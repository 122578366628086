import React, { useEffect, useState, useRef, useCallback } from 'react';
import Loader from 'react-loader-spinner';
import dayjs from 'dayjs';
import { Redirect, useHistory } from 'react-router-dom';
import { Flex, Grid, Text, useBreakpointValue } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import routes from 'constants/routesPaths';
import { activeExperiments } from 'constants/experiments';
import { green } from 'styles/_variables.scss';
import PaymentToggle from 'components/settlementOffer/PaymentToggle';
import MonthPaymentTile from 'components/settlementOffer/MonthPaymentTile';
import MonthPaymentOption from 'components/settlementOffer/MonthPaymentOption';
import MonthDaySelection from 'components/settlementOffer/MonthDaySelection';
import OfferHeader from 'components/settlementOffer/OfferHeader';
import PayInFullPicker from 'components/settlementOffer/PayInFullPicker';
import { FULFILLED, REJECTED } from 'constants/actionStatusConstants';
import PaymentOptionSection from 'components/settlementOffer/PaymentOptionSection';
import ExperimentWrapper from 'components/ABTesting';
import TextDisclosure from 'components/paymentDisclosure/TextDisclosure';
import ModalDisclosure from 'components/paymentDisclosure/ModalDisclosure';
import UnverifiedPhoneLogin from 'components/settlementOffer/UnverifiedPhoneLogin';
import { getAmountPerMonth, getMonthAbrev, monthsBetween } from 'utils/helpers';
import {
  IFRAME_LOADER_FORMAT,
  IFRAME_LOADER_SIZE,
  PAYMENT_STATUS,
  PAYMENT_METHOD_OPTIONS,
  SETTLEMENT_OPTIONS
} from 'constants/constants';
// eslint-disable-next-line import/extensions
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import 'swiper/swiper.scss'; // core Swiper

import {
  paymentForm,
  resetPaymentForm,
  savePaymentToken,
  savePaymentCardType,
  savePaymentSource,
  savePaymentLast4,
  savePaymentRoutingNumber,
  savePaymentNameOnAccount,
  savePaymentDetails
} from 'state/actions/paymentMethodActions';
import {
  settlementOffer,
  selectSettlementOption,
  selectSettlementPlan,
  selectSettlementPaymentMethod,
  selectDate,
  verifyPhoneNumber
} from 'state/actions/settlementOfferActions';
import {
  useDispatch,
  useStatus,
  useSelectSettlementReferenceNumber,
  useSelectPaymentForm,
  useSelectedSettlementPaymentMethod,
  useSelectSettlementPlan,
  useSelectSettlementOption,
  useSelectSettlementOfferInfo,
  useSelectPaymentInfo,
  useSelectSettlementDate,
  useSelectVerifiedDevice,
  useLiveChat // chat widget
} from 'hooks';
import { useIntl } from 'react-intl';
import { messages } from './translations';

const SettlementOfferPage = () => {
  const intl = useIntl();
  const history = useHistory();
  const getSettlementOffer = useDispatch(settlementOffer);
  const { status } = useStatus(settlementOffer);
  const { referenceNumber } = useSelectSettlementReferenceNumber();
  const { verified } = useSelectVerifiedDevice();

  const swiperRef = useRef();
  // Pay in full or create a plan
  const { selectedSettlementOption } = useSelectSettlementOption();
  // Plan selected ( # of months )
  const { selectedSettlementPlan } = useSelectSettlementPlan();
  // ACH or credit card
  const { selectedSettlementPaymentMethod } = useSelectedSettlementPaymentMethod();
  // Settlement offer info
  const {
    currentBalance,
    offerAmount,
    settlementTermsOffered,
    expiryDate
  } = useSelectSettlementOfferInfo();

  const { selectedDate } = useSelectSettlementDate();
  const { paymentFormUrl, hasPaymentForm } = useSelectPaymentForm();
  const { paymentToken } = useSelectPaymentInfo();

  const setDate = useDispatch(selectDate);
  const paymentFormRequest = useDispatch(paymentForm);
  const selectOption = useDispatch(selectSettlementOption);
  const selectPaymentMethod = useDispatch(selectSettlementPaymentMethod);
  const selectSettlementPlanOption = useDispatch(selectSettlementPlan);
  const resetPaymentFormRequest = useDispatch(resetPaymentForm);
  const savePaymentTokenRequest = useDispatch(savePaymentToken);
  const savePaymentCardTypeRequest = useDispatch(savePaymentCardType);
  const savePaymentSourceRequest = useDispatch(savePaymentSource);
  const savePaymentLast4Request = useDispatch(savePaymentLast4);
  const savePaymentDetailsRequest = useDispatch(savePaymentDetails);
  const savePaymentRoutingNumberRequest = useDispatch(savePaymentRoutingNumber);
  const savePaymentNameOnAccountRequest = useDispatch(savePaymentNameOnAccount);
  const verificationRequest = useDispatch(verifyPhoneNumber);

  const isProhibitedDay = dayjs().date() > 28;
  const isDecember = dayjs().month() === 11;

  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [selectOptions, setSelectOptions] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(1);
  // If today day is > 28, then we need to set the selected day to the 1st of the following month
  // taking into consideration the special case of december where we have to add 1 year
  const [selectedDay, setSelectedDay] = useState(isProhibitedDay ? 1 : dayjs().date());
  const [selectedMonth, setSelectedMonth] = useState({
    month: isProhibitedDay ? (isDecember ? 0 : dayjs().month() + 1) : dayjs().month(),
    year: isProhibitedDay ? (isDecember ? dayjs().year() + 1 : dayjs().year()) : dayjs().year()
  });
  const [loaderVisible, setLoaderVisible] = useState(false);

  const selectedPaymentMethodRef = useRef();
  const paymentLoaderRef = useRef();

  const repayUrls = process.env.REPAY_URLS.split(',');

  const isCreatePlan = selectedSettlementOption === SETTLEMENT_OPTIONS.createPlan;
  const isCard = PAYMENT_METHOD_OPTIONS.card === selectedSettlementPaymentMethod;
  const isBank = PAYMENT_METHOD_OPTIONS.bank === selectedSettlementPaymentMethod;

  const amountOfMonthsPlan = selectedSettlementPlan?.numberOfMonths;

  const amountSlidesPerGroup = useBreakpointValue({
    base: Math.min(2, amountOfMonthsPlan),
    bigSm: Math.min(3, amountOfMonthsPlan),
    lg: Math.min(4, amountOfMonthsPlan),
    xl: Math.min(5, amountOfMonthsPlan),
    '2xl': Math.min(6, amountOfMonthsPlan)
  });

  const showArrows = useBreakpointValue({
    base: false,
    md: true
  });

  const NoDisclosure = () => null;

  const variants = {
    [activeExperiments?.paymentMethodDisclosures?.variants?.[0]?.name]: NoDisclosure,
    [activeExperiments?.paymentMethodDisclosures?.variants?.[1]?.name]: TextDisclosure,
    [activeExperiments?.paymentMethodDisclosures?.variants?.[2]?.name]: ModalDisclosure
  };

  useLiveChat(verified);

  useEffect(() => {
    if (referenceNumber) {
      getSettlementOffer({ referenceNumber });
    }
  }, [referenceNumber, getSettlementOffer]);

  useEffect(() => {
    if (settlementTermsOffered) {
      const paymentPlans = [];
      settlementTermsOffered.map(numberOfMonths => {
        const amount = getAmountPerMonth(offerAmount, numberOfMonths);
        if (numberOfMonths > 1) {
          paymentPlans.push({
            numberOfMonths,
            amount
          });
        }
        const paymentPlansOrdered = paymentPlans.sort(
          (a, b) => a.numberOfMonths - b.numberOfMonths
        );
        setSelectOptions(paymentPlansOrdered);
        selectSettlementPlanOption(paymentPlansOrdered?.[0]);
      });
    }
  }, [settlementTermsOffered, offerAmount, selectSettlementPlanOption]);

  const paymentHandler = e => {
    if (!repayUrls.includes(e.origin)) {
      return;
    }
    if (e.data === PAYMENT_STATUS.success) {
      setPaymentSuccess(true);
    }
    if (e.data?.paymentResponseData) {
      const {
        saved_payment_method: { token },
        payment_type_id: savedCardType,
        customer_id: customerId,
        account_type: accountType,
        pn_ref: pnRef,
        result_details: { card_info: { type = '' } = {} } = {},
        result,
        last4,
        date,
        transit_number: routingNumber,
        name_on_check: nameOnAccount
      } = e.data.paymentResponseData;
      const paymentSource =
        PAYMENT_METHOD_OPTIONS.card === selectedPaymentMethodRef.current
          ? savedCardType
          : accountType;

      savePaymentCardTypeRequest(savedCardType);
      savePaymentSourceRequest(paymentSource);
      savePaymentLast4Request(last4);
      savePaymentTokenRequest(token);
      savePaymentRoutingNumberRequest(routingNumber);
      savePaymentNameOnAccountRequest(nameOnAccount);
      savePaymentDetailsRequest({
        date,
        customer_id: customerId,
        pn_ref: pnRef,
        card_type: type,
        result,
        payment_last4: last4,
        payment_source: paymentSource
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener('message', paymentHandler);
    resetPaymentFormRequest();
    setDate(new Date());
    return () => window.removeEventListener('message', paymentHandler);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const formattedDate = dayjs()
      .set('date', selectedDay)
      .set('month', selectedMonth.month)
      .set('year', selectedMonth.year);
    setDate(formattedDate.toDate());
  }, [selectedDay]);

  const onPaymentMethodSelect = paymentMethod => {
    setLoaderVisible(!loaderVisible);
    resetPaymentFormRequest();
    selectPaymentMethod(paymentMethod);
    selectedPaymentMethodRef.current = paymentMethod;
    paymentFormRequest({ accountId: referenceNumber, methodType: paymentMethod });
    return paymentLoaderRef.current?.scrollIntoView();
  };

  const handleMonthClick = month => {
    if (month?.month === dayjs().month() && month?.year === dayjs().year()) {
      setSelectedDay(dayjs().date());
    } else if (
      month?.month === dayjs(expiryDate).month() &&
      month?.year === dayjs(expiryDate).year() &&
      selectedDay > dayjs(expiryDate).date()
    ) {
      setSelectedDay(dayjs(expiryDate).date());
    }
    setSelectedMonth(month);
  };

  const handleDayClick = day => {
    setSelectedDay(day);
  };

  const handleMonthPaymentClick = option => {
    if (option.numberOfMonths < selectedSettlementPlan?.numberOfMonths) {
      selectSettlementPlanOption(option);
      handleMonthClick(selectedMonth);
    } else {
      selectSettlementPlanOption(option);
    }
  };

  const handlePrev = useCallback(() => {
    if (!swiperRef.current) return;
    swiperRef.current.swiper.slidePrev();
    setCurrentSlide(currentSlide => currentSlide - 1);
  }, []);

  const handleNext = useCallback(() => {
    if (!swiperRef.current) return;
    swiperRef.current.swiper.slideNext();
    setCurrentSlide(currentSlide => currentSlide + 1);
  }, []);

  if (paymentSuccess && paymentToken) {
    return <Redirect push to={routes.settlementConfirm} />;
  }

  if (!referenceNumber) {
    return <Redirect push to={routes.settlementError} />;
  }

  return status === FULFILLED ? (
    <Flex
      backgroundColor="delta.50"
      direction="column"
      w="full"
      alignItems="center"
      h="full"
      overflow="auto"
      pt={10}
      className="settlement-payment-page-container"
    >
      {!verified && <UnverifiedPhoneLogin onSubmit={verificationRequest} />}
      <Text
        fontSize={{ base: 'xl', md: '3xl' }}
        color="alpha.500"
        fontWeight={700}
        textAlign="center"
        mx={4}
      >
        {intl.formatMessage(messages.title)}
      </Text>
      <Text fontSize={{ base: 'md', md: 'xl' }} color="gray.500" textAlign="center" mx={4}>
        {intl.formatMessage(messages.subtitle)}
      </Text>
      <PaymentToggle
        alignItems="center"
        justifyContent="center"
        paymentSelected={selectedSettlementOption}
        setSelectedPayment={selectOption}
      />
      <Flex w={{ base: '95%', md: '65%' }} mx="auto" mt={5} direction="column" h="full">
        <OfferHeader
          referenceNumber={referenceNumber}
          currentBalance={currentBalance}
          offerAmount={offerAmount}
        />
        {isCreatePlan && (
          <>
            <Flex
              alignItems="center"
              direction="column"
              backgroundColor="white"
              borderRadius="12px"
              borderTopRightRadius={0}
              borderTopLeftRadius={0}
              w="full"
              py={6}
              px={4}
            >
              <Text fontWeight={700} fontSize={{ base: 'lg', md: 'xl' }} color="gray.600" mb={4}>
                {intl.formatMessage(messages.selectYourPayments)}
              </Text>
              <Grid
                justifyContent="center"
                w="full"
                gridTemplateColumns={{
                  base: 'repeat(auto-fit, minmax(auto, 275px))',
                  md: 'repeat(auto-fit, minmax(auto, 330px))'
                }}
                gridGap={2.5}
              >
                {selectOptions.map(option => (
                  <MonthPaymentOption
                    key={option?.numberOfMonths}
                    amount={option.amount?.[0]}
                    numberOfPayments={option?.numberOfMonths}
                    selected={selectedSettlementPlan?.numberOfMonths === option?.numberOfMonths}
                    onClick={() => handleMonthPaymentClick(option)}
                  />
                ))}
              </Grid>
            </Flex>
            <MonthDaySelection
              selectedMonth={selectedMonth}
              selectedDay={selectedDay}
              handleDayClick={handleDayClick}
              handleMonthClick={handleMonthClick}
              expiryDate={expiryDate}
              amountOfMonths={monthsBetween(expiryDate)?.length}
            />
          </>
        )}
        <Flex
          alignItems="center"
          direction="column"
          backgroundColor="white"
          borderRadius="12px"
          borderTopRightRadius={0}
          borderTopLeftRadius={0}
          w="full"
          position="relative"
          ref={paymentLoaderRef}
          scrollBehavior="smooth"
          py={6}
          px={{ base: 2, md: 4 }}
        >
          {isCreatePlan && selectedSettlementPlan ? (
            <>
              <Text fontWeight={700} fontSize={{ base: 'lg', md: 'xl' }} color="gray.600" mb={4}>
                {intl.formatMessage(messages.paymentDates)}
              </Text>
              <Text fontSize="xs" color="gray.600" alignSelf="flex-end">
                {Math.min(
                  amountSlidesPerGroup * currentSlide,
                  selectedSettlementPlan?.numberOfMonths
                )}{' '}
                {intl.formatMessage(messages.of)} {selectedSettlementPlan?.numberOfMonths}
              </Text>
              <Flex
                position="relative"
                width="100%"
                justifyContent="center"
                px={{ base: 0, md: 2 }}
              >
                <Swiper
                  ref={swiperRef}
                  spaceBetween={1}
                  centerInsufficientSlides
                  slidesPerView={amountSlidesPerGroup}
                  slidesPerGroup={amountSlidesPerGroup}
                  allowSlideNext={
                    currentSlide < selectedSettlementPlan?.numberOfMonths / amountSlidesPerGroup
                  }
                  allowSlidePrev={currentSlide > 1}
                  onSlideNextTransitionEnd={() => {
                    setCurrentSlide(currentSlide => currentSlide + 1);
                  }}
                  onSlidePrevTransitionEnd={() => {
                    setCurrentSlide(currentSlide => currentSlide - 1);
                  }}
                >
                  {selectedSettlementPlan?.amount?.map((amount, index) => {
                    let yearToAdd = 0;
                    if (selectedMonth?.month + index >= 12) {
                      yearToAdd += 1;
                    }
                    return (
                      <SwiperSlide key={`${index}-${amount}`}>
                        <MonthPaymentTile
                          day={selectedDay}
                          year={selectedMonth?.year + yearToAdd}
                          monthAbr={getMonthAbrev((selectedMonth?.month + index) % 12)}
                          amount={amount.toLocaleString(undefined, { minimumFractionDigits: 2 })}
                        />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
                {showArrows && currentSlide > 1 && (
                  <ChevronLeftIcon
                    cursor="pointer"
                    zIndex="docked"
                    className="prev-arrow"
                    onClick={handlePrev}
                  />
                )}
                {showArrows &&
                  currentSlide < selectedSettlementPlan?.numberOfMonths / amountSlidesPerGroup && (
                    <ChevronRightIcon
                      cursor="pointer"
                      zIndex="docked"
                      className="next-arrow"
                      onClick={handleNext}
                    />
                  )}
              </Flex>
            </>
          ) : (
            <PayInFullPicker
              expiryDate={expiryDate}
              selectedDate={selectedDate}
              setDate={setDate}
            />
          )}
        </Flex>
        <Flex
          alignItems="center"
          justifyContent="center"
          direction="column"
          backgroundColor="white"
          borderRadius="12px"
          py={6}
          w="full"
          mt={2}
          h="100%"
        >
          <Text fontWeight={700} fontSize={{ base: 'lg', md: 'xl' }} color="gray.600" mt={0}>
            {isCreatePlan ? '3.' : '2.'} {intl.formatMessage(messages.selectYourPaymentMethod)}
          </Text>
          <PaymentOptionSection
            hasPaymentForm={hasPaymentForm}
            onPaymentMethodSelect={onPaymentMethodSelect}
            loaderVisible={loaderVisible}
            setLoaderVisible={setLoaderVisible}
            isCard={isCard}
            isBank={isBank}
            paymentFormUrl={paymentFormUrl}
          />
        </Flex>
        <Flex
          alignItems="start"
          justifyContent="center"
          direction="column"
          py={6}
          w="full"
          mt={2}
          h="100%"
          paddingTop={0}
        >
          <Flex direction="column" mt={4} w="100%">
            <Text mt={1} fontSize={{ base: 'sm', md: 'md' }} color="alpha.500">
              {intl.formatMessage(messages.noObligation)}
            </Text>
            <Text mt={1} fontSize={{ base: 'sm', md: 'md' }} color="alpha.500">
              {intl.formatMessage(messages.miniMiranda)}
            </Text>
            <Text
              onClick={() => history.push(routes.disclosures)}
              textDecoration="underline"
              textAlign="center"
              mt={4}
              fontSize="md"
              fontWeight={700}
              color="beta.500"
              cursor="pointer"
              w="fit-content"
            >
              {intl.formatMessage(messages.knowYourRights)}
            </Text>
          </Flex>
          <ExperimentWrapper
            experimentName={activeExperiments?.paymentMethodDisclosures?.name}
            variantContent={variants}
          />
        </Flex>
      </Flex>
    </Flex>
  ) : (
    <>
      {status === REJECTED ? (
        <Redirect to={routes.login} />
      ) : (
        <div className="settlement-container">
          <Loader
            visible
            type={IFRAME_LOADER_FORMAT}
            color={green}
            height={IFRAME_LOADER_SIZE}
            width={IFRAME_LOADER_SIZE}
          />
        </div>
      )}
    </>
  );
};

export default SettlementOfferPage;
