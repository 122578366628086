import { useSelector, shallowEqual } from 'react-redux';

const useSelectAccountIds = () =>
  useSelector(
    ({ session: { user } }) => ({
      accountIds: user?.accountIds
    }),
    shallowEqual
  );

export default useSelectAccountIds;
