import { useSelector, shallowEqual } from 'react-redux';

const useSelectPaymentDates = () =>
  useSelector(
    ({ paymentPlan: { paymentSchedule } }) => ({
      paymentSchedule
    }),
    shallowEqual
  );

export default useSelectPaymentDates;
