import React from 'react';
import { Flex, Text, Image } from '@chakra-ui/react';
import { useIntl } from 'react-intl';

import Status from 'components/routes/Status';
import { Button, ContentContainer } from 'components/common';
import routes from 'constants/routesPaths';
import { useSession } from 'hooks';

import humanStanding from 'assets/images/humanStanding.png';

const NotFoundPage = () => {
  const intl = useIntl();
  const { authenticated } = useSession();

  return (
    <Status code={404}>
      <ContentContainer noMargin={!authenticated}>
        <Flex
          as="section"
          mt={{ base: 0, md: 10 }}
          className="container"
          pt={6}
          px={4}
          justifyContent="center"
          alignItems="center"
          direction={{ base: 'column', md: 'row' }}
        >
          <Flex direction="column" order={{ base: 2, md: 1 }} alignItems="center" pb={4}>
            <Text fontSize={{ base: '48px', md: '70px' }} fontWeight={700} mb={5} color="link">
              404
            </Text>
            <Text
              as="h1"
              textAlign="center"
              mb={{ base: 4, md: 8 }}
              fontSize={{ base: '32px', md: '40px' }}
            >
              {intl.formatMessage({ id: 'notFound.title' })}
            </Text>
            <Text as="p" textAlign="center" mb={6} fontSize={{ base: '20px', md: '22px' }}>
              {intl.formatMessage({ id: 'notFound.explanation' })}
            </Text>
            <Button
              linkTo={routes.index}
              buttonStyle="secondary"
              size="medium"
              title={intl.formatMessage({ id: 'notFound.back' })}
            />
          </Flex>

          <Flex order={{ base: 1, md: 2 }} maxH={{ base: '60vh', md: 'unset' }}>
            <Image
              width={{ base: '260px', md: '100%' }}
              height={{ base: '260px', md: '100%' }}
              src={humanStanding}
              alt={intl.formatMessage({ id: 'notFound.altImage' })}
            />
          </Flex>
        </Flex>
      </ContentContainer>
    </Status>
  );
};

export default NotFoundPage;
