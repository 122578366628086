import React, { useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { Flex, Link, Box, Button as ChakraButton } from '@chakra-ui/react';
import { useIntl, FormattedMessage } from 'react-intl';

import {
  useSession,
  useSelectAccountSuppressed,
  useSelectAccountId,
  useDispatch,
  useStatus,
  useAnalytics
} from 'hooks';
import routes from 'constants/routesPaths';
import { account, logout } from 'state/actions/userActions';
import { Button, ContentContainer, Loading } from 'components/common';
import LegalInformation from 'components/legal/LegalInformation';
import { MIXPANEL_EVENTS } from 'constants/mixpanelEvents';
import { TELEPHONE_CONTACT } from 'constants/constants';
import { PENDING } from 'constants/actionStatusConstants';

const { SOC_WEB_CONTACT_US } = process.env;

const AccountErrorPage = () => {
  const intl = useIntl();
  const location = useLocation();
  const { authenticated } = useSession();
  const { status } = useStatus(account);
  const { suppressed } = useSelectAccountSuppressed();
  const { accountId } = useSelectAccountId();
  const { trackEvent } = useAnalytics();
  const accountRequest = useDispatch(account);
  const logoutRequest = useDispatch(logout);

  useEffect(() => {
    trackEvent(MIXPANEL_EVENTS.viewAccountSuppressedPage);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!authenticated) {
    return <Redirect to={{ pathname: routes.login, state: { hideMenu: true } }} />;
  }

  if (authenticated && !suppressed) {
    return <Redirect to={routes.home} />;
  }

  return (
    <ContentContainer noMargin={location.state?.hideMenu}>
      <Flex
        p={2}
        alignItems="center"
        direction="column"
        h="100%"
        justifyContent="center"
        maxW="760px"
        textAlign="center"
      >
        {status === PENDING ? (
          <Loading />
        ) : (
          <>
            <Box mb={2} fontSize={{ base: '20px', md: '28px' }} whiteSpace="pre-line">
              <FormattedMessage
                id="account.suppressed"
                values={{
                  contactUsLink: (
                    <Link
                      color="alpha.500"
                      fontWeight={700}
                      textDecoration="underline"
                      href={SOC_WEB_CONTACT_US}
                    >
                      {intl.formatMessage({ id: 'legal.contactUs' })}
                    </Link>
                  ),
                  contactUsNumber: (
                    <Link
                      color="alpha.500"
                      fontWeight={700}
                      textDecoration="underline"
                      href={`tel:${TELEPHONE_CONTACT}`}
                    >
                      {TELEPHONE_CONTACT}
                    </Link>
                  )
                }}
              />
            </Box>

            <Button
              className="mt-4 mb-3"
              onClick={() => accountRequest([accountId])}
              buttonStyle="primary"
              size="large"
              color="green"
              title={intl.formatMessage({ id: 'legal.tryAgain' })}
              ariaLabel={intl.formatMessage({ id: 'legal.tryAgain' })}
            />
            <ChakraButton
              color="alpha.500"
              fontSize={{ base: '16px', md: '20px' }}
              fontWeight={700}
              backgroundColor="transparent"
              _hover={{ backgroundColor: 'transparent' }}
              textDecoration="underline"
              onClick={logoutRequest}
            >
              {intl.formatMessage({ id: 'account.suppressed.logout' })}
            </ChakraButton>
            <LegalInformation />
          </>
        )}
      </Flex>
    </ContentContainer>
  );
};

export default AccountErrorPage;
