import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { useIntl, defineMessages } from 'react-intl';

import { bool, number, func } from 'prop-types';
import SelectedMonth from '../../icons/SelectedMonth';

const MonthPaymentOption = ({ amount, selected, numberOfPayments, onClick }) => {
  const intl = useIntl();

  const messages = defineMessages({
    monthly: { id: 'settlement.monthly' },
    payments: { id: 'settlement.payments' }
  });

  return (
    <Flex
      paddingY={6}
      paddingX={5.5}
      background="white"
      borderRadius="12px"
      borderWidth="1px"
      borderStyle="solid"
      borderColor="gray.100"
      width={{ base: '280px', sm: '323px' }}
      height="82px"
      cursor="pointer"
      alignItems="center"
      justifyContent="space-evenly"
      onClick={onClick}
    >
      <SelectedMonth selected={selected} />
      <Text lineHeight="150%" ml={2} as="span" fontSize="md" color="black" fontWeight={700}>
        $
        {parseInt(amount, 10) === amount
          ? amount.toLocaleString()
          : amount.toLocaleString(undefined, { minimumFractionDigits: 2 })}
      </Text>
      <Text lineHeight="150%" ml={0.5} as="span" fontSize="md" color="black" whiteSpace="nowrap">
        {intl.formatMessage(messages.monthly)}
      </Text>
      <Flex ml={4} padding="8px 20px" backgroundColor="gamma.50" borderRadius="27px">
        <Text
          ml={{ base: 0, md: 2 }}
          fontSize="xs"
          color="alpha.500"
          fontWeight={700}
          textAlign={{ base: 'center', md: 'left' }}
        >
          {numberOfPayments} {intl.formatMessage(messages.payments)}
        </Text>
      </Flex>
    </Flex>
  );
};

MonthPaymentOption.propTypes = {
  selected: bool.isRequired,
  numberOfPayments: number.isRequired,
  amount: number.isRequired,
  onClick: func.isRequired
};

export default MonthPaymentOption;
