import React from 'react';
import { useIntl } from 'react-intl';
import { Text, Flex } from '@chakra-ui/react';
import { messages } from './translations';

const TextDisclosure = () => {
  const intl = useIntl();

  return (
    <Flex direction="column" mt={4} w="100%">
      <Text fontSize={{ base: 'lg', md: 'xl' }} fontWeight={700} color="alpha.500">
        {intl.formatMessage(messages.disclosureTitle)}
      </Text>
      <Text mt={1} fontSize={{ base: 'sm', md: 'md' }} color="alpha.500">
        {intl.formatMessage(messages.disclosureBody)}
      </Text>
    </Flex>
  );
};

export default TextDisclosure;
