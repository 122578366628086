import dayjs from 'dayjs';

export const PAYMENT_DATES_DAY_FORMAT = 'D';
export const PAYMENT_DATES_MONTH_FORMAT = 'MMM';
export const DATEPICKER_DATE_FORMAT = 'MM/dd/yyyy';
export const DATEINPUT_FORMAT = '##/##/####';
export const DATEINPUT_MASK = ['M', 'M', 'D', 'D', 'Y', 'Y', 'Y', 'Y'];
export const ACCOUNT_DATE_FORMAT = 'MMMM D, YYYY';
export const SHORT_DATE_FORMAT = 'MMM D, YYYY';
export const PAYLOAD_DATE_FORMAT = 'YYYY-MM-DD';

export const formatDate = (date, format) => {
  return dayjs(date).format(format);
};
