import { createSlice } from '@reduxjs/toolkit';
import {
  paymentOptionsFulfilled,
  selectRepaymentOption,
  selectPlan,
  selectDate,
  selectFrequency,
  getPaymentPlansFulfilled,
  selectAmount,
  savePlanSummary,
  resetRepaymentOption,
  createPaymentPlanFulfilled,
  getPlanPaymentDatesFulfilled,
  getPaymentHistoryFulfilled,
  cleanPlan,
  addPaymentMethodToPromiseFulfilled,
  cleanNewPlan,
  getSpecialPaymentPlanFulfilled,
  saveSpecialPaymentPlanSchedule
} from 'state/actions/paymentPlanActions';
import { logout } from 'state/actions/userActions';
import dayjs from 'dayjs';

const initialState = {
  accountPaymentPlan: [],
  selectedRepaymentOption: undefined,
  selectedPlan: undefined,
  selectedDate: undefined,
  selectedFrequency: {},
  selectedAmount: null,
  paymentPlans: [],
  planSummary: '',
  newPlan: undefined,
  paymentSchedule: [],
  paymentHistory: [],
  specialPlan: {
    currentBalance: '',
    payments: [],
    proposedAmountApproved: true,
    standardMonthlyAmount: '',
    termsOffered: 0
  }
};

const paymentPlanSlice = createSlice({
  name: 'paymentPlan',
  initialState,
  extraReducers: {
    [paymentOptionsFulfilled]: (state, { payload }) => {
      state.accountPaymentPlan = payload;
    },
    [selectRepaymentOption]: (state, { payload }) => {
      state.selectedRepaymentOption = payload;
      state.selectedPlan = initialState.selectedPlan;
      state.selectedDate = initialState.selectedDate;
      state.selectedFrequency = initialState.selectedFrequency;
      state.selectedAmount = initialState.selectedAmount;
      state.planSummary = initialState.planSummary;
    },
    [selectPlan]: (state, { payload }) => {
      state.selectedPlan = payload;
    },
    [cleanPlan]: state => {
      state.selectedPlan = undefined;
    },
    [cleanNewPlan]: state => {
      state.newPlan = undefined;
    },
    [selectDate]: (state, { payload }) => {
      state.selectedDate = payload;
    },
    [selectFrequency]: (state, { payload }) => {
      state.selectedFrequency = payload;
    },
    [selectAmount]: (state, { payload }) => {
      state.selectedAmount = payload;
    },
    [getPaymentPlansFulfilled]: (state, { payload }) => {
      state.paymentPlans = payload;
    },
    [savePlanSummary]: (state, { payload }) => {
      state.planSummary = payload;
    },
    [createPaymentPlanFulfilled]: (state, { payload }) => {
      state.newPlan = payload;
    },
    [getPlanPaymentDatesFulfilled]: (state, { payload }) => {
      state.paymentSchedule = payload;
    },
    [getPaymentHistoryFulfilled]: (state, { payload }) => {
      state.paymentHistory = payload;
    },
    [addPaymentMethodToPromiseFulfilled]: (state, { payload }) => {
      state.newPlan = payload;
    },
    [getSpecialPaymentPlanFulfilled]: (state, { payload }) => {
      state.specialPlan = payload;
    },
    [saveSpecialPaymentPlanSchedule]: (state, { payload }) => {
      // hydrate existing payment schedule state object
      const { firstPaymentMonth, firstPaymentDay, payments } = payload;
      const { month, year } = firstPaymentMonth;

      const paymentDate = dayjs()
        .set('date', firstPaymentDay)
        .set('month', month)
        .set('year', year);

      state.paymentSchedule = payments.map(amount => {
        const date = paymentDate.format('YYYY-MM-DD');
        paymentDate.add(1, 'month');
        return { date, amount };
      });
    },
    [resetRepaymentOption]: () => initialState,
    [logout]: () => initialState
  }
});

export const getPaymentPlan = state => state.paymentPlan;
export const getNewPlan = state => getPaymentPlan(state).newPlan;

export default paymentPlanSlice.reducer;
