import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { func } from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import compact from 'lodash/compact';
import {
  Text,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalHeader,
  ModalContent,
  Box,
  Flex,
  Center
} from '@chakra-ui/react';
import { REJECTED, PENDING } from 'constants/actionStatusConstants';
import { Loading, DateInput, Input } from 'components/common';
import Button, { buttonColor, buttonStyle, buttonSize } from 'components/common/Button';
import { formatDate, formattedDateInput, acceptOnlyDigits } from 'utils/helpers';
import {
  useForm,
  useTextInputProps,
  useSelectShortUrl,
  useSelectSettlementUrl,
  useSelectSettlementReferenceNumber,
  useSelectSettlementReferrer,
  useStatus
} from 'hooks';
import { verifyPhoneNumber } from 'state/actions/settlementOfferActions';
import AlertIcon from 'assets/icons/alertIcon.svg';
import { messages } from './translations';

const fields = {
  birthDate: 'dateOfBirth',
  ssn: 'ssnLastFour'
};

const UnverifiedPhoneLogin = ({ onSubmit }) => {
  const isModalOpen = true;
  const [date, setDate] = useState();
  const [disabled, setDisabled] = useState(true);
  const { shortUrl } = useSelectShortUrl();
  const { settlementUrl } = useSelectSettlementUrl();
  const { referenceNumber } = useSelectSettlementReferenceNumber();
  const { ref } = useSelectSettlementReferrer();
  const { status: verificationStatus } = useStatus(verifyPhoneNumber);
  const intl = useIntl();
  const {
    values,
    errors,
    handleValueChange,
    handleSubmit,
    handleFocus,
    handleBlur,
    activeFields,
    touched
  } = useForm(
    {
      onSubmit,
      validateOnChange: true,
      validateOnBlur: true
    },
    [onSubmit]
  );

  const inputProps = useTextInputProps(
    handleValueChange,
    handleFocus,
    handleBlur,
    values,
    errors,
    activeFields,
    touched
  );

  const handleOnInput = (e, maxLength) => {
    e.target.value = e.target.value.slice(0, maxLength);
  };

  useEffect(() => {
    values.shortUrl = shortUrl;
    values.settlementUrl = settlementUrl;
    values.debtId = referenceNumber;
    values.ref = ref;
    values.ssnLastFour = null;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    values.dateOfBirth = null;
    if (date?.length === 10) {
      values.dateOfBirth = formatDate(new Date(date));
    }
  }, [date]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const compactedValues = compact(Object.values(values));

    setDisabled(
      compactedValues.length < 3 ||
        ((compactedValues.length >= 3 &&
          (!isEmpty(values.ssnLastFour) && values.ssnLastFour.length !== 4)) ||
          (!isEmpty(values.dateOfBirth) && values.dateOfBirth.length !== 10))
    );
  }, [date, values]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Modal closeOnOverlayClick={false} size="xl" isCentered isOpen={isModalOpen}>
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
        <ModalContent py={8} px={8} mx={2}>
          <ModalHeader fontSize="3xl" py={2}>
            {intl.formatMessage(messages.unverifiedLoginTitle)}
          </ModalHeader>
          <ModalBody>
            <Flex direction="column">
              <Text mt={1} mb={3} fontSize="md" color="alpha.500">
                {intl.formatMessage(messages.unverifiedLoginBody)}
              </Text>
              <Box as="form" minW={{ base: 'unset', sm: '400px' }} onSubmit={handleSubmit}>
                <Box>
                  <DateInput
                    label={intl.formatMessage(messages.unverifiedLoginDOB)}
                    placeholder={intl.formatMessage(messages.unverifiedLoginDOBPlaceholder)}
                    value={date}
                    onChange={({ value }) => setDate(formattedDateInput(value))}
                    // onBlur={onDateBlur}
                  />
                </Box>
                <Center>
                  <Text fontSize="md" color="alpha.500">
                    {intl.formatMessage(messages.or)}
                  </Text>
                </Center>
                <Box>
                  <Input
                    name="ssnLastFour"
                    type="number"
                    min={1}
                    step={1}
                    onKeyPress={acceptOnlyDigits}
                    inputMode="numeric"
                    onInput={e => handleOnInput(e, 4)}
                    label={intl.formatMessage(messages.unverifiedLoginSSN)}
                    placeholder={intl.formatMessage(messages.unverifiedLoginSSNPlaceholder)}
                    // error={!isEmpty(values.ssnLastFour) && values.ssnLastFour.length != 4 && 'ssn.invalid'}
                    {...inputProps(fields.ssn)}
                    // onBlur={onSSNBlur}
                  />
                </Box>
                {verificationStatus === REJECTED && (
                  <Flex
                    borderWidth="1px"
                    borderStyle="solid"
                    borderColor="red"
                    backgroundColor="softRed"
                    color="red"
                    mb={3}
                    alignItems="center"
                    justifyContent="space-around"
                    borderRadius="8px"
                    height="54px"
                  >
                    <AlertIcon />
                    {intl.formatMessage({ id: 'login.form.error' })}
                  </Flex>
                )}
                <p className="text-center">{intl.formatMessage(messages.numSubmitFields)}</p>

                {verificationStatus === PENDING ? (
                  <Loading />
                ) : (
                  <Button
                    type="submit"
                    className="w-100"
                    ariaLabel={intl.formatMessage(messages.verify)}
                    buttonStyle={buttonStyle.PRIMARY}
                    size={buttonSize.MEDIUM}
                    color={buttonColor.GREEN}
                    title={intl.formatMessage(messages.verify)}
                    isDisabled={disabled}
                  />
                )}
              </Box>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

UnverifiedPhoneLogin.propTypes = {
  onSubmit: func.isRequired
};

export default UnverifiedPhoneLogin;
