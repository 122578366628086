import React from 'react';
import { string, func } from 'prop-types';
import {
  Text,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalCloseButton,
  ModalHeader,
  ModalContent,
  Button,
  Flex
} from '@chakra-ui/react';

const CommonModal = ({ title, buttonText, onClose, children }) => (
  <Modal size="xl" isCentered isOpen onClose={onClose}>
    <ModalOverlay />
    <ModalContent padding={8}>
      <ModalHeader py={2}>{title}</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Flex direction="column">
          {children}
          <Button variant="successBase" size="lg" onClick={onClose} alignSelf="flex-end" mt={6}>
            <Text fontSize="md" color="white">
              {buttonText}
            </Text>
          </Button>
        </Flex>
      </ModalBody>
    </ModalContent>
  </Modal>
);

CommonModal.propTypes = {
  title: string,
  buttonText: string,
  onClose: func
};

export default CommonModal;
