import { useDispatch } from 'react-redux';

import {
  goToSection,
  goToPreviousSection,
  cleanNavigationStack
} from 'state/actions/sectionActions';

const useSectionRouter = () => {
  const dispatch = useDispatch();
  return {
    push: section => {
      dispatch(goToSection(section));
    },
    pop: () => {
      dispatch(goToPreviousSection());
    },
    clear: () => {
      dispatch(cleanNavigationStack());
    }
  };
};

export default useSectionRouter;
