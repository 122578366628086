import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  back: { id: 'settlement.back' },
  backAria: { id: 'settlement.backAria' },
  bankAccount: { id: 'settlement.bank' },
  creditor: { id: 'accounts.originalCreditor' },
  endingIn: { id: 'confirmPage.endingIn' },
  every: { id: 'confirmPage.every' },
  paymentMethod: { id: 'confirmPage.paymentMethod' },
  reference: { id: 'accounts.referenceNumber' },
  titleCreatePlan: { id: 'confirmPage.titleCreatePlan' },
  titlePayment: { id: 'confirmPage.titlePayment' },
  totalBalance: { id: 'confirmPage.totalBalance' },
  confirmSettlement: { id: 'settlement.confirmSettlement' }
});
