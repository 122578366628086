import React, { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';

import Button, { buttonStyle, buttonSize, buttonColor } from 'components/common/Button';
import { ContentContainer } from 'components/common';
import routes from 'constants/routesPaths';
import { useAnalytics, useStatus } from 'hooks';
import { MIXPANEL_EVENTS } from 'constants/mixpanelEvents';
import { createPaymentPlan } from 'state/actions/paymentPlanActions';
import { PAYMENT_PLAN_ERROR_CODE } from 'constants/constants';

import ErrorIcon from 'assets/icons/errorIcon.svg';

const PaymentErrorPage = () => {
  const intl = useIntl();
  const { trackEvent } = useAnalytics();
  const { code } = useStatus(createPaymentPlan);

  useEffect(() => {
    trackEvent(MIXPANEL_EVENTS.viewPaymentErrorPage);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const renderError = useMemo(() => {
    let errorMessage = '';

    switch (code) {
      case PAYMENT_PLAN_ERROR_CODE.RepayContactException:
        errorMessage = 'paymentError.repayContactException';
        break;
      case PAYMENT_PLAN_ERROR_CODE.RepayDeclinedPaymentException:
        errorMessage = 'paymentError.repayDeclinedPaymentException';
        break;
      default:
        errorMessage = 'paymentError.genericDescription';
        break;
    }

    return <span>{intl.formatMessage({ id: errorMessage })}</span>;
  }, [code, intl]);

  return (
    <ContentContainer>
      <div className="container payment-error-container">
        <ErrorIcon />
        <h1>{intl.formatMessage({ id: 'paymentError.title' })}</h1>

        {renderError}

        <Button
          className="my-4"
          buttonStyle={buttonStyle.SECONDARY}
          size={buttonSize.SMALL}
          color={buttonColor.BLACK}
          title={intl.formatMessage({ id: 'paymentError.tryAgain' })}
          ariaLabel={intl.formatMessage({ id: 'paymentError.tryAgain' })}
          linkTo={routes.confirmPaymentPage}
        />
      </div>
    </ContentContainer>
  );
};

export default PaymentErrorPage;
