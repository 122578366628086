import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';

import EmptyPage from 'pages/EmptyPage';
import PaymentsOverview from 'components/paymentsOverview';
import { ContentContainer, Loading } from 'components/common';
import { getPaymentPlans, getPaymentHistory } from 'state/actions/paymentPlanActions';
import {
  useSelectPaymentPlans,
  useStatus,
  useSelectAccountId,
  useDispatch,
  useSelectAccountDetails,
  useSelectPaymentHistory,
  useAnalytics
} from 'hooks';
import { FULFILLED, PENDING } from 'constants/actionStatusConstants';
import { MIXPANEL_EVENTS } from 'constants/mixpanelEvents';

const PaymentHistoryPage = () => {
  const intl = useIntl();
  const { accountId } = useSelectAccountId();
  const { hasPaymentPlans } = useSelectPaymentPlans();
  const { paymentHistory } = useSelectPaymentHistory();
  const { remainingBalance } = useSelectAccountDetails();
  const { status } = useStatus(getPaymentPlans);
  const { status: statusHistory } = useStatus(getPaymentPlans);
  const { trackEvent } = useAnalytics();
  const paymentPlansRequest = useDispatch(getPaymentPlans);
  const paymentHistoryRequest = useDispatch(getPaymentHistory);
  const resetPaymentHistoryRequest = useDispatch(getPaymentHistory.reset);

  useEffect(() => {
    resetPaymentHistoryRequest();
    trackEvent(MIXPANEL_EVENTS.viewPaymentHistoryPage);
    window.scrollTo(0, 0);
    paymentHistoryRequest(accountId);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    accountId && !hasPaymentPlans && paymentPlansRequest(accountId);
  }, [accountId, hasPaymentPlans, paymentPlansRequest]);

  useEffect(() => {
    resetPaymentHistoryRequest();
    statusHistory !== FULFILLED && paymentHistoryRequest(accountId);
  }, [accountId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ContentContainer>
      <div className="homepage-container">
        {statusHistory === FULFILLED &&
          (paymentHistory.length > 0 ? (
            <>
              <h1 className="page-title">{intl.formatMessage({ id: 'paymentHistory.title' })}</h1>
              <PaymentsOverview payments={paymentHistory} />
            </>
          ) : (
            <EmptyPage
              title={intl.formatMessage({ id: 'paymentHistory.empty' })}
              showPaymentCTA={remainingBalance >= 1}
              buttonTitle={intl.formatMessage({ id: 'paymentOptionsBlock.createPlan' })}
            />
          ))}

        {(status === PENDING || statusHistory === PENDING) && <Loading />}
      </div>
    </ContentContainer>
  );
};

export default PaymentHistoryPage;
