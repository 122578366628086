import { createSlice } from '@reduxjs/toolkit';
import {
  logout,
  documentsFulfilled,
  documentFulfilled,
  resetDocuments
} from 'state/actions/userActions';

const initialState = {
  documents: null
};

const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  extraReducers: {
    [documentsFulfilled]: (state, { payload }) => {
      state.documents = payload;
    },
    [documentFulfilled]: (state, { payload }) => {
      state.documents = state.documents.map(doc => {
        if (doc.documentId !== payload.documentId) {
          return doc;
        }
        return { ...doc, documentBase64: payload.document };
      });
    },
    [resetDocuments]: () => initialState,
    [logout]: () => initialState
  }
});

export default documentsSlice.reducer;
