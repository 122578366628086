import React from 'react';
import { oneOfType, bool, func, string } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import NumberFormat from 'react-number-format';

const MAX_VAL = 10 ** 8;

const withValueLimit = input => {
  const { value } = input;

  if (value < MAX_VAL) return input;
};

const Input = ({
  label,
  name,
  value,
  placeholder = '',
  onChange,
  onBlur = () => {},
  error,
  ...props
}) => (
  <div className="input-component">
    {label && (
      <label className="input-component__label" htmlFor={name}>
        {label}
      </label>
    )}

    <div className="input-component-container">
      <NumberFormat
        className={cn('input-component-container-content', {
          'input-component-container-content--error': error
        })}
        aria-label={label}
        name={name}
        value={value}
        placeholder={placeholder}
        onValueChange={onChange}
        onBlur={onBlur}
        thousandSeparator
        prefix="$"
        allowNegative={false}
        fixedDecimalScale
        isAllowed={withValueLimit}
        {...props}
      />

      {error && (
        <span className="span--error">
          <FormattedMessage id={error} defaultMessage={error} />
        </span>
      )}
    </div>
  </div>
);

Input.propTypes = {
  name: string.isRequired,
  label: string,
  value: string,
  placeholder: string,
  onChange: func.isRequired,
  onBlur: func,
  error: oneOfType([string, bool])
};

export default Input;
