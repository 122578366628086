import React from 'react';
import Loader from 'react-loaders';
import cn from 'classnames';
import { oneOf } from 'prop-types';

export const loadingSize = {
  XSMALL: 'x-small',
  SMALL: 'small'
};

const Loading = ({ size }) => {
  const classnames = cn('loading-wrapper', `loading-wrapper--${size}`);
  return (
    <div className={classnames}>
      <Loader type="ball-scale-multiple" active />
    </div>
  );
};

Loading.propTypes = {
  size: oneOf([loadingSize.XSMALL, loadingSize.SMALL])
};

export default Loading;
