import { useSelector, shallowEqual } from 'react-redux';

const useSelectBannerSummary = () =>
  useSelector(
    ({ session: { isBannerSummaryExpanded } }) => ({
      isBannerSummaryExpanded
    }),
    shallowEqual
  );

export default useSelectBannerSummary;
