import { useSelector, shallowEqual } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

const useSelectDocuments = () =>
  useSelector(
    ({ documents: { documents } }) => ({
      documents,
      hasDocuments: !isEmpty(documents)
    }),
    shallowEqual
  );

export default useSelectDocuments;
