import React from 'react';
import { boolean } from 'prop-types';
import { useTheme } from '@chakra-ui/react';

const SelectedMonth = ({ selected = false }) => {
  const theme = useTheme();

  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 8C2 4.41136 4.91136 1.5 8.5 1.5C12.0886 1.5 15 4.41136 15 8C15 11.5886 12.0886 14.5 8.5 14.5C4.91136 14.5 2 11.5886 2 8ZM8.5 2.5C5.46364 2.5 3 4.96364 3 8C3 11.0364 5.46364 13.5 8.5 13.5C11.5364 13.5 14 11.0364 14 8C14 4.96364 11.5364 2.5 8.5 2.5Z"
        fill={selected ? theme.colors.beta[400] : theme.colors.black}
      />
      <path
        d="M8.5 12.5C10.9853 12.5 13 10.4853 13 8C13 5.51472 10.9853 3.5 8.5 3.5C6.01472 3.5 4 5.51472 4 8C4 10.4853 6.01472 12.5 8.5 12.5Z"
        fill={selected ? theme.colors.beta[400] : theme.colors.white}
      />
    </svg>
  );
};

SelectedMonth.propTypes = {
  selected: boolean
};

export default SelectedMonth;
