import React from 'react';
import { arrayOf, shape, number, string, func, object } from 'prop-types';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { useIntl } from 'react-intl';

import { CurrencyText, Pane } from 'components/common';

const PlanOptions = ({ planOptions, selectedFrequency, onPlanSelected, selectedPlan }) => {
  const intl = useIntl();

  return (
    <>
      <span className="page-subtitle">{intl.formatMessage({ id: 'createPlan.planTitle' })}</span>
      <section className="plan-options-container">
        {!isEmpty(planOptions) &&
          planOptions.map(
            ({
              planTerm,
              perPaymentAmount: { standard: paymentAmount },
              planScheduleFrequency
            }) => (
              <button
                className="plan-option"
                key={planTerm}
                onClick={() => onPlanSelected({ planTerm, paymentAmount, planScheduleFrequency })}
                type="button"
                aria-label={`Select ${planTerm} ${selectedFrequency} payments of $${paymentAmount}`}
              >
                <Pane
                  className={cn('plan-option-content', {
                    active:
                      selectedPlan?.planTerm === planTerm &&
                      selectedPlan?.planScheduleFrequency === planScheduleFrequency
                  })}
                >
                  <span className="price">
                    <CurrencyText value={paymentAmount} />
                    <span className="frequency">{` / ${selectedFrequency}*`}</span>
                  </span>
                  <span className="term">
                    {`${planTerm * planScheduleFrequency}
                    ${intl.formatMessage({ id: 'termUnit.payments' })}`}
                  </span>
                </Pane>
              </button>
            )
          )}
      </section>
      <span>{intl.formatMessage({ id: 'createPlan.disclaimer' })}</span>
    </>
  );
};

PlanOptions.propTypes = {
  planOptions: arrayOf(
    shape({
      planTerm: number,
      perPaymentAmount: object
    })
  ).isRequired,
  selectedFrequency: string.isRequired,
  onPlanSelected: func.isRequired,
  selectedPlan: object
};

export default PlanOptions;
