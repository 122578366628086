import React, { useEffect } from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { BackBanner, TileButton, ContentContainer, Loading } from 'components/common';
import PaymentPlanIcon from 'assets/icons/paymentPlan.svg';
import PayInFullIcon from 'assets/icons/payInFull.svg';
import PartialPaymentIcon from 'assets/icons/partialPayment.svg';
import routes from 'constants/routesPaths';
import { MIXPANEL_EVENTS } from 'constants/mixpanelEvents';
import { REPAYMENT_OPTIONS, DEFAULT_PLAN } from 'constants/constants';
import { PENDING, FULFILLED } from 'constants/actionStatusConstants';
import {
  getPaymentPlans,
  selectRepaymentOption,
  paymentPlanOptions,
  savePlanSummary,
  resetRepaymentOption,
  selectPlan
} from 'state/actions/paymentPlanActions';
import {
  useDispatch,
  useSelectAccountId,
  useStatus,
  useSelectPaymentPlans,
  useAnalytics,
  useSelectBannerInfo,
  useLiveChat
} from 'hooks';

const messages = defineMessages({
  title: { id: 'selectRepaymentOption.title' },
  createPaymentPlan: { id: 'selectRepaymentOption.createPaymentPlan' },
  payInFull: { id: 'selectRepaymentOption.payInFull' },
  partialPayment: { id: 'selectRepaymentOption.partialPayment' },
  amountSummary: { id: 'partialPaymentPage.amountSummary' }
});

const RepaymentOptionPage = () => {
  const intl = useIntl();
  const history = useHistory();
  const { trackEvent } = useAnalytics();
  const { accountId } = useSelectAccountId();
  const { hasPaymentPlans } = useSelectPaymentPlans();
  const { remainingBalance } = useSelectBannerInfo();
  const { status } = useStatus(getPaymentPlans);
  const getPlanOptions = useDispatch(paymentPlanOptions);
  const paymentPlansRequest = useDispatch(getPaymentPlans);
  const selectOption = useDispatch(selectRepaymentOption);
  const selectPaymentPlan = useDispatch(selectPlan);
  const resetSelectedRepaymentOption = useDispatch(resetRepaymentOption);
  const setPlanSummary = useDispatch(savePlanSummary);
  const { optionStatus } = useStatus(paymentPlanOptions);

  useEffect(() => {
    accountId && !hasPaymentPlans && paymentPlansRequest(accountId);
  }, [accountId, hasPaymentPlans, paymentPlansRequest]);

  useEffect(() => {
    resetSelectedRepaymentOption();
    trackEvent(MIXPANEL_EVENTS.viewRepaymentOptions);
    optionStatus !== FULFILLED && getPlanOptions(accountId);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useLiveChat();

  const onRepaymentOptionSelected = option => {
    selectOption(option);
    trackEvent(MIXPANEL_EVENTS.selectRepaymentOption, { option });

    let route;

    switch (option) {
      case REPAYMENT_OPTIONS.createPlan:
        route = routes.createPaymentPlan;
        break;
      case REPAYMENT_OPTIONS.partialPayment:
        route = routes.partialPayment;
        break;
      case REPAYMENT_OPTIONS.payInFull:
      default:
        route = routes.setupPlanPayment;
        setPlanSummary(
          intl.formatMessage(messages.amountSummary, { selectedAmount: `$${remainingBalance}` })
        );
        selectPaymentPlan(DEFAULT_PLAN);
        break;
    }

    history.push(route);
  };

  return (
    <ContentContainer>
      <BackBanner
        ariaLabel={intl.formatMessage({ id: 'createPlan.goBackAria' })}
        title={intl.formatMessage({ id: 'createPlan.goBack' })}
        backTo={routes.index}
      />
      <div className="payment-container">
        <div className="container">
          <h1 className="page-title">{intl.formatMessage(messages.title)}</h1>
          {status === PENDING && <Loading />}
          {status === FULFILLED && (
            <div className="tile-button-container">
              {remainingBalance >= 1 && (
                <>
                  <TileButton
                    label={intl.formatMessage(messages.payInFull)}
                    ariaLabel={intl.formatMessage(messages.payInFull)}
                    onClick={() => onRepaymentOptionSelected(REPAYMENT_OPTIONS.payInFull)}
                    icon={<PayInFullIcon />}
                  />

                  {!hasPaymentPlans && (
                    <>
                      <TileButton
                        label={intl.formatMessage(messages.createPaymentPlan)}
                        ariaLabel={intl.formatMessage(messages.createPaymentPlan)}
                        onClick={() => onRepaymentOptionSelected(REPAYMENT_OPTIONS.createPlan)}
                        icon={<PaymentPlanIcon />}
                      />
                    </>
                  )}

                  <TileButton
                    label={intl.formatMessage(messages.partialPayment)}
                    ariaLabel={intl.formatMessage(messages.partialPayment)}
                    onClick={() => onRepaymentOptionSelected(REPAYMENT_OPTIONS.partialPayment)}
                    icon={<PartialPaymentIcon />}
                  />
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </ContentContainer>
  );
};

export default RepaymentOptionPage;
