import React from 'react';
import { Box, Text, Link } from '@chakra-ui/react';
import { useIntl } from 'react-intl';

import { Pane } from 'components/common';
import { TELEPHONE_CONTACT } from 'constants/constants';
import InfoIcon from 'assets/icons/infoIcon.svg';

const CancelPlan = () => {
  const intl = useIntl();

  return (
    <Pane className="cancel-plan-block" wide>
      <Box
        mr={4}
        h="100%"
        backgroundColor="secondaryColor"
        borderRadius="7px 0 0 7px"
        minW="14px"
      />
      <Box mr={6} minW="38px">
        <InfoIcon />
      </Box>
      <div>
        <Text fontSize="16px" lineHeight="23px" mr={3}>
          {intl.formatMessage({ id: 'cancelPlan.title' })}
          <Link color="black" fontWeight={700} href={`tel:${TELEPHONE_CONTACT}`}>
            {TELEPHONE_CONTACT}
          </Link>
        </Text>
      </div>
    </Pane>
  );
};

export default CancelPlan;
