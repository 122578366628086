import React from 'react';
import { AccordionItemPanel } from 'react-accessible-accordion';
import { string } from 'prop-types';
import { useIntl } from 'react-intl';

const AccountAccordionItem = ({ title, value }) => {
  const intl = useIntl();

  return value ? (
    <AccordionItemPanel className="accordion-item-content">
      <div className="accordion-item-title">{intl.formatMessage({ id: title })}</div>
      <div className="accordion-item-value">{value}</div>
    </AccordionItemPanel>
  ) : null;
};

AccountAccordionItem.propTypes = {
  title: string.isRequired,
  value: string
};

export default AccountAccordionItem;
