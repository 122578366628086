import { useSelector, shallowEqual } from 'react-redux';

const useSelectAccountId = () =>
  useSelector(
    ({ accounts: { accountSelected } }) => ({
      accountId: accountSelected?.accountId
    }),
    shallowEqual
  );

export default useSelectAccountId;
