import { useSelector, shallowEqual } from 'react-redux';

const useSelectAccountOptions = () =>
  useSelector(
    ({ accounts: { accountOptions } }) => ({
      accountOptions
    }),
    shallowEqual
  );

export default useSelectAccountOptions;
