import { useSelector, shallowEqual } from 'react-redux';

const useSelectDisclosures = () =>
  useSelector(
    ({ accounts: { solDisclosureID, currentCreditorName } }) => ({
      solDisclosureID,
      currentCreditorName
    }),
    shallowEqual
  );

export default useSelectDisclosures;
