import React from 'react';
import { oneOfType, bool, func, string, arrayOf, object } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import useDropdownMenu from 'react-accessible-dropdown-menu-hook';

import { Arrow } from 'components/common';

const Select = ({
  label,
  name,
  value,
  placeholder,
  active,
  error,
  touched,
  options,
  selectedOption,
  onOptionSelected,
  className = '',
  selectedFrequency = {},
  ...props
}) => {
  const {
    buttonProps,
    itemProps: [itemProps],
    isOpen,
    setIsOpen
  } = useDropdownMenu(options.length);

  const selectOption = frequency => {
    setIsOpen(false);
    onOptionSelected(frequency);
  };

  return (
    <div className={cn(className, 'input-component select')}>
      {label && (
        <label className="input-component__label" htmlFor={name}>
          {label}
        </label>
      )}
      <div className="input-component-container">
        <button
          className={cn('input-component-container-content', {
            'input-component-container-content--error': error
          })}
          type="button"
          name={name}
          onClick={() => setIsOpen(true)}
          {...buttonProps}
          {...props}
        >
          {selectedFrequency?.value}
          <Arrow className="ml-3" />
        </button>
        <div className={cn('select-options', isOpen ? 'd-block' : 'd-none')} role="menu">
          {options.map(({ key, value }) => {
            const onSelected = () => selectOption({ key, value });

            return (
              <span
                key={key}
                {...itemProps}
                role="menuitem"
                onClick={onSelected}
                onKeyDown={onSelected}
                tabIndex={0}
                className={cn('select-options-item', {
                  active: selectedFrequency?.value === value
                })}
              >
                {value}
              </span>
            );
          })}
        </div>
        {error && (
          <span className="span--error">
            <FormattedMessage id={error} defaultMessage={error} />
          </span>
        )}
      </div>
    </div>
  );
};

Select.propTypes = {
  name: string,
  label: string,
  value: string,
  min: string,
  placeholder: string,
  error: oneOfType([string, bool]),
  active: bool,
  touched: bool,
  options: arrayOf(object).isRequired,
  selectedOption: string,
  onOptionSelected: func.isRequired,
  className: string,
  selectedFrequency: object
};

export default Select;
