/**
 * Parses the body of an error response.
 *
 */
export default response => {
  if (!response.data) return 'Something went wrong';

  const {
    status,
    data: { msg }
  } = response;

  if (msg) return { message: msg, code: status.toString() };

  return 'Something went wrong';
};
