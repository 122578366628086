import React from 'react';
import Carousel from 'react-multi-carousel';
import { useIntl } from 'react-intl';
import { arrayOf, shape, number, string } from 'prop-types';

import { CurrencyText } from 'components/common';
import { useBreakpoint } from 'hooks';
import { PAYMENT_DATES_MEDIA_QUERIES } from 'constants/constants';
import { md } from 'styles/_variables.scss';
import {
  PAYMENT_DATES_DAY_FORMAT,
  PAYMENT_DATES_MONTH_FORMAT,
  formatDate
} from 'utils/dateHelpers';

const PaymentPlanCarousel = ({ schedules }) => {
  const intl = useIntl();
  const isDesktop = useBreakpoint(md);

  return (
    <>
      <div className="payment-plan-next-payments">
        {intl.formatMessage({ id: 'paymentPlans.nextPayments' })}
      </div>
      <Carousel
        responsive={PAYMENT_DATES_MEDIA_QUERIES}
        partialVisible={!isDesktop}
        itemClass="payment-schedule-item"
        arrows={isDesktop}
      >
        {schedules.map(({ paymentDate, paymentAmount, paymentId }) => (
          <div className="payment-schedule-container" key={`payment-${paymentId}`}>
            <div className="payment-schedule-container-day">
              {formatDate(paymentDate, PAYMENT_DATES_DAY_FORMAT)}
            </div>
            <div className="payment-schedule-container-month">
              {formatDate(paymentDate, PAYMENT_DATES_MONTH_FORMAT)}
            </div>
            <div className="payment-schedule-container-amount">
              <CurrencyText value={paymentAmount} />
            </div>
          </div>
        ))}
      </Carousel>
    </>
  );
};

PaymentPlanCarousel.propTypes = {
  schedules: arrayOf(
    shape({
      paymentId: string.isRequired,
      paymentDate: string.isRequired,
      paymentAmount: number.isRequired
    })
  ).isRequired
};

export default PaymentPlanCarousel;
