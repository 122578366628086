import { useSelector, shallowEqual } from 'react-redux';

const useSelectShortUrl = () =>
  useSelector(
    ({ settlementOffer: { shortUrl } }) => ({
      shortUrl
    }),
    shallowEqual
  );

export default useSelectShortUrl;
