import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: { id: 'specialPaymentPlan.title' },
  payFullBalance: { id: 'specialPaymentPlan.payFullBalance' },
  selectFirstPaymentDate: { id: 'specialPaymentPlan.selectFirstPaymentDate' },
  standardPayments: { id: 'specialPaymentPlan.standardPayments' },
  finalPayment: { id: 'specialPaymentPlan.finalPayment' },
  or: { id: 'specialPaymentPlan.or' },
  proposedAmountLabel: { id: 'specialPaymentPlan.proposedAmountLabel' },
  proposedAmountTooHigh: { id: 'specialPaymentPlan.proposedAmountTooHigh' },
  proposedAmountTooLow: { id: 'specialPaymentPlan.proposedAmountTooLow' },
  recalculatePaymentsLabel: { id: 'specialPaymentPlan.recalculatePaymentsLabel' },
  selectPaymentMethodLabel: { id: 'specialPaymentPlan.selectPaymentMethodLabel' },
  subtitle: { id: 'settlement.subtitle' },
  amountTitle: { id: 'settlement.amountTitle' },
  offerTitle: { id: 'settlement.offerTitle' },
  referenceIdTitle: { id: 'settlement.referenceIdTitle' },
  selectYourPayments: { id: 'settlement.selectYourPayments' },
  selectMonthAndDay: { id: 'settlement.selectMonthAndDay' },
  selectMonth: { id: 'settlement.selectMonth' },
  selectDay: { id: 'settlement.selectDay' },
  paymentDates: { id: 'settlement.paymentDates' },
  setUpPayment: { id: 'settlement.setUpPayment' },
  paymentDateQuestion: { id: 'settlement.paymentDateQuestion' },
  daysDisclaimerBegin: { id: 'settlement.daysDisclaimerBegin' },
  daysDisclaimerEnd: { id: 'settlement.daysDisclaimerEnd' },
  selectYourPaymentMethod: { id: 'settlement.selectYourPaymentMethod' },
  of: { id: 'settlement.of' },
  miniMiranda: { id: 'settlement.miniMiranda' },
  knowYourRights: { id: 'settlement.knowYourRights' },
  modalTitle: { id: 'specialPaymentPlanModal.title' },
  modalButtonText: { id: 'specialPaymentPlanModal.buttonText' },
  modalBodyTextHigh: { id: 'specialPaymentPlanModal.bodyTextHigh' },
  modalBodyTextLow: { id: 'specialPaymentPlanModal.bodyTextLow' }
});
