import React from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import { useDispatch, useAnalytics } from 'hooks';
import LogoutIcon from 'assets/icons/logoutIcon.svg';
import { Button } from 'components/common';
import { logout } from 'state/actions/userActions';
import { mixPanelLocation } from 'utils/mixPanelLocation';
import { MIXPANEL_EVENTS } from 'constants/mixpanelEvents';

const Logout = () => {
  const intl = useIntl();
  const logoutRequest = useDispatch(logout);
  const { trackEvent } = useAnalytics();
  const location = useLocation();

  const handleLogout = () => {
    logoutRequest();
    trackEvent(MIXPANEL_EVENTS.logOut, mixPanelLocation(location.pathname));
  };

  return (
    <Button onClick={handleLogout} ariaLabel="Logout" className="button--icon p-1">
      <LogoutIcon />
      <span className="ml-4">{intl.formatMessage({ id: 'sidemenu.logout' })}</span>
    </Button>
  );
};

export default Logout;
