import React, { useEffect } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton
} from 'react-accessible-accordion';
import { useIntl } from 'react-intl';

import { Arrow } from 'components/common';
import { useSelectAccountDetails, useToggle, useAnalytics } from 'hooks';
import { formatDate, ACCOUNT_DATE_FORMAT } from 'utils/dateHelpers';
import { MIXPANEL_EVENTS } from 'constants/mixpanelEvents';
import AccountAccordionItem from './AccountAccordionItem';

const AccountDetails = () => {
  const intl = useIntl();
  const [isExpanded, toggleExpanded] = useToggle();
  const { trackEvent } = useAnalytics();
  const {
    originalCreditor,
    accountNumber,
    referenceNumber,
    openedDate,
    vehicleMake,
    vehicleModel,
    vehicleYear,
    vin,
    cardStoreBrand
  } = useSelectAccountDetails();

  const accordionItems = [
    { title: 'accounts.originalCreditor', value: originalCreditor },
    { title: 'accounts.referenceNumber', value: referenceNumber },
    { title: 'accounts.openedDate', value: formatDate(openedDate, ACCOUNT_DATE_FORMAT) },
    { title: 'accounts.accountNumber', value: accountNumber },
    { title: 'accounts.vehicleMake', value: vehicleMake },
    { title: 'accounts.vehicleModel', value: vehicleModel },
    { title: 'accounts.vehicleYear', value: vehicleYear },
    { title: 'accounts.vin', value: vin },
    { title: 'accounts.storeCard', value: cardStoreBrand }
  ];

  useEffect(() => {
    isExpanded && trackEvent(MIXPANEL_EVENTS.expandAccountDetails);
    isExpanded === false && trackEvent(MIXPANEL_EVENTS.collapseAccountDetails);
  }, [isExpanded]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="w-100">
      <Accordion allowZeroExpanded onChange={toggleExpanded}>
        <AccordionItem>
          <AccordionItemHeading className="accordion-title">
            <AccordionItemButton>
              {intl.formatMessage({
                id: isExpanded ? 'accounts.hideDetails' : 'accounts.showDetails'
              })}
              <Arrow type={isExpanded ? 'up' : 'down'} className="ml-2 ml-md-5" />
            </AccordionItemButton>
          </AccordionItemHeading>
          {isExpanded && (
            <div className="d-flex row pt-4 w-100">
              {accordionItems.map(({ ...props }, index) => (
                <AccountAccordionItem key={`account-accordion-item-${index}`} {...props} />
              ))}
            </div>
          )}
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default AccountDetails;
