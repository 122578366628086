import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Redirect, useLocation } from 'react-router-dom';
import cn from 'classnames';

import {
  useSession,
  useAnalytics,
  useDispatch,
  useSelectDisclosuresAccepted,
  useStatus
} from 'hooks';
import { acceptDisclosures, disclosures } from 'state/actions/userActions';
import { FixedFooter, ContentContainer } from 'components/common';
import Disclosures from 'components/legal/Disclosures';
import routes from 'constants/routesPaths';
import { MIXPANEL_EVENTS } from 'constants/mixpanelEvents';
import { FULFILLED } from 'constants/actionStatusConstants';
import Loader from 'react-loader-spinner';
import { IFRAME_LOADER_FORMAT, IFRAME_LOADER_SIZE } from 'constants/constants';
import { green } from 'styles/_variables.scss';

const DisclosuresPage = () => {
  const intl = useIntl();
  const { authenticated } = useSession();
  const { trackEvent } = useAnalytics();
  const location = useLocation();
  const { disclosuresAccepted } = useSelectDisclosuresAccepted();
  const acceptDisclosuresRequest = useDispatch(acceptDisclosures);
  const getDisclosures = useDispatch(disclosures);
  const { status } = useStatus(disclosures);
  const [, , referenceNumber] = location.pathname.split('/');

  const handleAcceptDisclosures = () => {
    trackEvent(MIXPANEL_EVENTS.acceptDisclosures);
    acceptDisclosuresRequest();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    trackEvent(MIXPANEL_EVENTS.viewDisclosuresPage);
    if (referenceNumber) getDisclosures(referenceNumber);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (disclosuresAccepted && location.pathname === routes.disclosures) {
    return location.state?.settlementUrl ? (
      <Redirect to={location.state?.settlementUrl} />
    ) : (
      <Redirect to={routes.index} />
    );
  }

  return status === FULFILLED || !referenceNumber ? (
    <ContentContainer
      noMargin={location.state?.hideMenu}
      className={cn({ scrollable: !location.state?.hideMenu })}
    >
      <div className="container">
        <Disclosures />
        {location.state?.hideMenu &&
          (location.state?.settlementUrl ? (
            <FixedFooter
              title={intl.formatMessage({ id: 'footer.continue' })}
              route={location.state?.settlementUrl}
              ariaLabel={intl.formatMessage({ id: 'footer.continueAria' })}
            />
          ) : authenticated ? (
            <FixedFooter
              title={intl.formatMessage({ id: 'footer.continue' })}
              route={routes.index}
              ariaLabel={intl.formatMessage({ id: 'footer.continueAria' })}
              onClick={handleAcceptDisclosures}
            />
          ) : (
            <FixedFooter
              title={intl.formatMessage({ id: 'footer.login' })}
              route={routes.login}
              ariaLabel={intl.formatMessage({ id: 'footer.loginAria' })}
            />
          ))}
      </div>
    </ContentContainer>
  ) : (
    <div className="loader-container">
      <Loader
        visible
        type={IFRAME_LOADER_FORMAT}
        color={green}
        height={IFRAME_LOADER_SIZE}
        width={IFRAME_LOADER_SIZE}
      />
    </div>
  );
};

export default DisclosuresPage;
