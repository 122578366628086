import React from 'react';
import { string } from 'prop-types';

import { white } from 'styles/_variables.scss';

const CalendarIcon = ({ color = white }) => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.3334 2.49999H12.6667V1.16666H11.3334V2.49999H4.66671V1.16666H3.33337V2.49999H2.66671C1.93337 2.49999 1.33337 3.09999 1.33337 3.83332V14.5C1.33337 15.2333 1.93337 15.8333 2.66671 15.8333H13.3334C14.0667 15.8333 14.6667 15.2333 14.6667 14.5V3.83332C14.6667 3.09999 14.0667 2.49999 13.3334 2.49999ZM13.3334 14.5H2.66671V7.16666H13.3334V14.5ZM13.3334 5.83332H2.66671V3.83332H13.3334V5.83332Z"
      fill={color}
    />
  </svg>
);

CalendarIcon.propTypes = {
  color: string
};

export default CalendarIcon;
