/**
 * The variants will be formed by two types: color and structure.
 * The color is defined by the first part of the variant, primary will be mainly using alpha colors,
 * success will use beta colors, destructive epsilon and so on.
 *
 * The structure of the button will be defined by the subVariant, base variants are solid buttons,
 * outline variants have a border defined, and link variants do not have a border and just the text
 *
 * all the variants defined in this document are formed by "[variant][subVariant]". For example, an
 * outlined green button will have the variant prop set in `successOutline`.
 */

const variantColors = {
  primary: {
    base: 'alpha.500',
    color: 'white',
    baseHover: 'alpha.600',
    outlinedHover: 'alpha.50'
  },
  success: {
    base: 'beta.500',
    color: 'white',
    baseHover: 'beta.600',
    outlinedHover: 'beta.50'
  },
  destructive: {
    base: 'epsilon.500',
    color: 'white',
    baseHover: 'epsilon.600',
    outlinedHover: 'epsilon.50'
  },
  secondary: {
    base: 'gamma.500',
    color: 'white',
    baseHover: 'gamma.600',
    outlinedHover: 'gamma.50'
  }
};

const buildBaseVariant = (color, bgColor, hoverColor) => ({
  color,
  bg: bgColor,

  _focus: {
    boxShadow: 'outline'
  },

  _disabled: {
    opacity: 0.4,
    cursor: 'not-allowed',
    boxShadow: 'none'
  },

  _loading: {
    bg: hoverColor,
    cursor: 'wait',
    _hover: {
      bg: hoverColor
    }
  },

  _hover: {
    bg: hoverColor
  }
});

const buildOutlinedVariant = (color, bgColor, hoverColor) => ({
  color,
  bg: bgColor,
  border: '1px solid',
  borderColor: color,

  _focus: {
    boxShadow: 'outline'
  },

  _disabled: {
    opacity: 0.4,
    cursor: 'not-allowed',
    boxShadow: 'none'
  },

  _hover: {
    color: bgColor,
    bg: hoverColor
  }
});

const buildLinkVariant = (color, bgColor, hoverColor) => ({
  color,
  bg: bgColor,

  _focus: {
    boxShadow: 'outline'
  },

  _disabled: {
    opacity: 0.4,
    cursor: 'not-allowed',
    boxShadow: 'none'
  },

  _loading: {
    bg: hoverColor,
    cursor: 'wait',
    _hover: {
      bg: hoverColor
    }
  },

  _hover: {
    color: bgColor,
    bg: hoverColor
  }
});

const variantDefinition = {};

Object.keys(variantColors).map(variantName => {
  const styles = variantColors[variantName];
  variantDefinition[`${variantName}Base`] = buildBaseVariant(
    styles.color,
    styles.base,
    styles.baseHover
  );
  variantDefinition[`${variantName}Outline`] = buildOutlinedVariant(
    styles.base,
    styles.color,
    styles.outlinedHover
  );
  variantDefinition[`${variantName}Link`] = buildLinkVariant(
    styles.base,
    styles.color,
    styles.outlinedHover
  );
});

const ButtonStyle = {
  baseStyle: {
    borderRadius: '24px',
    fontWeight: 'bold'
  },
  sizes: {
    lg: {
      h: '48px',
      px: 6,
      py: 2.4,
      fontSize: '18px',
      lineHeight: '27px'
    },
    md: {
      h: '40px',
      px: 4,
      py: 2,
      fontSize: '16px',
      lineHeight: '24px'
    },
    sm: {
      h: '31px',
      px: 3,
      py: 1.25,
      fontSize: '14px',
      lineHeight: '21px'
    }
  },
  variants: variantDefinition
};

export default ButtonStyle;
