import React, { useState, useEffect, memo } from 'react';
import { useIntl } from 'react-intl';
import { number } from 'prop-types';

import { CurrencyText, Tooltip } from 'components/common';
import { useBreakpoint } from 'hooks';
import { toFixed } from 'utils/helpers';
import { md } from 'styles/_variables.scss';

const ProgressBar = ({ originalBalance, remainingBalance }) => {
  const intl = useIntl();
  const isDesktop = useBreakpoint(md);
  const [paid, setPaid] = useState(0);
  const balance = toFixed(originalBalance - remainingBalance);

  const paidStyles = {
    width: paid > 0 ? `${paid}%` : '16px'
  };

  useEffect(() => {
    originalBalance > 0 && setPaid(Math.floor((balance * 100) / originalBalance));
  }, [remainingBalance, originalBalance]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="progress-details">
      <div className="w-100 pt-2">
        <div className="progress progress-bar-container">
          <div
            className="progress-bar progress-amount"
            aria-valuenow={balance}
            aria-valuemin="0"
            aria-valuemax={originalBalance}
            style={paidStyles}
          />
        </div>

        <div className="d-flex mt-2">
          {intl.formatMessage({ id: 'accounts.paid' })}
          <div className="balance">
            <CurrencyText value={balance} />
          </div>
          {!isDesktop && (
            <>
              <div className="ml-2">{intl.formatMessage({ id: 'accounts.paidOf' })}</div>
              <div className="balance">
                <CurrencyText value={originalBalance} />
              </div>
            </>
          )}
        </div>
      </div>

      {isDesktop && (
        <div className="account-balance">
          <div className="account-original-balance">
            <div className="balance">
              <CurrencyText value={originalBalance} />
            </div>
            <div className="tooltip-container">
              <div className="tooltip-original-balance">
                {intl.formatMessage({ id: 'accounts.originalBalance' })}
              </div>
              <Tooltip
                id="originalBalanceTooltip"
                ariaLabel={intl.formatMessage({ id: 'originalBalance.tooltip.aria' })}
                title={intl.formatMessage({ id: 'originalBalance.tooltip.title' })}
                description={intl.formatMessage({ id: 'originalBalance.tooltip.description' })}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

ProgressBar.propTypes = {
  originalBalance: number.isRequired,
  remainingBalance: number.isRequired
};

export default memo(ProgressBar);
