import React from 'react';
import { Flex, Button, Text, useTheme } from '@chakra-ui/react';
import { useIntl, defineMessages } from 'react-intl';
import { string, func } from 'prop-types';
import { SETTLEMENT_OPTIONS } from 'constants/constants';
import MoneyIcon from '@mui/icons-material/PaidOutlined';
import CalendarIcon from '../../icons/CalendarIcon';

const PaymentToggle = ({ paymentSelected, setSelectedPayment, leftLabel, rightLabel }) => {
  const intl = useIntl();

  const messages = defineMessages({
    settleInFull: { id: 'settlement.settleInFull' },
    createPlan: { id: 'settlement.createPlan' }
  });

  if (leftLabel === undefined) {
    leftLabel = intl.formatMessage(messages.settleInFull);
  }

  if (rightLabel === undefined) {
    rightLabel = intl.formatMessage(messages.createPlan);
  }

  const theme = useTheme();

  const isCreatePlan = paymentSelected === SETTLEMENT_OPTIONS.createPlan;
  const isSettleInFull = paymentSelected === SETTLEMENT_OPTIONS.settleInFull;

  return (
    <Flex padding={1} background="rgba(104, 157, 13, 0.15)" borderRadius="62px" mt={6}>
      <Button
        _active={{
          background: isSettleInFull ? 'beta.500' : 'transparent'
        }}
        _focus={{
          background: isSettleInFull ? 'beta.500' : 'transparent'
        }}
        _hover={{
          background: isSettleInFull ? 'beta.500' : 'transparent'
        }}
        backgroundColor={isSettleInFull ? 'beta.500' : 'transparent'}
        borderRadius="24px"
        paddingY={{ base: 2, md: 2.5 }}
        paddingX={{ base: 2.5, md: 5 }}
        height="100%"
        onClick={() => setSelectedPayment(SETTLEMENT_OPTIONS.settleInFull)}
      >
        <Flex alignItems="center" justifyContent="space-between">
          <MoneyIcon
            sx={{
              color: isSettleInFull ? theme.colors.white : theme.colors.beta[500],
              height: '17px',
              width: '17px'
            }}
          />
          <Text
            ml={2}
            fontSize={{ base: 'md', md: 'lg' }}
            color={isSettleInFull ? 'white' : 'beta.500'}
          >
            {leftLabel}
          </Text>
        </Flex>
      </Button>
      <Button
        _active={{
          background: isCreatePlan ? 'beta.500' : 'transparent'
        }}
        _focus={{
          background: isCreatePlan ? 'beta.500' : 'transparent'
        }}
        _hover={{
          background: isCreatePlan ? 'beta.500' : 'transparent'
        }}
        backgroundColor={isCreatePlan ? 'beta.500' : 'transparent'}
        borderRadius="24px"
        paddingY={{ base: 2, md: 2.5 }}
        paddingX={{ base: 2.5, md: 5 }}
        height="100%"
        onClick={() => setSelectedPayment(SETTLEMENT_OPTIONS.createPlan)}
      >
        <Flex alignItems="center" justifyContent="space-between">
          <CalendarIcon color={isCreatePlan ? theme.colors.white : theme.colors.beta[500]} />
          <Text
            ml={2}
            fontSize={{ base: 'md', md: 'lg' }}
            color={isCreatePlan ? theme.colors.white : theme.colors.beta[500]}
          >
            {rightLabel}
          </Text>
        </Flex>
      </Button>
    </Flex>
  );
};

PaymentToggle.propTypes = {
  paymentSelected: string.isRequired,
  setSelectedPayment: func.isRequired,
  leftLabel: string,
  rightLabel: string
};

export default PaymentToggle;
