import { useSelector, shallowEqual } from 'react-redux';

const useSelectSettlementReferrer = () =>
  useSelector(
    ({ settlementOffer: { ref } }) => ({
      ref
    }),
    shallowEqual
  );

export default useSelectSettlementReferrer;
