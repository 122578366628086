import React from 'react';
import { node, bool, string } from 'prop-types';
import cn from 'classnames';

const ContentContainer = ({ children, noMargin = false, className = '' }) => (
  <div className={cn('content-container', className, { 'no-margin': noMargin })}>{children}</div>
);

ContentContainer.propTypes = {
  children: node.isRequired,
  noMargin: bool,
  className: string
};

export default ContentContainer;
