import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import AccountAccordion from 'components/accounts/AccountAccordion';
import { CurrencyText, ProgressBar } from 'components/common';
import routes from 'constants/routesPaths';
import { useSelectAccountDetails, useAnalytics, useSelectPaymentPlans, useDispatch } from 'hooks';
import { MIXPANEL_EVENTS } from 'constants/mixpanelEvents';
import { REPAYMENT_OPTIONS } from 'constants/constants';
import ExperimentWrapper from 'components/ABTesting';
import { activeExperiments } from 'constants/experiments';
import TextDisclosure from 'components/paymentDisclosure/TextDisclosure';
import ModalDisclosure from 'components/paymentDisclosure/ModalDisclosure';
import {
  selectRepaymentOption,
  savePlanSummary,
  selectPlan
} from 'state/actions/paymentPlanActions';
import { Box, Button } from '@chakra-ui/react';

const AccountDetails = () => {
  const intl = useIntl();
  const history = useHistory();
  const { hasPaymentPlans } = useSelectPaymentPlans();
  const { originalBalance, remainingBalance } = useSelectAccountDetails();
  const { trackEvent } = useAnalytics();
  const selectOption = useDispatch(selectRepaymentOption);
  const setPlanSummary = useDispatch(savePlanSummary);
  const selectPaymentPlan = useDispatch(selectPlan);

  useEffect(() => {
    trackEvent(MIXPANEL_EVENTS.viewAccountDetailsPage);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onPayInFullClick = () => {
    trackEvent(MIXPANEL_EVENTS.clickPayInFull);
    selectOption(REPAYMENT_OPTIONS.payInFull);
    setPlanSummary(
      intl.formatMessage(
        { id: 'partialPaymentPage.amountSummary' },
        { selectedAmount: `$${remainingBalance}` }
      )
    );
    selectPaymentPlan({
      planTerm: 1,
      planScheduleFrequency: 1
    });
    history.push(routes.setupPlanPayment);
  };

  const payInFullLabel = intl.formatMessage({ id: 'selectRepaymentOption.payInFull' });

  const NoDisclosure = () => null;

  const variants = {
    [activeExperiments?.paymentMethodDisclosures?.variants?.[0]?.name]: NoDisclosure,
    [activeExperiments?.paymentMethodDisclosures?.variants?.[1]?.name]: TextDisclosure,
    [activeExperiments?.paymentMethodDisclosures?.variants?.[2]?.name]: ModalDisclosure
  };

  return (
    <div className="account-details-component">
      <div className="account-remaining-balance">
        <div className="d-flex align-items-center pb-2 pb-md-0">
          {intl.formatMessage({ id: 'accounts.remainingBalance' })}
          <div className="balance">
            <CurrencyText value={remainingBalance} />
          </div>
        </div>
        {remainingBalance > 0 && (
          <Button
            variant="successBase"
            px="2rem"
            size="md"
            ariaLabel={payInFullLabel}
            onClick={onPayInFullClick}
          >
            {payInFullLabel}
          </Button>
        )}
      </div>
      <ProgressBar originalBalance={originalBalance} remainingBalance={remainingBalance} />
      {hasPaymentPlans && (
        <Box className="view-payment-plans-container">
          <Button
            my="1rem"
            onClick={() => history.push(routes.paymentPlans)}
            variant="primaryOutline"
            size="sm"
          >
            {intl.formatMessage({ id: 'paymentPlans.viewAll' })}
          </Button>
        </Box>
      )}
      {!hasPaymentPlans && (
        <ExperimentWrapper
          experimentName={activeExperiments?.paymentMethodDisclosures?.name}
          variantContent={variants}
        />
      )}
      <AccountAccordion />
    </div>
  );
};

export default AccountDetails;
