import { useSelector, shallowEqual } from 'react-redux';

const useSelectAccountSelectOpen = () =>
  useSelector(
    ({ accounts: { isSelectAccountOpened } }) => ({
      isSelectAccountOpened
    }),
    shallowEqual
  );

export default useSelectAccountSelectOpen;
