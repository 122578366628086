import { createSlice } from '@reduxjs/toolkit';
import { SETTLEMENT_OPTIONS } from 'constants/constants';
import {
  settlementShortUrl,
  settlementReferrer,
  settlementInitialUrlFulfilled,
  settlementOfferFulfilled,
  selectSettlementOption,
  selectDate,
  selectSettlementPlan,
  selectSettlementPaymentMethod,
  createPaymentPlanFulfilled,
  verifyPhoneNumberFulfilled,
  hydrateFromSpecialPlan
} from 'state/actions/settlementOfferActions';

const initialState = {
  shortUrl: undefined,
  ref: undefined,
  verified: undefined,
  settlementUrl: undefined,
  referenceNumber: undefined,
  selectedSettlementOption: SETTLEMENT_OPTIONS.settleInFull,
  selectedDate: undefined,
  selectedSettlementPlan: undefined,
  selectedSettlementPaymentMethod: undefined,
  expiryDate: undefined,
  currentCreditorName: undefined,
  newPlan: undefined
};

const settlementOffer = createSlice({
  name: 'settlementOffer',
  initialState,
  extraReducers: {
    [settlementShortUrl]: (state, { payload }) => {
      state.shortUrl = payload.shortUrlPath;
    },
    [settlementReferrer]: (state, { payload }) => {
      state.ref = payload.ref;
    },
    [verifyPhoneNumberFulfilled]: (state, { payload }) => {
      state.verified = payload.verified;
    },
    [settlementInitialUrlFulfilled]: (state, { payload }) => {
      state.settlementUrl = payload.fullUrlPath;
      state.referenceNumber = payload.referenceNumber;
      state.verified = payload.verified;
      state.solDisclosureID = payload.disclosureData.solDisclosureID;
      state.currentCreditorName = payload.disclosureData.currentCreditorName;
    },
    [settlementOfferFulfilled]: (state, { payload }) => {
      state.currentBalance = payload.currentBalance;
      state.offerAmount = payload.offerAmount;
      state.settlementTermsOffered = payload.termsOffered;
      state.expiryDate = payload.expiryDate;
      state.currentCreditorName = payload.currentCreditorName;
    },
    [selectSettlementOption]: (state, { payload }) => {
      state.selectedSettlementOption = payload;
    },
    [selectDate]: (state, { payload }) => {
      state.selectedDate = payload;
    },
    [selectSettlementPlan]: (state, { payload }) => {
      state.selectedSettlementPlan = payload;
    },
    [selectSettlementPaymentMethod]: (state, { payload }) => {
      state.selectedSettlementPaymentMethod = payload;
    },
    [createPaymentPlanFulfilled]: (state, { payload }) => {
      state.newPlan = payload;
    },
    [hydrateFromSpecialPlan]: (state, { payload }) => {
      state.offerAmount = payload.offerAmount;
      state.selectedSettlementPlan.numberOfMonths = payload.numberOfMonths;
    }
  }
});

export default settlementOffer.reducer;
