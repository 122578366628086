import { useSelector, shallowEqual } from 'react-redux';

const useSelectPaymentInfo = () =>
  useSelector(
    ({
      paymentMethod: {
        paymentToken,
        last4,
        cardType,
        paymentDetails,
        routingNumber,
        source,
        nameOnAccount
      }
    }) => ({
      paymentToken,
      last4,
      cardType,
      paymentDetails,
      routingNumber,
      source,
      nameOnAccount
    }),
    shallowEqual
  );

export default useSelectPaymentInfo;
