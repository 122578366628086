import { useSelector, shallowEqual } from 'react-redux';

const useSelectPaymentMethod = () =>
  useSelector(
    ({ paymentMethod: { selectedPaymentMethod } }) => ({
      selectedPaymentMethod
    }),
    shallowEqual
  );

export default useSelectPaymentMethod;
