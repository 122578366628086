import React from 'react';
import { useIntl } from 'react-intl';
import Loader from 'react-loader-spinner';
import { TilePaymentMethod } from 'components/common';
import { Flex } from '@chakra-ui/react';
import { green } from 'styles/_variables.scss';
import cn from 'classnames';
import CardIcon from 'assets/icons/cardIcon.svg';
import BankIcon from 'assets/icons/bankIcon.svg';
import {
  IFRAME_LOADER_FORMAT,
  IFRAME_LOADER_SIZE,
  PAYMENT_METHOD_OPTIONS
} from 'constants/constants';
import { bool, string, func } from 'prop-types';

const PaymentOptionSection = ({
  hasPaymentForm,
  onPaymentMethodSelect,
  loaderVisible,
  setLoaderVisible,
  isCard,
  isBank,
  paymentFormUrl
}) => {
  const intl = useIntl();

  return (
    <Flex direction="column" w={{ base: '90%', md: '100%' }} mt={8} h="100%" alignItems="center">
      <div className="tile-button-container-settlement mb-0 justify-content-center">
        <TilePaymentMethod
          label={intl.formatMessage({ id: 'paymentMethod.card' })}
          ariaLabel={intl.formatMessage({ id: 'paymentMethod.card' })}
          active={isCard}
          onClick={() => onPaymentMethodSelect(PAYMENT_METHOD_OPTIONS.card)}
          icon={<CardIcon />}
        />
        <TilePaymentMethod
          label={intl.formatMessage({ id: 'paymentMethod.bank' })}
          ariaLabel={intl.formatMessage({ id: 'paymentMethod.bank' })}
          active={isBank}
          onClick={() => onPaymentMethodSelect(PAYMENT_METHOD_OPTIONS.bank)}
          icon={<BankIcon />}
        />
      </div>
      <div className="text-center">
        <Loader
          visible={loaderVisible}
          type={IFRAME_LOADER_FORMAT}
          color={green}
          height={IFRAME_LOADER_SIZE}
          width={IFRAME_LOADER_SIZE}
        />
      </div>
      <div
        className={cn(
          'payment-method-container',
          {
            'payment-method-card': isCard
          },
          {
            'payment-method-bank': isBank
          }
        )}
      >
        {hasPaymentForm && (
          <iframe
            title="Repay Payment Method"
            key={paymentFormUrl}
            src={paymentFormUrl}
            onLoad={() => setLoaderVisible(false)}
            scrolling="no"
            className="h-100 w-100"
          />
        )}
      </div>
    </Flex>
  );
};

PaymentOptionSection.propTypes = {
  hasPaymentForm: bool,
  onPaymentMethodSelect: func,
  loaderVisible: bool,
  setLoaderVisible: func,
  isCard: bool,
  isBank: bool,
  paymentFormUrl: string
};

export default PaymentOptionSection;
