const routes = {
  accountError: '/account-error',
  confirmPaymentPage: '/confirm-payment',
  createPaymentPlan: '/payment-plan/create',
  disclosures: '/disclosures',
  uniqueDisclosures: '/disclosures/:referenceNumber',
  documents: '/documents',
  homeDisclosures: '/home-disclosures',
  index: '/',
  login: '/login',
  partialPayment: '/payment-plan/partial',
  paymentErrorPage: '/payment-plan/error',
  paymentHistory: '/payment-history',
  paymentPlanSuccess: '/payment-plan/success',
  paymentPlans: '/payment-plans',
  repaymentOptions: '/repayment-options',
  selectAccount: '/select-account',
  settlementOffer: '/special-offer/user/:referenceNumber',
  settlementConfirm: '/confirm-special-offer-payment',
  settlementSuccess: '/special-offer/success',
  settlementOfferInitial: '/:ref/:token',
  settlementError: '/special-offer/error',
  setupPlanPayment: '/payment-plan/payment',
  version: '/version',
  notFound: '/not-found',
  addPaymentMethod: '/payment-plan/add-payment-method',
  paymentPlanLandingPage: '/payment-plan/user/:referenceNumber'
};

export default routes;
