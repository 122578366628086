import React from 'react';
import { string } from 'prop-types';

import { black } from 'styles/_variables.scss';

const ArrowMobileIcon = ({ color = black }) => (
  <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.712e-07 7.99999L0.686656 8.67824L8.11413 16L10 14.6435L3.25919 7.99999L10 1.35649L8.11414 -7.5368e-08L0.686656 7.32176L5.712e-07 7.99999Z"
      fill={color}
    />
  </svg>
);

ArrowMobileIcon.propTypes = {
  color: string
};

export default ArrowMobileIcon;
