import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { getOrdinal } from 'utils/helpers';

import { useIntl, defineMessages } from 'react-intl';

import { string, number } from 'prop-types';

const MonthPaymentTile = ({ monthAbr, day, amount, year }) => {
  const intl = useIntl();

  const messages = defineMessages({
    paymentDate: { id: 'settlement.paymentDate' },
    amount: { id: 'settlement.amount' }
  });

  return (
    <Flex
      direction="column"
      paddingY={3}
      paddingX={2.5}
      background="delta.50"
      borderStyle="solid"
      borderRadius="12px"
      borderWidth="1px"
      borderColor="gray.100"
      width="134px"
      height="150px"
      flexShrink={0}
      marginX="auto"
    >
      <Text fontSize="xs" color="gray.500">
        {intl.formatMessage(messages.paymentDate)}
      </Text>
      <Text mt={0.5} fontSize="md" color="alpha.500" fontWeight={700}>
        {monthAbr} {getOrdinal(day)}
      </Text>
      <Text fontSize="md" color="alpha.500" fontWeight={700}>
        {year}
      </Text>
      <Text mt={2.5} fontSize="xs" color="gray.500">
        {intl.formatMessage(messages.amount)}
      </Text>
      <Text mt={0.5} fontSize="md" color="alpha.500">
        ${amount}
      </Text>
    </Flex>
  );
};

MonthPaymentTile.propTypes = {
  monthAbr: string.isRequired,
  year: number.isRequired,
  day: number.isRequired,
  amount: number.isRequired
};

export default MonthPaymentTile;
