import React from 'react';
import { string, arrayOf, shape } from 'prop-types';

import SideMenuOption from './SideMenuOption';

const SideMenuSection = ({ options }) => (
  <section className="menu-options-container">
    {options.map(({ ...props }, index) => (
      <SideMenuOption key={index} {...props} />
    ))}
  </section>
);

SideMenuSection.propTypes = {
  options: arrayOf(
    shape({
      titleId: string.isRequired,
      ariaLabel: string.isRequired,
      route: string.isRequired
    })
  ).isRequired
};

export default SideMenuSection;
