import React from 'react';
import { number } from 'prop-types';
import NumberFormat from 'react-number-format';

const CurrencyText = ({ value }) => (
  <NumberFormat
    value={value}
    prefix="$"
    thousandSeparator
    fixedDecimalScale
    decimalScale={2}
    displayType="text"
  />
);

CurrencyText.propTypes = {
  value: number.isRequired
};

export default CurrencyText;
