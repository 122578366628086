import { useSelector, shallowEqual } from 'react-redux';

const useSelectSettlementPlan = () =>
  useSelector(
    ({ settlementOffer: { selectedSettlementPlan } }) => ({
      selectedSettlementPlan
    }),
    shallowEqual
  );

export default useSelectSettlementPlan;
