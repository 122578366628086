import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Button, Flex, Icon, Stack, Text } from '@chakra-ui/react';
import { ContentContainer } from 'components/common';
import illustration1 from 'assets/icons/illustration1.svg';

const ErrorSection = ({ description, ctaCallback }) => {
  const intl = useIntl();

  const messages = defineMessages({
    title: { id: 'errorSection.title' },
    subtitle: { id: 'errorSection.subtitle' },
    goToPaymentPlans: { id: 'errorSection.goToPaymentPlans' }
  });

  return (
    <ContentContainer>
      <Flex
        direction={{ base: 'column', xl: 'row' }}
        h="full"
        justifyContent="center"
        alignItems="center"
      >
        <Stack m="2rem" textAlign="center" order={{ base: 1, xl: 0 }}>
          <Text fontSize="5rem" fontWeight={700} color="alpha.300">
            {intl.formatMessage(messages.title)}
          </Text>
          <Text fontSize="2rem" fontWeight={700} color="black">
            {intl.formatMessage(messages.subtitle)}
          </Text>
          <Text>{description}</Text>
          <Flex py="2rem" justifyContent="center">
            <Button onClick={ctaCallback} variant="successBase">
              {intl.formatMessage(messages.goToPaymentPlans)}
            </Button>
          </Flex>
        </Stack>
        <Icon m="2rem" w={{ base: '20rem', xl: '25rem' }} h="auto" as={illustration1} />
      </Flex>
    </ContentContainer>
  );
};

export default ErrorSection;
