import React from 'react';
import { Helmet } from 'react-helmet';

const VersionPage = () => (
  <>
    <Helmet>
      <meta name="robots" content="noindex" />
    </Helmet>
    <section className="container text-center">
      <h2 className="mt-2">App Version:</h2>
      <h2>@VERSION@</h2>
    </section>
  </>
);

export default VersionPage;
