import React, { useEffect, useState, useRef } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { Flex, Button, Text } from '@chakra-ui/react';
import routes from 'constants/routesPaths';
import PaymentToggle from 'components/settlementOffer/PaymentToggle';
import OfferHeader from 'components/settlementOffer/OfferHeader';
import PayInFullPicker from 'components/settlementOffer/PayInFullPicker';
import {
  IFRAME_LOADER_FORMAT,
  IFRAME_LOADER_SIZE,
  PAYMENT_STATUS,
  PAYMENT_METHOD_OPTIONS,
  REPAYMENT_OPTIONS,
  SETTLEMENT_OPTIONS
} from 'constants/constants';
import { PENDING, REJECTED, RESET } from 'constants/actionStatusConstants';
import PaymentOptionSection from 'components/settlementOffer/PaymentOptionSection';
import UnverifiedPhoneLogin from 'components/settlementOffer/UnverifiedPhoneLogin';
import Modal from 'components/common/Modal';
import Loader from 'react-loader-spinner';
import { MIXPANEL_EVENTS } from 'constants/mixpanelEvents';
import { CurrencyText, CurrencyInput } from 'components/common';
import dayjs from 'dayjs';
import {
  paymentForm,
  resetPaymentForm,
  savePaymentToken,
  savePaymentCardType,
  savePaymentSource,
  savePaymentLast4,
  savePaymentRoutingNumber,
  savePaymentNameOnAccount,
  savePaymentDetails
} from 'state/actions/paymentMethodActions';
import {
  settlementOffer,
  selectSettlementOption,
  verifyPhoneNumber,
  selectSettlementPaymentMethod,
  selectDate,
  selectSettlementPlan,
  hydrateFromSpecialPlan
} from 'state/actions/settlementOfferActions';
import {
  getSpecialPaymentPlan,
  saveSpecialPaymentPlanSchedule
} from 'state/actions/paymentPlanActions';
import {
  useDispatch,
  useAnalytics,
  useLiveChat,
  // useQuiqChat,
  useStatus,
  // useSelectHolderName,
  // useSelectAccountId,
  useSelectVerifiedDevice,
  useSelectSettlementReferenceNumber,
  useSelectPaymentForm,
  useSelectedSettlementPaymentMethod,
  useSelectSettlementDate,
  useSelectPaymentInfo
} from 'hooks';

// eslint-disable-next-line import/extensions
import 'swiper/swiper.scss'; // core Swiper
import { green } from 'styles/_variables.scss';

import { useIntl } from 'react-intl';
import useSelectSpecialPaymentPlan from 'hooks/useSelectSpecialPaymentPlan';
import DateDropdown from 'components/common/DateDropdown';
import { messages } from './translations';

const PaymentPlanLandingPage = () => {
  const history = useHistory();
  const intl = useIntl();
  const { trackEvent } = useAnalytics();

  const repayUrls = process.env.REPAY_URLS.split(',');

  // redux actions

  const getSpecialPaymentPlanRequest = useDispatch(getSpecialPaymentPlan);
  const verificationRequest = useDispatch(verifyPhoneNumber);
  const paymentFormRequest = useDispatch(paymentForm);
  const resetPaymentFormRequest = useDispatch(resetPaymentForm);
  const savePaymentTokenRequest = useDispatch(savePaymentToken);
  const savePaymentCardTypeRequest = useDispatch(savePaymentCardType);
  const savePaymentSourceRequest = useDispatch(savePaymentSource);
  const savePaymentLast4Request = useDispatch(savePaymentLast4);
  const savePaymentDetailsRequest = useDispatch(savePaymentDetails);
  const savePaymentRoutingNumberRequest = useDispatch(savePaymentRoutingNumber);
  const savePaymentNameOnAccountRequest = useDispatch(savePaymentNameOnAccount);
  const selectPaymentMethod = useDispatch(selectSettlementPaymentMethod);
  const setDate = useDispatch(selectDate);
  const setSpecialPlanPaymentSchedule = useDispatch(saveSpecialPaymentPlanSchedule);
  const selectOption = useDispatch(selectSettlementOption);
  const hydrateSettlementPlan = useDispatch(selectSettlementPlan);
  const hydrateOfferAmount = useDispatch(hydrateFromSpecialPlan);

  // payment plan stuff
  const [selectedPayment, setSelectedPayment] = useState(SETTLEMENT_OPTIONS.createPlan);
  const [proposedMonthlyAmount, setProposedMonthlyAmount] = useState(null);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [paymentNotApprovedModalVisible, setPaymentNotApprovedModalVisible] = useState(false);

  // If today day is > 28, then we need to set the selected day to the 1st of the following month
  // taking into consideration the special case of december where we have to add 1 year
  const isProhibitedDay = dayjs().date() > 28;
  const isDecember = dayjs().month() === 11;
  const [selectedDay, setSelectedDay] = useState(isProhibitedDay ? 1 : dayjs().date());
  const [selectedMonth, setSelectedMonth] = useState({
    month: isProhibitedDay ? (isDecember ? 0 : dayjs().month() + 1) : dayjs().month(),
    year: isProhibitedDay ? (isDecember ? dayjs().year() + 1 : dayjs().year()) : dayjs().year()
  });

  const { selectedDate } = useSelectSettlementDate();

  const expiryDate = dayjs()
    .set('date', selectedDay)
    .set('month', selectedMonth.month)
    .set('year', selectedMonth.year)
    .add(2, 'month')
    .format('YYYY-MM-DD');

  const selectedPaymentMethodRef = useRef();
  const paymentLoaderRef = useRef();
  const paymentMethodSectionRef = useRef();

  const [loaderVisible, setLoaderVisible] = useState(false);

  // redux select hooks
  const { status } = useStatus(settlementOffer);
  const { paymentToken } = useSelectPaymentInfo();
  const { verified } = useSelectVerifiedDevice();
  const { referenceNumber } = useSelectSettlementReferenceNumber();
  const {
    currentBalance,
    payments,
    proposedAmountApproved,
    standardMonthlyAmount,
    termsOffered
  } = useSelectSpecialPaymentPlan();

  const finalPaymentAmount = payments[payments.length - 1];
  const isFinalPaymentAmountDifferent = finalPaymentAmount !== payments[0];
  const numStandardPayments = isFinalPaymentAmountDifferent ? termsOffered - 1 : termsOffered;

  const { paymentFormUrl, hasPaymentForm } = useSelectPaymentForm();
  const { selectedSettlementPaymentMethod } = useSelectedSettlementPaymentMethod();

  const isCreatePlan = selectedPayment === SETTLEMENT_OPTIONS.createPlan;
  const isCard = PAYMENT_METHOD_OPTIONS.card === selectedSettlementPaymentMethod;
  const isBank = PAYMENT_METHOD_OPTIONS.bank === selectedSettlementPaymentMethod;

  //
  // useEffect hooks
  //

  useEffect(() => {
    trackEvent(MIXPANEL_EVENTS.viewPaymentPlanLandingPage, { referenceNumber });
  }, [referenceNumber]);

  useEffect(() => {
    const event = proposedAmountApproved
      ? MIXPANEL_EVENTS.proposedSpecialPaymentPlanApproved
      : MIXPANEL_EVENTS.proposedSpecialPaymentPlanRejected;

    const lastPaymentAmount =
      payments.length > 0 ? payments[payments.length - 1] : standardMonthlyAmount;

    trackEvent(event, {
      currentBalance,
      termsOffered,
      standardMonthlyAmount,
      lastPaymentAmount
    });
  }, [currentBalance, termsOffered, standardMonthlyAmount, payments]);

  useEffect(() => {
    if (referenceNumber) {
      getSpecialPaymentPlanRequest({ referenceNumber, proposedMonthlyAmount: null });
    }
  }, [referenceNumber, getSpecialPaymentPlanRequest]);

  useEffect(() => {
    const formattedDate = dayjs()
      .set('date', selectedDay)
      .set('month', selectedMonth.month)
      .set('year', selectedMonth.year);
    setDate(formattedDate.toDate());
  }, [selectedDay]);

  useEffect(() => {
    const option =
      selectedPayment === SETTLEMENT_OPTIONS.createPlan
        ? REPAYMENT_OPTIONS.createPlan
        : REPAYMENT_OPTIONS.payInFull;

    trackEvent(MIXPANEL_EVENTS.togglePaymentPlanLandingPage, { option });
    selectOption(option);
  }, [selectedPayment]);

  useEffect(() => {
    const planPayments = isCreatePlan ? payments : [currentBalance];
    const numberOfMonths = planPayments.length;
    hydrateSettlementPlan({ numberOfMonths, amount: planPayments });
    hydrateOfferAmount({ numberOfMonths, offerAmount: currentBalance });
  }, [payments, isCreatePlan]);

  // const { accountId } = useSelectAccountId();
  // const { firstName } = useSelectHolderName();
  // useQuiqChat({ verified, firstName, accountId });
  useLiveChat(verified);

  //
  // event handlers
  //

  const paymentHandler = e => {
    if (!repayUrls.includes(e.origin)) {
      return;
    }
    if (e.data === PAYMENT_STATUS.success) {
      setPaymentSuccess(true);
    }
    if (e.data?.paymentResponseData) {
      const {
        saved_payment_method: { token },
        payment_type_id: savedCardType,
        customer_id: customerId,
        account_type: accountType,
        pn_ref: pnRef,
        result_details: { card_info: { type = '' } = {} } = {},
        result,
        last4,
        date,
        transit_number: routingNumber,
        name_on_check: nameOnAccount,
        name_on_card: nameOnCard
      } = e.data.paymentResponseData;

      const isCard = PAYMENT_METHOD_OPTIONS.card === selectedPaymentMethodRef.current;
      const paymentSource = isCard ? savedCardType : accountType;
      const accountName = isCard ? nameOnCard : nameOnAccount;

      savePaymentCardTypeRequest(savedCardType);
      savePaymentSourceRequest(paymentSource);
      savePaymentLast4Request(last4);
      savePaymentTokenRequest(token);
      savePaymentRoutingNumberRequest(routingNumber);
      savePaymentNameOnAccountRequest(accountName);
      savePaymentDetailsRequest({
        date,
        customer_id: customerId,
        pn_ref: pnRef,
        card_type: type,
        result,
        payment_last4: last4,
        payment_source: paymentSource
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener('message', paymentHandler);
    resetPaymentFormRequest();
    setDate(new Date());
    return () => window.removeEventListener('message', paymentHandler);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onPaymentMethodSelect = paymentMethod => {
    trackEvent(MIXPANEL_EVENTS.selectPaymentMethod, { paymentMethod });
    setLoaderVisible(!loaderVisible);
    resetPaymentFormRequest();
    selectPaymentMethod(paymentMethod);
    selectedPaymentMethodRef.current = paymentMethod;
    paymentFormRequest({ accountId: referenceNumber, methodType: paymentMethod });
    return paymentLoaderRef.current?.scrollIntoView();
  };

  const onChangeProposedMonthlyAmount = ({ value }) => {
    setProposedMonthlyAmount(value);
  };

  const onProposedMonthlyAmountUpdate = async () => {
    trackEvent(MIXPANEL_EVENTS.proposeSpecialPaymentPlan, { proposedMonthlyAmount });
    const { payload } = await getSpecialPaymentPlanRequest({
      referenceNumber,
      proposedMonthlyAmount
    });
    const showModal = !payload?.proposedAmountApproved;
    setPaymentNotApprovedModalVisible(showModal);
    setSpecialPlanPaymentSchedule({
      firstPaymentMonth: selectedMonth,
      firstPaymentDay: selectedDay,
      payments
    });
  };

  const onSelectDate = dateString => {
    const date = dayjs(dateString);
    setSelectedMonth({ month: date.month(), year: date.year() });
    setSelectedDay(date.date());
    console.log('new month', { month: date.month(), year: date.year() });
    console.log('new day', date.date());
  };

  const onChangePaymentToggle = option => {
    setSelectedPayment(option);
  };

  const scrollToPaymentMethod = () => paymentMethodSectionRef.current.scrollIntoView();

  // payment confirmation/error redirects

  if (paymentSuccess && paymentToken) {
    return <Redirect push to={routes.settlementConfirm} />;
  }

  if (!referenceNumber) {
    return <Redirect push to={routes.settlementError} />;
  }

  //
  // render
  //

  if (status === REJECTED) {
    return <Redirect to={routes.login} />;
  }
  if (status === PENDING || status === RESET) {
    return (
      <div className="settlement-container">
        <Loader
          visible
          type={IFRAME_LOADER_FORMAT}
          color={green}
          height={IFRAME_LOADER_SIZE}
          width={IFRAME_LOADER_SIZE}
        />
      </div>
    );
  } // else status === FULFILLED

  return (
    <Flex
      backgroundColor="delta.50"
      direction="column"
      w="full"
      alignItems="center"
      h="full"
      overflow="auto"
      pt={10}
      className="settlement-payment-page-container"
    >
      {!verified && <UnverifiedPhoneLogin onSubmit={verificationRequest} />}
      {paymentNotApprovedModalVisible && (
        <Modal
          title={intl.formatMessage(messages.modalTitle)}
          buttonText={intl.formatMessage(messages.modalButtonText)}
          onClose={() => setPaymentNotApprovedModalVisible(false)}
        >
          <Text mt={1} fontSize="md" color="alpha.500">
            {proposedMonthlyAmount >= currentBalance
              ? intl.formatMessage(messages.modalBodyTextHigh)
              : intl.formatMessage(messages.modalBodyTextLow)}
          </Text>
        </Modal>
      )}
      <Text
        fontSize={{ base: 'xl', md: '3xl' }}
        color="alpha.500"
        fontWeight={700}
        textAlign="center"
        mx={4}
      >
        {intl.formatMessage(messages.title)}
      </Text>
      <PaymentToggle
        alignItems="center"
        justifyContent="center"
        paymentSelected={selectedPayment}
        setSelectedPayment={onChangePaymentToggle}
        leftLabel={intl.formatMessage(messages.payFullBalance)}
      />
      <Flex w={{ base: '95%', md: '65%' }} mx="auto" mt={5} direction="column" h="full">
        <OfferHeader referenceNumber={referenceNumber} currentBalance={currentBalance} />
        {isCreatePlan ? (
          <>
            <Flex
              alignItems="center"
              direction="column"
              backgroundColor="white"
              borderRadius="12px"
              borderTopRightRadius={0}
              borderTopLeftRadius={0}
              w="full"
              py={6}
              px={4}
            >
              <Text fontWeight={700} fontSize={{ base: 'lg', md: 'xl' }} color="gray.600" mb={0}>
                {intl.formatMessage(messages.selectYourPayments)}
              </Text>
            </Flex>
            <Flex
              alignItems="center"
              direction="column"
              backgroundColor="white"
              borderRadius="12px"
              borderTopRightRadius={0}
              borderTopLeftRadius={0}
              w="full"
              position="relative"
              /* ref={paymentLoaderRef} */
              scrollBehavior="smooth"
              py={6}
              px={{ base: 2, md: 4 }}
            >
              {numStandardPayments > 0 && (
                <>
                  <Text fontSize="md" color="alpha.500" mb={5}>
                    {`${intl.formatMessage(messages.standardPayments, { numStandardPayments })} `}
                    <strong>
                      <CurrencyText value={parseFloat(standardMonthlyAmount)} />
                    </strong>
                  </Text>
                  {isFinalPaymentAmountDifferent && (
                    <Text fontSize="md" color="alpha.500" mb={5}>
                      {`${intl.formatMessage(messages.finalPayment)} `}
                      <strong>
                        <CurrencyText value={parseFloat(finalPaymentAmount)} />
                      </strong>
                    </Text>
                  )}
                  <Text
                    fontWeight={700}
                    fontSize={{ base: 'lg', md: 'xl' }}
                    color="gray.600"
                    mb={4}
                  >
                    {intl.formatMessage(messages.or)}
                  </Text>
                </>
              )}
              <Text fontSize="md" color="alpha.500" mb={5}>
                {intl.formatMessage(messages.proposedAmountLabel)}
              </Text>
              <CurrencyInput
                name="proposedMonthlyAmount"
                placeholder="$0.00"
                value={proposedMonthlyAmount}
                onChange={onChangeProposedMonthlyAmount}
                error={
                  !proposedAmountApproved &&
                  (proposedMonthlyAmount >= currentBalance
                    ? intl.formatMessage(messages.proposedAmountTooHigh)
                    : intl.formatMessage(messages.proposedAmountTooLow))
                }
              />
              <Button ariaLabel="Go" size="lg" onClick={onProposedMonthlyAmountUpdate}>
                {intl.formatMessage(messages.recalculatePaymentsLabel)}
              </Button>
              <Flex
                alignItems="center"
                direction="column"
                backgroundColor="white"
                borderRadius="12px"
                w="full"
                py={6}
                px={4}
                mt={2}
              >
                <Text
                  textAlign="center"
                  fontWeight={700}
                  fontSize={{ base: 'lg', md: 'xl' }}
                  color="gray.600"
                >
                  {intl.formatMessage(messages.selectFirstPaymentDate)}
                </Text>
              </Flex>
              <DateDropdown onSelectDate={onSelectDate} />
              {proposedAmountApproved && (
                <Button ariaLabel="Go" size="lg" onClick={scrollToPaymentMethod}>
                  {intl.formatMessage(messages.selectPaymentMethodLabel)}
                </Button>
              )}
            </Flex>
          </>
        ) : (
          <>
            <Flex
              alignItems="center"
              direction="column"
              backgroundColor="white"
              borderRadius="12px"
              borderTopRightRadius={0}
              borderTopLeftRadius={0}
              w="full"
              position="relative"
              // ref={paymentLoaderRef}
              scrollBehavior="smooth"
              py={6}
              px={{ base: 2, md: 4 }}
            >
              <PayInFullPicker
                expiryDate={expiryDate}
                selectedDate={selectedDate}
                setDate={setDate}
              />
            </Flex>
          </>
        )}
        {(!isCreatePlan || proposedAmountApproved) && (
          <>
            <Flex
              ref={paymentMethodSectionRef}
              alignItems="center"
              justifyContent="center"
              direction="column"
              backgroundColor="white"
              borderRadius="12px"
              py={6}
              w="full"
              mt={2}
              h="100%"
            >
              <Text fontWeight={700} fontSize="md" color="gray.600" mt={0}>
                {intl.formatMessage(messages.selectYourPaymentMethod)}
              </Text>
              <PaymentOptionSection
                hasPaymentForm={hasPaymentForm}
                onPaymentMethodSelect={onPaymentMethodSelect}
                loaderVisible={loaderVisible}
                setLoaderVisible={setLoaderVisible}
                isCard={isCard}
                isBank={isBank}
                paymentFormUrl={paymentFormUrl}
              />
            </Flex>
            <Flex
              alignItems="start"
              justifyContent="center"
              direction="column"
              py={6}
              w="full"
              mt={2}
              h="100%"
              paddingTop={0}
            >
              <Flex direction="column" mt={4} w="100%">
                <Text mt={1} fontSize={{ base: 'sm', md: 'md' }} color="alpha.500">
                  {intl.formatMessage(messages.miniMiranda)}
                </Text>
                <Text
                  onClick={() => history.push(routes.disclosures)}
                  textDecoration="underline"
                  textAlign="center"
                  mt={4}
                  fontSize="md"
                  fontWeight={700}
                  color="beta.500"
                  cursor="pointer"
                  w="fit-content"
                >
                  {intl.formatMessage(messages.knowYourRights)}
                </Text>
              </Flex>
            </Flex>
          </>
        )}
      </Flex>
    </Flex>
  );
};

export default PaymentPlanLandingPage;
