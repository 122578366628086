import { useSelector, shallowEqual } from 'react-redux';

const useSelectPlanOptions = () =>
  useSelector(
    ({ paymentPlan: { accountPaymentPlan } }) => ({
      allowedPlanStartLatency: accountPaymentPlan?.allowedPlanStartLatency,
      weeklyOptions: accountPaymentPlan?.planOptions?.weekly,
      biweeklyOptions: accountPaymentPlan?.planOptions?.biweekly,
      monthlyOptions: accountPaymentPlan?.planOptions?.monthly
    }),
    shallowEqual
  );

export default useSelectPlanOptions;
