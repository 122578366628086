import { extendTheme } from '@chakra-ui/react';
import colors from './theme/colors';
import typography from './theme/typography';
import ButtonStyle from './theme/button';
import global from './theme/global';
import breakpoints from './theme/breakpoints';

export default extendTheme({
  styles: {
    global
  },
  breakpoints,
  colors,
  ...typography,
  components: {
    Button: ButtonStyle
  }
});
