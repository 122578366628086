// Add payment method to a promise

export const PREVIEW = 'preview';
export const ADD_PAYMENT_METHOD_TO_PROMISE = {
  PREVIEW: 'preview',
  ADD_PAYMENT_METHOD: 'add_payment_method',
  CONFIRM_PAYMENT_METHOD: 'confirm_payment_method',
  PAYMENT_METHOD_SUCCESS: 'payment_method_success',
  ADD_PAYMENT_METHOD_TO_PROMISE_ERROR: 'add_payment_method_to_promise_error_page'
};
