import { useSelector, shallowEqual } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

const useSelectPaymentForm = () =>
  useSelector(
    ({ paymentMethod: { paymentFormUrl } }) => ({
      paymentFormUrl,
      hasPaymentForm: !isEmpty(paymentFormUrl)
    }),
    shallowEqual
  );

export default useSelectPaymentForm;
