import { FULFILLED, PENDING } from 'constants/actionStatusConstants';
import { emitter } from '@marvelapp/react-ab-test';
import { activeExperiments } from 'constants/experiments';
import PaymentPlan from 'components/paymentPlans/PaymentPlan';
import EmptyPage from 'pages/EmptyPage';
import { ContentContainer, Loading } from 'components/common';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelectAccountDetails, useSelectPaymentPlans, useStatus } from 'hooks';
import { getPaymentPlans } from 'state/actions/paymentPlanActions';
import useSectionRouter from 'hooks/sectionRouter';
import { ADD_PAYMENT_METHOD_TO_PROMISE } from 'constants/sections';
import { Flex, Text } from '@chakra-ui/react';

const { ADD_PAYMENT_METHOD } = ADD_PAYMENT_METHOD_TO_PROMISE;

const PaymentPlanPreview = () => {
  const intl = useIntl();
  const { paymentPlans, hasPaymentPlans } = useSelectPaymentPlans();
  const { remainingBalance } = useSelectAccountDetails();
  const { status } = useStatus(getPaymentPlans);
  const { push } = useSectionRouter();

  return (
    <ContentContainer>
      <div className="homepage-container">
        {status === FULFILLED &&
          (hasPaymentPlans ? (
            <>
              <Flex
                justifyContent={{ base: 'center', lg: 'left' }}
                mb={{ lg: '2rem', base: '1rem' }}
              >
                <Text fontSize="2rem" fontWeight="700">
                  {intl.formatMessage({ id: 'paymentPlans.title' })}
                </Text>
              </Flex>
              {paymentPlans.map(paymentPlan => (
                <PaymentPlan
                  key={paymentPlan.planId}
                  paymentPlan={paymentPlan}
                  addPaymentMethodOnClick={() => {
                    push(ADD_PAYMENT_METHOD);
                    emitter.emitWin(activeExperiments?.paymentMethodDisclosures?.name);
                  }}
                />
              ))}
            </>
          ) : (
            <EmptyPage
              title={intl.formatMessage({ id: 'paymentPlans.empty' })}
              buttonTitle={intl.formatMessage({ id: 'paymentOptionsBlock.createPlan' })}
              showPaymentCTA={remainingBalance >= 1}
            />
          ))}
        {status === PENDING && <Loading />}
      </div>
    </ContentContainer>
  );
};

export default PaymentPlanPreview;
