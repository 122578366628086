import { useSelector, shallowEqual } from 'react-redux';

const useSelectVerifiedDevice = () =>
  useSelector(
    ({ settlementOffer: { verified } }) => ({
      verified
    }),
    shallowEqual
  );

export default useSelectVerifiedDevice;
