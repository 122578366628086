import { useSelector, shallowEqual } from 'react-redux';

const useSelectSettlementReferenceNumber = () =>
  useSelector(
    ({ settlementOffer: { referenceNumber } }) => ({
      referenceNumber
    }),
    shallowEqual
  );

export default useSelectSettlementReferenceNumber;
