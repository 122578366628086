export const buildPayload = ({ accountId, selectedPlan, paymentMethod }) => {
  const paymentProcessorReturn = {
    ...paymentMethod.paymentDetails,
    methodType: paymentMethod.selectedPaymentMethod,
    token: paymentMethod.paymentToken
  };

  const paymentSchedule = selectedPlan.paymentSchedule.map(schedule => ({
    date: schedule.paymentDate,
    amount: schedule.paymentAmount
  }));

  const planDetails = {
    paymentSchedule,
    paymentProcessorReturn
  };

  return { accountId, planDetails };
};
