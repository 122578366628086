import React from 'react';
import { Flex, Text, useBreakpointValue, Button } from '@chakra-ui/react';
import { useIntl, defineMessages } from 'react-intl';
import SettlementErrorIcon from 'assets/icons/settlementError.svg';
import SettlementErrorIconMobile from 'assets/icons/settlementErrorMobile.svg';
import routes from 'constants/routesPaths';
import { useHistory } from 'react-router-dom';

const SettlementErrorPage = () => {
  const intl = useIntl();
  const history = useHistory();
  const showErrorImageDesktop = useBreakpointValue({ base: false, md: true });
  const showErrorImageMobile = useBreakpointValue({ base: true, md: false });

  const messages = defineMessages({
    ohNo: { id: 'settlementError.ohNo' },
    wentWrong: { id: 'settlementError.wentWrong' },
    action: { id: 'settlementError.action' },
    cta: { id: 'settlementError.cta' }
  });

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      w="full"
      h="calc(100vh - 60px)"
      backgroundColor="delta.50"
      flex="1"
      direction={{ base: 'column', md: 'row' }}
      padding={{ base: 5, md: 10 }}
      pb={10}
    >
      {showErrorImageMobile && <SettlementErrorIconMobile />}
      <Flex
        maxW={{ base: '90%', md: '420px' }}
        direction="column"
        mr={{ base: 0, md: 5 }}
        gridGap={2}
      >
        <Text
          textAlign={{ base: 'center', md: 'left' }}
          fontSize={{ base: '4xl', md: '6xl' }}
          fontWeight={700}
          color="alpha.300"
        >
          {intl.formatMessage(messages.ohNo)}
        </Text>
        <Text
          textAlign={{ base: 'center', md: 'left' }}
          fontSize={{ base: '2xl', md: '4xl' }}
          fontWeight={700}
          color="black"
        >
          {intl.formatMessage(messages.wentWrong)}
        </Text>
        <Text
          textAlign={{ base: 'center', md: 'left' }}
          fontSize={{ base: 'lg', md: 'xl' }}
          color="black"
        >
          {intl.formatMessage(messages.action)}
        </Text>
        <Button
          _hover={{ backgroundColor: 'transparent' }}
          _active={{ backgroundColor: 'transparent' }}
          paddingX={20}
          paddingY={2.5}
          mt={4}
          height="auto"
          alignSelf="center"
          maxW="216px"
          colorScheme="alpha"
          variant="outline"
          onClick={() => history.push(routes.index)}
        >
          {intl.formatMessage(messages.cta)}
        </Button>
      </Flex>
      {showErrorImageDesktop && <SettlementErrorIcon />}
    </Flex>
  );
};

export default SettlementErrorPage;
