import { createSlice } from '@reduxjs/toolkit';
import {
  logout,
  accountsFulfilled,
  accountFulfilled,
  setAccountSelectedId,
  toggleAccounts,
  disclosuresFulfilled
} from 'state/actions/userActions';

const initialState = {
  accountOptions: undefined,
  accountSelected: {},
  solDisclosureID: null,
  currentCreditorName: null,
  isSelectAccountOpened: false
};

const accountsSlice = createSlice({
  name: 'accounts',
  initialState,
  extraReducers: {
    [accountsFulfilled]: (state, { payload }) => {
      state.accountOptions = payload.map(account => {
        return { accountId: account.accountId, accountName: account.clientName };
      });
      state.accountSelected = payload.length === 1 ? payload[0] : {};
    },
    [accountFulfilled]: (state, { payload }) => {
      state.accountSelected = payload;
    },
    [disclosuresFulfilled]: (state, { payload }) => {
      state.solDisclosureID = payload.solDisclosureID;
      state.currentCreditorName = payload.currentCreditorName;
    },
    [setAccountSelectedId]: (state, { payload }) => {
      state.accountSelected.accountId = payload;
    },
    [toggleAccounts]: (state, { payload }) => {
      state.isSelectAccountOpened = payload;
    },
    [logout]: () => initialState
  }
});

export default accountsSlice.reducer;
