export const MIXPANEL_EVENTS = {
  acceptDisclosures: 'Acknowledge Disclosures',
  clickBackToWebsite: 'Click Back to website',
  clickHeaderLogo: 'Click Header Logo',
  clickKnowYourRights: 'Click Know Your Rights',
  clickMorePaymentOptions: 'Click More Payment Options',
  clickPay: 'Click Pay',
  clickPayInFull: 'Click Pay in Full',
  clickPaymentPlan: 'Click Create Plan',
  clickPrintReceipt: 'Click Print Receipt',
  closeSideMenu: 'Close Menu',
  collapseAccountDetails: 'Collapse Account Details Page',
  downloadDocument: 'Download Document',
  expandAccountDetails: 'Expand Account Details',
  fillDOB: 'Fill DOB field',
  fillLastName: 'Fill Last Name',
  fillReference: 'Fill Reference number field',
  fillSSN: 'Fill Last4SSN field',
  frequencySelected: 'Select Schedule Frequency',
  loginAttempt: 'Login Attempt',
  loginFail: 'Login Failure',
  loginSuccess: 'Login Success',
  logOut: 'Log Out',
  openSideMenu: 'Open Menu',
  refNumberTooltip: 'Click Reference number tooltip',
  selectPaymentFrequency: 'Select Payment Frequency',
  selectPaymentMethod: 'Select Payment Method',
  selectRepaymentOption: 'Select Repayment Option',
  startDateSelected: 'Select Start Date',
  termsSelected: 'Select Schedule Terms',
  viewAccountDetailsPage: 'View Account Details Page',
  viewAccountSuppressedPage: 'View Account Suppressed Page',
  viewAuthorizationPaymentPage: 'View Payment Authorization Page',
  viewCreatePlanPage: 'View Choose your Payment Frequency Page',
  viewDisclosuresPage: 'View Disclosures Page',
  viewDocumentsPage: 'View Documents Page',
  viewPaymentConfirmationPage: 'View Payment Confirmation Page',
  viewPaymentErrorPage: 'View Payment Error Page',
  viewPaymentHistoryPage: 'View Payment History Page',
  viewPaymentPlansPage: 'View Payment Plans Page',
  viewRepaymentOptions: 'View Choose your Repayment Option Page',
  viewSetupPaymentPage: 'View Set up your Payment Page',
  viewSettlementOfferPage: 'View Settlement Splash Page',
  viewSettlementOfferSuccessPagePIF: 'Settle In Full Conversion',
  viewSettlementOfferSuccessPagePlan: 'Settlement Plan Conversion',
  viewAddPaymentMethodPage: 'View Add Payment Method Page',
  viewPaymentPlanLandingPage: 'View Payment Plan Landing Page',
  initiateAddDetailsToPromise: 'Initiate Add Details To Promise',
  authorizeAddDetailsToPromise: 'Authorize Add Details To Promise',
  conversionAddDetailsToPromise: 'Conversion Add Details To Promise',
  viewConfirmAddPaymentMethodPage: 'View Add Payment Method Confirmation Page',
  togglePaymentPlanLandingPage: 'Toggle payment option',
  proposeSpecialPaymentPlan: 'Propose special payment plan',
  proposedSpecialPaymentPlanApproved: 'Proposed special payment plan approved',
  proposedSpecialPaymentPlanRejected: 'Proposed special payment plan rejected',
  openChatWidget: 'User opened chat widget'
};

export const MIXPANEL_LOCATIONS = {
  accountDetails: 'Account Details Page',
  accountSuppressed: 'Account Suppressed page',
  disclosures: 'Disclosures page',
  home: 'Home page',
  login: 'Login page',
  paymentPlanLandingPage: 'Payment Plan Landing Page'
};

export const MIXPANEL_AB_TESTING = {
  startExperiment: '$experiment_started',
  emitWin: 'Experiments Win'
};
