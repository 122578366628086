import { createAction } from '@reduxjs/toolkit';

import paymentPlanService from 'services/paymentPlanService';
import parseError from 'utils/parseError';
import createAsyncThunk from 'utils/createAsyncThunk';

import {
  getPlanRequestBody,
  getPaymentDatesRequestBody,
  comparePaymentDates,
  formatDate
} from 'utils/helpers';
import { REPAYMENT_OPTIONS, PAYMENT_METHOD_OPTIONS } from 'constants/constants';
import { buildPayload } from 'utils/builders/addPaymentMethodToPromise';

export const getSpecialPaymentPlan = createAsyncThunk(
  'paymentPlan/special',
  async ({ referenceNumber, proposedMonthlyAmount }) => {
    try {
      const data = await paymentPlanService.getSpecialPaymentPlan({
        referenceNumber,
        proposedMonthlyAmount
      });
      const {
        data: { data: specialPlan }
      } = data;

      return specialPlan;
    } catch ({ response }) {
      throw parseError(response);
    }
  }
);

export const paymentPlanOptions = createAsyncThunk('paymentPlan/options', async accountId => {
  try {
    const data = await paymentPlanService.repaymentOptions(accountId);
    const {
      data: { data: paymentPlanInfo }
    } = data;

    return paymentPlanInfo;
  } catch ({ response }) {
    throw parseError(response);
  }
});

export const getPaymentPlans = createAsyncThunk('paymentPlan/getPlans', async accountId => {
  try {
    const data = await paymentPlanService.getPaymentPlans(accountId);
    const {
      data: { data: paymentPlansInfo }
    } = data;

    return paymentPlansInfo.paymentPlans ?? [];
  } catch ({ response }) {
    throw parseError(response);
  }
});

export const addPaymentMethodToPromise = createAsyncThunk(
  'paymentPlan/addPaymentMethodToPromise',
  async ({ accountId, selectedPlan, paymentMethod }, thunkAPI) => {
    try {
      const requestPayload = buildPayload({ accountId, selectedPlan, paymentMethod });
      const {
        data: { data: payload }
      } = await paymentPlanService.addPaymentMethodToPromise(requestPayload);
      await thunkAPI.dispatch(getPaymentPlans(accountId));
      return payload;
    } catch ({ response }) {
      throw parseError(response);
    }
  }
);

export const createPaymentPlan = createAsyncThunk(
  'paymentPlan/createPlan',
  async ({
    accountId,
    paymentToken,
    paymentDetails,
    selectedPaymentMethod,
    selectedRepaymentOption,
    selectedDate,
    selectedPlan,
    selectedFrequency,
    paymentAmount,
    last4,
    routingNumber,
    source,
    nameOnAccount,
    paymentSchedule,
    remainingBalance
  }) => {
    try {
      // send proof of authorization in case the payment method is ACH
      if (selectedPaymentMethod === PAYMENT_METHOD_OPTIONS.bank) {
        const {
          data: { IPv4 }
        } = await paymentPlanService.getIPv4();

        let paymentSchedules = [];
        paymentSchedules =
          selectedRepaymentOption === REPAYMENT_OPTIONS.createPlan
            ? paymentSchedule.map(p => {
                return {
                  paymentDate: p.date,
                  currencyValue: p.amount
                };
              })
            : [
                {
                  paymentDate: formatDate(selectedDate),
                  currencyValue:
                    selectedRepaymentOption === REPAYMENT_OPTIONS.payInFull
                      ? remainingBalance
                      : Number(paymentAmount)
                }
              ];

        const data = {
          accountId,
          paymentSchedules,
          routeNumber: routingNumber || '',
          bankAcctLast4: last4 || '',
          accountType: source || '',
          consumerIpAddress: IPv4 || '',
          consumerName: nameOnAccount || ''
        };

        await paymentPlanService.sendProofOfAuth(data);
      }

      const body = {
        accountId,
        planDetails: getPlanRequestBody(
          paymentToken,
          paymentDetails,
          selectedPaymentMethod,
          selectedRepaymentOption,
          selectedDate,
          selectedPlan,
          selectedFrequency,
          paymentAmount
        )
      };

      const {
        data: {
          data: { planDetails }
        }
      } = await paymentPlanService.createPlan(body);

      return planDetails;
    } catch ({ response }) {
      throw parseError(response);
    }
  }
);

export const getPlanPaymentDates = createAsyncThunk(
  'paymentPlan/getPlanPaymentDates',
  async ({
    accountId,
    paymentToken,
    selectedPaymentMethod,
    selectedRepaymentOption,
    selectedDate,
    selectedPlan,
    selectedFrequency,
    paymentAmount
  }) => {
    try {
      const body = {
        accountId,
        planScheduleDetails: getPaymentDatesRequestBody(
          paymentToken,
          selectedPaymentMethod,
          selectedRepaymentOption,
          selectedDate,
          selectedPlan,
          selectedFrequency,
          paymentAmount
        )
      };

      const {
        data: {
          data: {
            scheduleDetails: { paymentSchedule }
          }
        }
      } = await paymentPlanService.getPlanPaymentDates(body);

      return paymentSchedule;
    } catch ({ response }) {
      throw parseError(response);
    }
  }
);

export const getPaymentHistory = createAsyncThunk(
  'paymentPlan/getPaymentHistory',
  async accountId => {
    try {
      const data = await paymentPlanService.getPaymentHistory(accountId);
      const {
        data: {
          data: { payments }
        }
      } = data;

      return payments.sort(comparePaymentDates);
    } catch ({ response }) {
      throw parseError(response);
    }
  }
);

export const selectRepaymentOption = createAction('paymentPlan/selectRepaymentOption');
export const selectPlan = createAction('paymentPlan/selectPlan');
export const cleanPlan = createAction('paymentPlan/cleanPlan');
export const cleanNewPlan = createAction('paymentPlan/cleanNewPlan');
export const selectDate = createAction('paymentPlan/selectDate');
export const selectFrequency = createAction('paymentPlan/selectFrequency');
export const selectAmount = createAction('paymentPlan/selectAmount');
export const savePlanSummary = createAction('paymentPlan/planSummary');
export const resetRepaymentOption = createAction('paymentPlan/resetRepaymentOption');
export const saveSpecialPaymentPlanSchedule = createAction(
  'paymentPlan/saveSpecialPaymentPlanSchedule'
);

export const { fulfilled: paymentOptionsFulfilled } = paymentPlanOptions;
export const { fulfilled: getPaymentPlansFulfilled } = getPaymentPlans;
export const { fulfilled: createPaymentPlanFulfilled } = createPaymentPlan;
export const { fulfilled: addPaymentMethodToPromiseFulfilled } = addPaymentMethodToPromise;
export const { fulfilled: getPlanPaymentDatesFulfilled } = getPlanPaymentDates;
export const { fulfilled: getPaymentHistoryFulfilled } = getPaymentHistory;
export const { fulfilled: getSpecialPaymentPlanFulfilled } = getSpecialPaymentPlan;
