import React, { useEffect, memo } from 'react';
import isUndefined from 'lodash/isUndefined';
import isEmpty from 'lodash/isEmpty';
import { Redirect } from 'react-router-dom';
import { useIntl } from 'react-intl';

import {
  useSession,
  useDispatch,
  useSelectAccountIds,
  useSelectAccountSuppressed,
  useSelectAccountSelected,
  useSelectAccountOptions,
  useAnalytics
} from 'hooks';
import { login, accounts } from 'state/actions/userActions';
import LoginForm from 'components/user/LoginForm';
import routes from 'constants/routesPaths';
import { Arrow } from 'components/common';
import LegalInformation from 'components/legal/LegalInformation';
import humanStanding from 'assets/images/humanStanding.png';
import { MIXPANEL_EVENTS } from 'constants/mixpanelEvents';

const { SOC_WEB_URL } = process.env;

const LoginPage = () => {
  const { authenticated } = useSession();
  const { accountIds } = useSelectAccountIds();
  const { suppressed } = useSelectAccountSuppressed();
  const { accountSelected } = useSelectAccountSelected();
  const { accountOptions } = useSelectAccountOptions();
  const { trackEvent } = useAnalytics();
  const intl = useIntl();
  const humanStandingAlt = intl.formatMessage({ id: 'img.humanStanding.alt' });
  const goBackHome = intl.formatMessage({ id: 'login.backWebsite' });
  const loginRequest = useDispatch(login);
  const accountsRequest = useDispatch(accounts);
  const isFirstTimeUser = authenticated && accountIds && isUndefined(suppressed);

  useEffect(() => {
    if (isFirstTimeUser) {
      accountsRequest(accountIds);
    }
  }, [isFirstTimeUser]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!isUndefined(suppressed)) {
    if (suppressed) {
      return <Redirect to={{ pathname: routes.accountError, state: { hideMenu: true } }} />;
    }
    return <Redirect push to={{ pathname: routes.disclosures, state: { hideMenu: true } }} />;
  }

  if (authenticated && isEmpty(accountSelected) && accountOptions?.length > 1) {
    return <Redirect push to={{ pathname: routes.selectAccount, state: { hideMenu: true } }} />;
  }

  return (
    <section className="d-flex flex-column w-100 mt-4">
      <div className="login-back-home">
        <a
          className="back-home-link"
          href={SOC_WEB_URL}
          onClick={() => trackEvent(MIXPANEL_EVENTS.clickBackToWebsite)}
        >
          <Arrow type="left" className="mr-2" />
          {goBackHome}
        </a>
      </div>
      <div className="d-flex">
        <div className="login-col">
          <h1 className="login-welcome">{intl.formatMessage({ id: 'login.welcome' })}</h1>
          <p className="login-subtitle">{intl.formatMessage({ id: 'login.validate' })}</p>
          <LoginForm onSubmit={loginRequest} />
          <LegalInformation />
        </div>
        <div className="login-illustration-container">
          <img src={humanStanding} className="mw-100" alt={humanStandingAlt} />
        </div>
      </div>
    </section>
  );
};

export default memo(LoginPage);
