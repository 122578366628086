import React, { useRef } from 'react';
import ReactTooltip from 'react-tooltip';
import ReactModal from 'react-modal';
import { string, func } from 'prop-types';

import CloseIcon from 'assets/icons/closeIcon.svg';
import QuestionTooltipIcon from 'assets/icons/questionTooltipIcon.svg';
import QuestionModalIcon from 'assets/icons/questionModalIcon.svg';
import { Button } from 'components/common';
import TooltipInfo from 'components/common/Tooltip/TooltipInfo';
import { useBreakpoint, useToggle } from 'hooks';
import { md } from 'styles/_variables.scss';

const Tooltip = ({ id, ariaLabel, title, description, afterShow = () => {} }) => {
  const isDesktop = useBreakpoint(md);
  const tooltipRef = useRef();
  const [isTooltipOpen, toggleTooltipOpen] = useToggle(false);

  return (
    <>
      <button
        data-tip
        data-for={id}
        data-event="click focus"
        data-event-off="blur"
        type="button"
        ref={tooltipRef}
        className="button button--icon reference-number-question"
        aria-label={ariaLabel}
        {...(!isDesktop && { onClick: toggleTooltipOpen })}
      >
        <QuestionTooltipIcon />
      </button>
      {isDesktop ? (
        <ReactTooltip
          afterShow={afterShow}
          isCapture
          place="right"
          id={id}
          type="light"
          effect="solid"
          className="tooltip"
          globalEventOff="click"
        >
          <Button className="button--icon tooltip-close-icon" ariaLabel="Close Tooltip">
            <CloseIcon />
          </Button>
          <TooltipInfo title={title} description={description} isDesktop />
        </ReactTooltip>
      ) : (
        <ReactModal
          isOpen={isTooltipOpen}
          onRequestClose={toggleTooltipOpen}
          overlayClassName="tooltip-overlay"
          className="tooltip-modal"
        >
          <Button
            ariaLabel="Close Tooltip"
            className="button--icon tooltip-close-icon"
            onClick={toggleTooltipOpen}
          >
            <CloseIcon />
          </Button>
          <QuestionModalIcon />
          <TooltipInfo title={title} description={description} />
        </ReactModal>
      )}
    </>
  );
};

Tooltip.propTypes = {
  id: string.isRequired,
  title: string.isRequired,
  description: string.isRequired,
  ariaLabel: string.isRequired,
  afterShow: func
};

export default Tooltip;
