import { string } from 'prop-types';
import React from 'react';
import cn from 'classnames';

const Label = ({ label, color }) => (
  <span className={cn('label', color ? `label--${color}` : '')}>{label}</span>
);

Label.propTypes = {
  label: string.isRequired,
  color: string
};

export default Label;
