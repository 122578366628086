import React, { useState, useEffect } from 'react';
import { string, func } from 'prop-types';
import dayjs from 'dayjs';
import { Select, Flex } from '@chakra-ui/react';

const DateDropdown = ({ onSelectDate, displayFormat = 'MMMM D', outputFormat = 'YYYY-MM-DD' }) => {
  const today = dayjs();
  const currentMonth = today.month();
  const currentDay = today.date();

  const [options, setOptions] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [_selectedOption, setSelectedOption] = useState();

  const setOption = (outputValue, displayValue) => {
    setSelectedOption(displayValue);
    if (onSelectDate instanceof Function) onSelectDate(outputValue);
  };

  const onChange = e => {
    const outputValue = e.target.value;
    const displayValue = e.target.querySelector(':checked').innerHTML;
    setOption(outputValue, displayValue);
  };

  useEffect(() => {
    const currentMonthYear = today.year();
    const oneMonthLater = today.add(1, 'month');
    const nextMonthYear = oneMonthLater.year();
    const nextMonth = oneMonthLater.month();

    const newOptions = [];

    for (let i = 0; i < 28; i += 1) {
      const day = (i + currentDay) % 28 || 28;
      let month;
      let year;

      // days 0-currentDay have already elapsed, so they're available for next month only
      if (day < currentDay) {
        month = nextMonth;
        year = nextMonthYear;
      } else {
        month = currentMonth;
        year = currentMonthYear;
      }

      const optionDate = dayjs()
        .set('date', day)
        .set('month', month)
        .set('year', year);

      const value = optionDate.format(outputFormat);
      const label = optionDate.format(displayFormat);

      newOptions.push({ value, label });
    }

    const { value, label } = newOptions[0];
    setOptions(newOptions);
    setOption(value, label);
  }, [currentMonth, currentDay]);

  return (
    <>
      <Flex alignItems="center" direction="column" w="auto" mt={2} mb={6}>
        <Select onChange={onChange} size="md">
          {options.map(({ value, label }) => (
            <option value={value}>{label}</option>
          ))}
        </Select>
      </Flex>
    </>
  );
};

DateDropdown.propTypes = {
  onSelectDate: func,
  displayFormat: string,
  outputFormat: string
};

export default DateDropdown;
