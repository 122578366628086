import httpClient from 'httpClient';

class SettlementOfferService {
  static getSettlementUrl(url) {
    return httpClient.post('/url', url);
  }

  static getSettlementOffer(referenceNumber) {
    return httpClient.post('/special-offer', referenceNumber);
  }

  static verifyPhoneNumber(credentials) {
    return httpClient.post('/verify-identity', credentials);
  }
}

export default SettlementOfferService;
