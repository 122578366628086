import React from 'react';
import { string, func, bool, node } from 'prop-types';
import cn from 'classnames';

import { Pane } from 'components/common';

const TilePaymentMethod = ({
  label,
  ariaLabel,
  onClick,
  active = false,
  small = false,
  icon,
  ...props
}) => (
  <button
    onClick={onClick}
    type="button"
    className={cn({ small })}
    aria-label={ariaLabel}
    {...props}
  >
    <Pane className={cn('tile-button', { active, small })}>
      {icon && icon}
      <span className="tile-button-label">{label}</span>
    </Pane>
  </button>
);

TilePaymentMethod.propTypes = {
  onClick: func.isRequired,
  ariaLabel: string.isRequired,
  label: string.isRequired,
  active: bool,
  small: bool,
  icon: node
};

export default TilePaymentMethod;
