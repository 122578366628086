import React from 'react';
import { Box, Text, Divider } from '@chakra-ui/react';
import { useIntl } from 'react-intl';

import { useSelectAccountDetails, useSelectDisclosures } from 'hooks';
import { DISCLOSURES } from 'constants/disclosures';
import { NULL_CREDITOR } from 'constants/constants';
import useSelectSettlementSolDisclosureID from 'hooks/useSelectSettlementSolDisclosureID';

const Disclosures = () => {
  const intl = useIntl();
  let { currentCreditorName, solDisclosureId } = useSelectAccountDetails();
  const {
    solDisclosureID,
    currentCreditorName: currentSettlementCreditorName
  } = useSelectSettlementSolDisclosureID();
  const {
    solDisclosureID: uniqueDisclosureID,
    currentCreditorName: uniqueCurrentCreditor
  } = useSelectDisclosures();
  // solDisclosures from normal login flow, settlement splash page, and unique disclosures link
  solDisclosureId = solDisclosureId || solDisclosureID || uniqueDisclosureID;
  currentCreditorName =
    currentCreditorName || currentSettlementCreditorName || uniqueCurrentCreditor;

  const disclosure = intl.formatMessage(
    { id: `solDisclosure${solDisclosureId}` },
    {
      currentCreditorName,
      currentCreditorAmount: +(currentCreditorName?.length && currentCreditorName !== NULL_CREDITOR)
    }
  );

  return (
    <Box pb={32} pt={6}>
      <Text as="h1" my={{ base: 5, lg: 8 }} fontSize={{ base: '32px', lg: '56px' }}>
        {intl.formatMessage({ id: 'disclosures.title' })}
      </Text>
      <Divider mb={6} />
      {solDisclosureId && (
        <Box mb={{ base: 2, lg: 6 }}>
          <p>{disclosure}</p>
        </Box>
      )}
      {DISCLOSURES.map(({ title, subtitle, paragraph }, index) => (
        <Box mb={{ base: 0, lg: 8 }} key={`disclosure-${index}`}>
          {title && (
            <Text fontSize={{ base: '24px', lg: '28px' }} mb={6} as="h2">
              {title}
            </Text>
          )}
          {subtitle && <p>{subtitle}</p>}
          <p>{paragraph}</p>
        </Box>
      ))}
    </Box>
  );
};

export default Disclosures;
