export default {
  // Images alternate text
  'img.humanStanding.alt': 'Human standing',
  'img.logo.alt': 'Spring Oaks Capital Logo',

  // Login
  'login.backWebsite': 'Back to website',
  'login.welcome': 'Welcome!',
  'login.validate': 'Please validate your identity to enter the Portal.',
  'login.form.lastName': 'Last Name',
  'login.form.lastNamePlaceholder': 'Enter your last name',
  'login.form.referenceNumber': 'Reference Number',
  'login.form.referenceNumberPlaceholder': 'Enter your reference number',
  'login.form.birthDate': 'Date of Birth',
  'login.form.birthDatePlaceholder': 'MM/DD/YYYY',
  'login.form.ssn': 'Last Four of SSN',
  'login.form.ssnPlaceholder': 'Enter the last four numbers of SSN',
  'login.form.submitFields': 'You must submit at least 3 fields to access.',
  'login.form.submit': 'Login',
  'login.form.error': 'Please check your data and try again',

  // Tooltip
  'originalBalance.tooltip.title': 'What is your Original Balance?',
  'originalBalance.tooltip.description':
    'Your Original Balance was the balance at the time of placement with Spring Oaks Capital.',
  'originalBalance.tooltip.aria': 'Information about the original balance',
  'login.tooltip.title': 'What is the Reference Number?',
  'login.tooltip.description':
    'The Reference Number is the unique number we use to identify your account. You can find your reference number on the letter we mailed you and on each email we send you.',
  'login.tooltip.aria': 'Information about the reference number',

  // Disclosures
  'disclosures.title': 'Disclosures',
  'footer.continue': 'Continue',
  'footer.continueAria': 'Continue to Portal',
  'footer.login': 'Login',
  'footer.loginAria': 'Back to Login',

  // Disclosures messages

  solDisclosure1: `The law limits how long you can be sued on a debt. Because of the age of your debt, you cannot be sued for it.
    If you do not pay the debt, we may report the debt to any credit reporting agency for as long as the law permits this reporting.
    In many circumstances, you can renew the debt and start the time period for the filing of a lawsuit against you if you take specific actions
     such as making certain payment on the debt or making a written promise to pay. You should determine the effect of any actions you take with
      respect to this debt.`,
  solDisclosure2: `The law limits how long you can be sued on a debt. Because of the age of your debt, you cannot be sued for it. If you do not pay the debt, we
   may continue to report the debt to any credit reporting agency for as long as the law permits this reporting.   In many circumstances, you can
    renew the debt and start the time period for the filing of a lawsuit against you if you take specific actions such as making certain payment
     on the debt or making a written promise to pay. You should determine the effect of any actions you take with respect to this debt.`,
  solDisclosure3: `The law limits how long you can be sued on a debt. Because of the age of your debt, you cannot be sued for it and this debt cannot be reported
   to any credit reporting agency. In many circumstances, you can renew the debt and start the time period for the filing of a lawsuit against you
    if you take specific actions such as making certain payment on the debt or making a written promise to pay. You should determine the effect
     of any actions you take with respect to this debt.`,
  solDisclosure4: `The law limits how long you can be sued on a debt. Because of the age of your debt, you cannot be sued for it. If you do not pay the debt,
   we may report the debt to any credit reporting agency for as long as the law permits this reporting.   In many circumstances, you can renew
    the debt and start the time period for the filing of a lawsuit against you if you take specific action such as making a written promise to pay.
     You should determine the effect of any actions you take with respect to this debt.`,
  solDisclosure5: `The law limits how long you can be sued on a debt. Because of the age of your debt, you cannot be sued for it. If you do not pay the debt,
   we may continue to report the debt to any credit reporting agency for as long as the law permits this reporting. In many circumstances,
    you can renew the debt and start the time period for the filing of a lawsuit against you if you take specific action such as making a written
     promise to pay. You should determine the effect of any actions you take with respect to this debt.`,
  solDisclosure6: `The law limits how long you can be sued on a debt. Because of the age of your debt, you cannot be sued for it and this debt cannot be reported
   to any credit reporting agency. In many circumstances, you can renew the debt and start the time period for the filing of a lawsuit against you
    if you take specific action such as making a written promise to pay. You should determine the effect of any actions you take with respect to this debt.`,
  solDisclosure7: `The law limits how long you can be sued on a debt. Because of the age of your debt, you cannot be sued  for it. If you do not pay the debt,
   we may report the debt to any credit reporting agency for as long as the law permits this reporting.`,
  solDisclosure8: `The law limits how long you can be sued on a debt. Because of the age of your debt, you cannot be sued  for it. If you do not pay the debt,
   we may continue to report the debt to any credit reporting agency for as long as the law permits this reporting.`,
  solDisclosure9: `The law limits how long you can be sued on a debt. Because of the age of your debt, you cannot be sued for it and this debt cannot be reported
   to any credit reporting agency.`,
  solDisclosure10: `The law limits how long you can be sued on a debt. Because of the age of your debt, you cannot be sued for it. If you do not pay the debt,
   we may report it to the credit reporting agencies as unpaid for as long as the law permits this reporting.`,
  solDisclosure11: `The law limits how long you can be sued on a debt. Because of the age of your debt, you cannot be sued for it. If you do not pay the debt,
   we may continue to report it to the credit reporting agencies as unpaid for as long as the law permits this reporting.`,
  solDisclosure12: `The law limits how long you can be sued on a debt. Because of the age of your debt, you will not be sued for it, and we will not report it
   to any credit reporting agency`,
  solDisclosure13: `The law limits how long you can be sued on a debt. Because of the age of your debt, you cannot be sued for it. If you do not pay the debt,
   we may report or continue to report it to the credit reporting agencies as unpaid.`,
  solDisclosure14: `The law limits how long you can be sued on a debt. Because of the age of your debt, you cannot be sued for it and we will not report it to
   any credit reporting agencies.`,
  solDisclosure15: `WE ARE REQUIRED BY REGULATION OF THE MASSACHUSETTS ATTORNEY GENERAL TO NOTIFY YOU OF THE FOLLOWING INFORMATION. THIS INFORMATION IS NOT LEGAL ADVICE:
   THIS DEBT MAY BE TOO OLD FOR YOU TO BE SUED ON IT IN COURT. IF IT IS TOO OLD, YOU CANNOT BE REQUIRED TO PAY IT THROUGH A LAWSUIT. TAKE NOTE:
    YOU CAN RENEW THE DEBT AND THE STATUTE OF LIMITATIONS FOR THE FILING OF A LAWSUIT AGAINST YOU IF YOU DO ANY OF THE FOLLOWING:
     MAKE ANY PAYMENT ON THE DEBT; SIGN A PAPER IN WHICH YOU ADMIT THAT YOU OWE THE DEBT OR IN WHICH YOU MAKE A NEW PROMISE TO PAY;
      SIGN A PAPER IN WHICH YOU GIVE UP OR WAIVE YOUR RIGHT TO STOP THE CREDITOR FROM SUING YOU IN COURT TO COLLECT THE DEBT.
       WHILE THIS DEBT MAY NOT BE ENFORCEABLE THROUGH A LAWSUIT, IT MAY STILL AFFECT YOUR ABILITY TO OBTAIN CREDIT OR AFFECT YOUR CREDIT SCORE OR RATING.`,
  solDisclosure16: `We are required by New Mexico Attorney General Rule to notify you of the following information. This information is not legal advice:
   This debt may be too old for you to be sued on it in court. If it is too old, you can’t be required to pay it through a lawsuit.
    You can renew the debt and start the time for the filing of a lawsuit against you to collect the debt if you do any of the following:
     make any payment of the debt; sign a paper in which you admit that you owe the debt or in which you make a new promise to pay;
      sign a paper in which you give up (“waive”) your right to stop the debt collector from suing you in court to collect the debt.`,
  solDisclosure17: `We are required by regulation of the New York State Department of Financial Services to notify you of the following information.
   This information is NOT legal advice:
  Your creditor or debt collector believes that the legal time limit (statute of limitations) for suing you to collect this debt may have expired.
   It is a violation of the Fair Debt Collection Practices Act, 15 U.S.C. § 1692 et seq., to sue to collect on a debt for which the statute of
    limitations has expired. However, if the creditor sues you to collect on this debt, you may be able to prevent the creditor from obtaining
     a judgment against you. To do so, you must tell the court that the statute of limitations has expired.
  Even if the statute of limitations has expired, you may choose to make payments on the debt.
  If you would like to learn more about your legal rights and options, you can consult an attorney or a legal assistance or legal aid organization.`,
  solDisclosure18: `THE LAW LIMITS HOW LONG YOU CAN BE SUED ON A DEBT. BECAUSE OF THE AGE OF YOUR DEBT, WE WILL NOT SUE YOU FOR IT.
  IF YOU DO NOT PAY THE DEBT, {currentCreditorAmount, plural, =0 {YOUR CURRENT CREDITOR} other {{currentCreditorName}}} MAY CONTINUE TO
  REPORT IT TO CREDIT REPORTING AGENCIES AS UNPAID FOR AS LONG AS THE LAW PERMITS THIS REPORTING. THIS NOTICE IS REQUIRED BY LAW.`,
  solDisclosure19: `THE LAW LIMITS HOW LONG YOU CAN BE SUED ON A DEBT. BECAUSE OF THE AGE OF YOUR DEBT, WE  WILL NOT SUE YOU FOR IT, AND WE WILL NOT REPORT IT
   TO ANY CREDIT REPORTING AGENCY.  THIS NOTICE IS REQUIRED BY LAW.`,
  solDisclosure20: `The law limits how long you can be sued on a debt. Because of the age of your debt, you cannot be sued for it. If you do not pay the debt,
   we may report or continue to report it to the credit reporting agencies as unpaid.`,
  solDisclosure21: `The law limits how long you can be sued on a debt.  Because of the age of your debt, you cannot be sued for it and we cannot report it to
   any credit reporting agencies.`,
  solDisclosure22: `THE LAW LIMITS HOW LONG YOU CAN BE SUED ON A DEBT. BECAUSE OF THE AGE OF YOUR DEBT,
  WE WILL NOT SUE YOU FOR IT. THIS NOTICE IS REQUIRED BY LAW.`,

  // Side Menu
  'sidemenu.disclosures': 'Disclosures',
  'sidemenu.disclosuresAria': 'Go to Disclosures Page',
  'sidemenu.documents': 'Documents',
  'sidemenu.documentsAria': 'Go to Documents Page',
  'sidemenu.home': 'Home',
  'sidemenu.homeAria': 'Go to Home Page',
  'sidemenu.logout': 'Log Out',
  'sidemenu.paymentHistory': 'Payment History',
  'sidemenu.paymentHistoryAria': 'Go to Payment History Page',
  'sidemenu.paymentPlans': 'Payment Plans',
  'sidemenu.paymentPlansAria': 'Go to Payment Plans Page',
  'sidemenu.referenceNumber': 'Ref. Number:',

  // Documents
  'documents.empty': 'You don’t have any documents',
  'documents.title': 'Documents',
  'documents.originalCreditor': 'Original Creditor',
  'documents.referenceNumber': 'Ref. Number: ',
  'documents.openedDate': 'Date Account Opened',
  'documents.accountNumber': 'Original Account Number',

  // Accounts
  'accounts.accountNumber': 'Original Account Number',
  'accounts.hideDetails': 'Hide Account Details',
  'accounts.openedDate': 'Date Account Opened',
  'accounts.originalBalance': 'Original Balance',
  'accounts.originalCreditor': 'Original Creditor',
  'accounts.paid': 'You already paid ',
  'accounts.paidOf': 'of',
  'accounts.referenceNumber': 'Reference Number',
  'accounts.remainingBalance': 'Remaining Balance',
  'accounts.showDetails': 'Account Details',
  'accounts.storeCard': 'Brand Name',
  'accounts.vehicleMake': 'Vehicle Make',
  'accounts.vehicleModel': 'Vehicle Model',
  'accounts.vehicleYear': 'Vehicle Year',
  'accounts.vin': 'VIN',
  'accounts.welcome': 'Welcome!',
  'accounts.selectAccount': 'Please select an account to get started',
  'accounts.switchAccount':
    'You will be able to switch accounts whenever you want from the top right of the page',

  // Legal
  'legal.debtCollector':
    'This is a communication from a debt collector. This is an attempt to collect a debt and any information obtained will be used for that purpose.',
  'legal.knowYourRights': 'Know your rights',
  'legal.troubleContact':
    'If you have trouble accessing your account\nplease call us at 866-539-7525 or through the {contactUsLink} page.\n© 2021 Spring Oaks Capital, LLC, All Rights Reserved.',
  'legal.contactUs': 'Contact Us',
  'legal.tryAgain': 'Try Again',

  // Repayment Options
  'selectRepaymentOption.createPaymentPlan': 'Create a Payment Plan',
  'selectRepaymentOption.partialPayment': 'Make a Partial Payment',
  'selectRepaymentOption.payInFull': 'Pay in Full',
  'selectRepaymentOption.title': 'Choose Your Repayment Option',

  // Partial Payment
  'partialPaymentPage.amount': 'Amount',
  'partialPaymentPage.amountError': 'Amount must be equal or less than Remaining Balance',
  'partialPaymentPage.amountErrorOne': 'Amount must be $1 minimum',
  'partialPaymentPage.amountSummary': '1 payment of {selectedAmount}',
  'partialPaymentPage.enterAmount': 'Or enter the exact amount you want to pay',
  'partialPaymentPage.percentageAria': 'Make a partial payment of {percentage}%',
  'partialPaymentPage.subtitle': 'Select the percentage you want to pay',
  'partialPaymentPage.title': 'Choose your Payment Amount',

  // Payment Options
  'paymentOptionsBlock.title': 'The journey to being debt-free starts with a single step.',
  'paymentOptionsBlock.desc': 'Start creating your payment plan or make a single payment.',
  'paymentOptionsBlock.singlePaymentDesc': 'Make a single payment towards your account today.',
  'paymentOptionsBlock.cta': 'More Payment Options',
  'paymentOptionsBlock.minifiedCTA': 'Payment Options',
  'paymentOptionsBlock.createPlan': 'Create a plan',
  'createPlan.frequencyTitle': 'Choose Your Payment Frequency',
  'createPlan.planTitle': 'Select the option that works best for you',
  'createPlan.continue': 'Continue',
  'createPlan.goBackAria': 'Go back to previous page',
  'createPlan.goBack': 'Back',
  'createPlan.continueAria': 'Continue to setup payment',
  'createPlan.disclaimer':
    '*The final payment of your plan may be less than your other payments to reflect the remaining balance and avoid overpayment',
  'paymentFrequency.weekly': 'Weekly',
  'paymentFrequency.biweekly': 'Every 2 weeks',
  'paymentFrequency.monthly': 'Monthly',
  'paymentFrequency.ofTheMonth': ' of the month',
  'paymentFrequency.biweeklyLabel': 'Bi-weekly',
  'paymentFrequency.ariaWeekly': 'Select weekly frequency',
  'paymentFrequency.ariaBiweekly': 'Select every 2 weeks frequency',
  'paymentFrequency.ariaMonthly': 'Select monthly frequency',
  'addPaymentMethod.setupPaymentMethod': 'Set up your payment method',
  'setupPayment.title': 'Set up your {payments, plural, one {Payment} other {Payments}}',
  'setupPayment.selectDate':
    'When would you like your {payments, plural, one {payment} other {payments}} to {payments, plural, one {be scheduled} other {begin}}?',
  'setupPayment.selectDateLabel': 'Select a date*',
  'setupPayment.selectDateError': 'You must select a Start Date to continue',
  'setupPayment.selectFrequency': 'Frequency',
  'setupPayment.everyOther': 'Every other',
  'setupPayment.every': 'Every',
  'setupPayment.daysDisclaimer':
    '*You can select a date up to {days} {days, plural, one {day} other {days}} from today.',
  'backBanner.originalCreditor': 'Original Creditor',
  'backBanner.remainingBalance': 'Remaining Balance',
  'backBanner.refNumber': 'Ref. Number',
  'backBanner.summaryAria': 'View/Hide banner details',
  'backBanner.summary': 'Summary',
  'backBanner.viewLess': 'View less',
  'termUnit.payments': 'Payments',

  // Cancel Plan
  'cancelPlan.title': 'To modify or cancel a recurring or scheduled payment, please call us at ',

  // Payment Plans
  'paymentPlans.empty': 'You don’t have any payment plans',
  'paymentPlans.endingIn': 'ending in',
  'paymentPlans.frequency': 'Frequency: ',
  'paymentPlans.installments': 'Installments: ',
  'paymentPlans.nextPayments': 'Next payments',
  'paymentPlans.remainingBalance': 'Remaining Balance: ',
  'paymentPlans.addPaymentMethod': 'Add payment method',
  'paymentPlans.paid': 'You already paid ',
  'paymentPlans.paidOf': 'of',
  'paymentPlans.payment': 'payment',
  'paymentPlans.paymentMethod': 'Payment method: ',
  'paymentPlans.payments': 'payments',
  'paymentPlans.title': 'Your Payment Plans',
  'paymentPlans.viewAll': 'View Your Payment Plans',

  // Payment Method
  'paymentMethod.card': 'Credit / Debit Card',
  'paymentMethod.bank': 'Bank Account (ACH)',
  'paymentMethod.bankMinified': 'Bank Account',

  'paymentMethod.disclosureBody':
    'If Spring Oaks Capital, LLC is reporting your account to a credit bureau, Spring Oaks Capital, LLC will request deletion of its tradeline from your credit report within 30 days of resolving the balance.',
  'paymentMethod.disclosureTitle': 'Notice',
  'paymentMethod.gotIt': 'Got it!',

  // Plan Types
  'planType.partialPayment': 'Partial Payment',
  'planType.payInFull': 'Pay in Full',
  'planType.paymentPlan': 'Payment Plan',

  // Days
  'days.monday': 'Monday',
  'days.tuesday': 'Tuesday',
  'days.wednesday': 'Wednesday',
  'days.thursday': 'Thursday',
  'days.friday': 'Friday',
  'days.saturday': 'Saturday',
  'days.sunday': 'Sunday',

  // Confirm Page
  'confirmPage.every': 'Every',
  'confirmPage.frequency': 'Frequency',
  'confirmPage.authorize': 'Authorize',
  'confirmPage.confirmAddPaymentMethod': 'Confirm',
  'confirmPage.paymentPlan': 'Payment Plan',
  'confirmPage.paymentDate': 'Payment Date',
  'confirmPage.paymentDates': 'Payments',
  'confirmPage.paymentMethod': 'Payment Method',
  'confirmPage.endingIn': 'ending in',
  'confirmPage.startingDate': 'Starting Date',
  'confirmPage.titleCreatePlan': 'Confirm Your Payment Plan',
  'confirmPage.titleAddPaymentMethod': 'Confirm Your Payment Method',
  'confirmPage.titlePayment': 'Confirm Your Payment',
  'confirmPage.totalBalance': 'Total Balance',
  'confirmPage.viewAllDates': 'View all dates',
  'confirmPage.viewLess': 'View less',

  // Error section

  'errorSection.title': 'Oh no!',
  'errorSection.subtitle': 'Something went wrong',
  'addPaymentMethodToPromiseErrorDescription.description':
    'Please go to your payment plans and restart the process.',
  'errorSection.goToPaymentPlans': 'Go to payment plans',

  // Payment Error
  'paymentError.genericDescription': 'Please check your payment details and try again',
  'paymentError.repayContactException':
    'We are experiencing issues processing payments. Please try again later or call us at 866-539-7525',
  'paymentError.repayDeclinedPaymentException':
    'The payment you submitted was not authorized by your bank. Please check your information and try again or contact your bank.',
  'paymentError.title': 'Something went wrong',
  'paymentError.tryAgain': 'Try again',

  // View Confirmation Page
  'viewPaymentConfirmation.createPlan.messageSuccess': 'Congratulations',
  'viewPaymentConfirmation.payment.messageSuccess': 'Payment Submitted!',
  'viewPaymentConfirmation.createPlan.paymentSuccess': 'You created your payment plan successfully',
  'viewPaymentConfirmation.payment.paymentSuccess':
    'Congratulations, your payment is being processed.',
  'viewPaymentConfirmation.transactionId': 'Transaction ID',
  'viewPaymentConfirmation.accountType': 'Account Type',
  'viewPaymentConfirmation.consumerName': 'Consumer Name',
  'viewPaymentConfirmation.createPlan.date': 'Starting Date',
  'viewPaymentConfirmation.payInFull.date': 'Date',
  'viewPaymentConfirmation.partialPayment.date': 'Date of payment',
  'viewPaymentConfirmation.originalCreditor': 'Original Creditor',
  'viewPaymentConfirmation.routingNumber': 'Routing Number',
  'viewPaymentConfirmation.referenceNumber': 'Reference Number',
  'viewPaymentConfirmation.createPlan.payment': 'Payment Plan',
  'viewPaymentConfirmation.partialPayment.payment': 'Partial Payment',
  'viewPaymentConfirmation.payInFull.payment': 'Amount',
  'viewPaymentConfirmation.frequency': 'Frequency',
  'viewPaymentConfirmation.every': 'Every',
  'viewPaymentConfirmation.startingDate': 'Starting Date',
  'viewPaymentConfirmation.paymentMethod': 'Payment Method',
  'viewPaymentConfirmation.endingIn': 'ending in',
  'viewPaymentConfirmation.saveAsPdf': 'Save as PDF',
  'viewPaymentConfirmation.printReceipt': 'Print Receipt',
  'viewPaymentConfirmation.emailReceipt': 'Email Receipt',
  'viewPaymentConfirmation.checkPaymentPlan':
    'You can check your payment plan details in the “payment plan section"',
  'viewPaymentConfirmation.backToHome': 'Back to Home',

  // View add payment method confirmation section

  'viewAddPaymentMethodConfirmation.addPaymentMethod.messageSuccess': 'Payment method registered',
  'viewAddPaymentMethodConfirmation.addPaymentMethod.planLabel': 'Payments',
  'viewAddPaymentMethodConfirmation.addPaymentMethod.addPaymentMethodSuccess':
    'Congratulations, your payment method has been registered',
  'viewAddPaymentMethodConfirmation.addPaymentMethod.paymentResume':
    '{payments} {payments, plural, one {payment} other {payments}} of {baseAmount} {surplus, plural, =0 {} one { and 1 payment of {lastPaymentAmount}}}',

  // Payment History Page
  'paymentHistory.amount': 'Amount',
  'paymentHistory.date': 'Date',
  'paymentHistory.empty': 'You don’t have any payments yet',
  'paymentHistory.emptyImgAlt': 'Woman reaching for the air',
  'paymentHistory.paymentMethod': 'Payment Method',
  'paymentHistory.status': 'Status',
  'paymentHistory.subtitle': 'Previous Payments',
  'paymentHistory.title': 'Payment History',
  'paymentHistory.viewAll': 'View all',

  // Payment Plan Landing Page
  'specialPaymentPlan.title': 'Payment Plan To Resolve Your Balance',
  'specialPaymentPlan.payFullBalance': 'Pay Full Balance',
  'specialPaymentPlan.selectFirstPaymentDate': '2. Select date of first payment',
  'specialPaymentPlan.standardPayments': '{numStandardPayments} monthly payments of',
  'specialPaymentPlan.finalPayment': 'Plus 1 final payment of',
  'specialPaymentPlan.or': 'OR',
  'specialPaymentPlan.proposedAmountLabel': 'Enter desired monthly payment:',
  'specialPaymentPlan.recalculatePaymentsLabel': 'Recalculate Payments',
  'specialPaymentPlan.selectPaymentMethodLabel': 'Select Payment Method',
  'specialPaymentPlan.proposedAmountTooHigh': 'Proposed payment amount too high.',
  'specialPaymentPlan.proposedAmountTooLow': 'Proposed payment amount too low.',

  // Special Payment Plan Modal
  'specialPaymentPlanModal.title': 'There was a problem',
  'specialPaymentPlanModal.buttonText': 'Got it',
  'specialPaymentPlanModal.bodyTextHigh':
    'The proposed amount you entered was too high. Please enter a lower payment amount.',
  'specialPaymentPlanModal.bodyTextLow':
    'The proposed amount you entered was too low. Please enter a higher payment amount.',

  // Settlement Page
  'settlement.title': 'Special Offer To Resolve Your Balance',
  'settlement.subtitle': 'Select your payment method of preference',
  'settlement.settleInFull': 'One Payment',
  'settlement.createPlan': 'Create a Plan',
  'settlement.paymentDate': 'Payment Date',
  'settlement.amount': 'Amount',
  'settlement.backAria': 'Go back to previous page',
  'settlement.back': 'Back',
  'settlement.card': 'Credit / Debit Card',
  'settlement.bank': 'Bank Account',
  'settlement.bankMinified': 'Bank Account',
  'settlement.confirmSettlement': 'Confirm',
  'settlement.amountTitle': 'Current Balance:',
  'settlement.offerTitle': 'Resolve Your Balance For:',
  'settlement.referenceIdTitle': 'Reference ID:',
  'settlement.selectYourPayments': '1. Select your payments',
  'settlement.selectMonthAndDay': '2. Select month & day of payments',
  'settlement.selectMonth': 'Select Month',
  'settlement.selectDay': 'Select Day',
  'settlement.paymentDates': 'Payment Dates',
  'settlement.setUpPayment': '1. Set up your payment',
  'settlement.paymentDateQuestion': 'When would you like your payment to be scheduled?',
  'settlement.daysDisclaimerBegin': '*You can select a date up to',
  'settlement.daysDisclaimerEnd': 'days from today.',
  'settlement.selectYourPaymentMethod': 'Select your payment method',
  'settlement.monthly': '/ Monthly',
  'settlement.payments': 'payments',
  'settlement.backToHome': 'Back to Home',
  'settlement.of': 'of',
  'settlement.noObligation': 'Spring Oaks Capital, LLC is not obligated to renew this offer.',
  'settlement.miniMiranda':
    'This is a communication from a debt collector. This is an attempt to collect a debt and any information obtained will be used for that purpose.',
  'settlement.knowYourRights': 'Know Your Rights',
  'settlementConfirmation.messageSuccess': 'Registered payment method',
  'settlementConfirmation.paymentSuccess':
    'Congratulations, your payment method has been registered for your Payment Plan',
  'settlementLogin.unverifiedLoginTitle': 'Welcome!',
  'settlementLogin.unverifiedLoginBody':
    'Please verify your identity to see your offer by providing one of the following fields.',
  'settlementLogin.unverifiedLoginDOB': 'Date of Birth',
  'settlementLogin.unverifiedLoginDOBPlaceholder': 'MM/DD/YYYY',
  'settlementLogin.unverifiedLoginSSN': 'Last Four of SSN',
  'settlementLogin.unverifiedLoginSSNPlaceholder': 'Enter the last four numbers of SSN',
  'settlementLogin.numSubmitFields': 'You must submit at least 1 field to access.',
  'settlementLogin.verify': 'Verify',
  'settlementLogin.or': 'OR',

  // Settlement Error
  'settlementError.ohNo': 'Oh no!',
  'settlementError.wentWrong': 'Something went wrong',
  'settlementError.action':
    'This Offer is either invalid or has expired.  Please log into the self service portal to access your account and make a payment.',
  'settlementError.cta': 'Back to Home page',
  // Not Found
  'notFound.altImage': 'Woman holding Spring Oaks Capital logo',
  'notFound.back': 'Back to home page',
  'notFound.explanation': 'The page you were looking for does not exist',
  'notFound.title': 'Page Not Found',

  // Errors
  'ssn.invalid': 'You must enter a 4 digit SSN number',
  'email.invalid': 'You must enter a valid email',
  'password.presence': 'You must enter a password to continue',
  'passwordConfirmation.presence': 'You must enter a password confirmation to continue',
  'passwordConfirmation.equality': 'Your password confirmation must be equal to the password',
  'account.suppressed':
    'Online access to your account is not available at this time. Please contact us at {contactUsNumber}\n or through the {contactUsLink} page.',
  'account.suppressed.logout': 'or Log Out'
};
