import React from 'react';
import { Grid, Text, Divider, Flex } from '@chakra-ui/react';
import { arrayOf, object, bool, shape, string } from 'prop-types';

const SettlementSuccessGrid = ({ data }) => {
  return (
    <Grid
      w="90%"
      maxW="650px"
      flex="1"
      gridTemplateRows="1fr"
      backgroundColor="white"
      borderRadius="6px"
      boxShadow="0px 10px 15px -3px rgba(0, 0, 0, 0.04)"
      gridGap={4}
      paddingX={{ base: 5, md: 10 }}
      paddingY={{ base: 6, md: 8 }}
      className="settlement-success-content"
    >
      {data.map((item, index) => {
        if (item.label && item.value) {
          const { isPaymentDates } = item;

          if (isPaymentDates) {
            return (
              <>
                <Flex direction="column" justifyContent="center" className="success-info-container">
                  <Text
                    mb={2}
                    alignSelf="flex-start"
                    fontSize={{ base: 'md', md: 'xl' }}
                    color="alpha.500"
                  >
                    {item.label}
                  </Text>
                  {item.value}
                </Flex>
                {index !== data.length - 1 && <Divider orientation="horizontal" />}
              </>
            );
          }
          return (
            <>
              <Grid
                key={item.label}
                gridTemplateColumns="1fr 2fr"
                gridGap={2}
                justifyContent="space-between"
                className="success-info-container"
              >
                <Text alignSelf="flex-start" fontSize={{ base: 'md', md: 'xl' }} color="alpha.500">
                  {item.label}
                </Text>
                <Text
                  fontSize={{ base: 'md', md: 'xl' }}
                  color="alpha.500"
                  fontWeight={700}
                  textAlign="right"
                >
                  {item.value}
                </Text>
              </Grid>
              {index !== data.length - 1 && <Divider orientation="horizontal" />}
            </>
          );
        }
        return null;
      })}
    </Grid>
  );
};

SettlementSuccessGrid.propTypes = {
  data: arrayOf(
    shape({
      label: string,
      isPaymentDates: bool,
      value: string || object
    })
  ).isRequired
};

export default SettlementSuccessGrid;
