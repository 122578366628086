import React, { useEffect, useRef, memo } from 'react';
import { string } from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { useAnalytics, useDispatch, useSelectAccountId } from 'hooks';
import { document } from 'state/actions/userActions';
import DownloadIcon from 'assets/icons/downloadIcon.svg';
import { MIXPANEL_EVENTS } from 'constants/mixpanelEvents';

const Document = ({ documentId, documentName, documentBase64 }) => {
  const { accountId } = useSelectAccountId();
  const downloadElementRef = useRef();
  const documentRequest = useDispatch(document);
  const { trackEvent } = useAnalytics();

  const handleDownloadDocument = () => {
    documentRequest({ accountId, documentId });
    trackEvent(MIXPANEL_EVENTS.downloadDocument, { fileName: documentName });
  };

  useEffect(() => {
    !isEmpty(documentBase64) && downloadElementRef.current.click();
  }, [documentBase64]);

  return (
    <div className="document-item-container">
      <button
        type="button"
        aria-label={`Download ${documentName}`}
        className="document-item-content"
        onClick={handleDownloadDocument}
      >
        <a
          className="document-item-content-link"
          ref={downloadElementRef}
          {...(documentBase64 && { href: documentBase64, download: documentName })}
        >
          {documentName}
          <div className="document-download-icon">
            <DownloadIcon />
          </div>
        </a>
      </button>
    </div>
  );
};

Document.propTypes = {
  documentId: string.isRequired,
  documentName: string.isRequired,
  documentBase64: string.isRequired
};

export default memo(Document);
