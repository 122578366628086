import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: { id: 'settlement.title' },
  subtitle: { id: 'settlement.subtitle' },
  amountTitle: { id: 'settlement.amountTitle' },
  offerTitle: { id: 'settlement.offerTitle' },
  referenceIdTitle: { id: 'settlement.referenceIdTitle' },
  selectYourPayments: { id: 'settlement.selectYourPayments' },
  selectMonthAndDay: { id: 'settlement.selectMonthAndDay' },
  selectMonth: { id: 'settlement.selectMonth' },
  selectDay: { id: 'settlement.selectDay' },
  paymentDates: { id: 'settlement.paymentDates' },
  setUpPayment: { id: 'settlement.setUpPayment' },
  paymentDateQuestion: { id: 'settlement.paymentDateQuestion' },
  daysDisclaimerBegin: { id: 'settlement.daysDisclaimerBegin' },
  daysDisclaimerEnd: { id: 'settlement.daysDisclaimerEnd' },
  selectYourPaymentMethod: { id: 'settlement.selectYourPaymentMethod' },
  of: { id: 'settlement.of' },
  noObligation: { id: 'settlement.noObligation' },
  miniMiranda: { id: 'settlement.miniMiranda' },
  knowYourRights: { id: 'settlement.knowYourRights' }
});
