import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { string, bool } from 'prop-types';

import HumanSitting from 'assets/icons/humanSitting.svg';
import PaymentCTA from 'components/paymentPlans/PaymentCTA';

const EmptyPage = ({ title, buttonTitle = '', showPaymentCTA = false }) => (
  <Box textAlign="center" className="empty-page">
    <HumanSitting />
    <Text
      fontSize={{ base: '20px', lg: '24px' }}
      mb={{ base: 3, sm: 4, md: 5 }}
      className="empty-page-title"
    >
      {title}
    </Text>
    {showPaymentCTA && (
      <Box textAlign="left">
        <PaymentCTA buttonTitle={buttonTitle} />
      </Box>
    )}
  </Box>
);

EmptyPage.propTypes = {
  title: string.isRequired,
  buttonTitle: string,
  showPaymentCTA: bool
};

export default EmptyPage;
