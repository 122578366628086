import { useEffect } from 'react';
import { lvWebWidget } from 'assets/scripts/lvWebWidget';

const URL = process.env.LV_CHAT_LIVE_URL;
const SECONDS_BEFORE_RENDER = process.env.LV_CHAT_SECONDS_BEFORE_RENDER;

const useLiveChat = (verified = true) => {
  useEffect(() => {
    const widget = document?.getElementById('outer_web_widget');
    let timer1;
    if (!verified) return;

    if (!widget) {
      if (!document?.getElementById('lvChatParent')) {
        const parent = document.createElement('div');
        parent.id = 'lvChatParent';
        document.getElementById('app')?.appendChild(parent);
      }

      if (!document?.getElementById('liveChatWidget')) {
        const script = document.createElement('script');
        script.src = URL;
        script.async = true;
        script.onload = () => {
          if (!document?.getElementById('liveChatWidget')) {
            const widgetScript = document.createElement('script');
            widgetScript.type = 'text/javascript';
            widgetScript.id = 'liveChatWidget';
            widgetScript.innerHTML = lvWebWidget;

            if (!sessionStorage.getItem('is_reloaded')) {
              sessionStorage.setItem('is_reloaded', true);
              timer1 = setTimeout(() => {
                document.getElementById('lvChatParent')?.appendChild(widgetScript);
              }, SECONDS_BEFORE_RENDER * 1000);
            } else document.getElementById('lvChatParent')?.appendChild(widgetScript);
          }
        };
        document.getElementById('lvChatParent')?.appendChild(script);
      }
    } else if (!sessionStorage.getItem('is_reloaded')) {
      sessionStorage.setItem('is_reloaded', true);
      timer1 = setTimeout(() => {
        widget.style.display = 'block';
      }, SECONDS_BEFORE_RENDER * 1000);
    } else widget.style.display = 'block';
    return () => {
      const parent = document.getElementById('lvChatParent');
      if (parent) parent.remove();
      const elem = document?.getElementById('outer_web_widget');
      if (elem) elem.style.display = 'none';
      if (timer1) clearTimeout(timer1);
    };
  }, [verified]);
};

export default useLiveChat;
