import React from 'react';
import { emitter } from '@marvelapp/react-ab-test';
import { activeExperiments } from 'constants/experiments';
import { useIntl } from 'react-intl';
import { string } from 'prop-types';

import { Pane } from 'components/common';
import Button, { buttonColor, buttonStyle, buttonSize } from 'components/common/Button';
import routes from 'constants/routesPaths';
import { useAnalytics, useSelectPaymentPlans } from 'hooks';
import { MIXPANEL_EVENTS, MIXPANEL_LOCATIONS } from 'constants/mixpanelEvents';

const PaymentCTABlock = ({ buttonTitle }) => {
  const intl = useIntl();
  const { trackEvent } = useAnalytics();
  const { hasPaymentPlans } = useSelectPaymentPlans();

  const trackEvents = () => {
    emitter.emitWin(activeExperiments?.paymentMethodDisclosures?.name);
    trackEvent(MIXPANEL_EVENTS.clickMorePaymentOptions, {
      Location: MIXPANEL_LOCATIONS.accountDetails
    });
  };

  return (
    <Pane className="payment-options-block" wide>
      <div>
        <h2>{intl.formatMessage({ id: 'paymentOptionsBlock.title' })}</h2>
        <span>
          {intl.formatMessage({
            id: hasPaymentPlans
              ? 'paymentOptionsBlock.singlePaymentDesc'
              : 'paymentOptionsBlock.desc'
          })}
        </span>
      </div>

      <Button
        linkTo={routes.repaymentOptions}
        buttonStyle={buttonStyle.PRIMARY}
        size={buttonSize.SMALL}
        color={buttonColor.BLACK}
        onClick={trackEvents}
      >
        {buttonTitle}
      </Button>
    </Pane>
  );
};

PaymentCTABlock.propTypes = {
  buttonTitle: string.isRequired
};

export default PaymentCTABlock;
