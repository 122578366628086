import React, { useState, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import cn from 'classnames';

import BankIcon from 'assets/icons/bankIcon.svg';
import CardIcon from 'assets/icons/cardIcon.svg';
import { TileButton } from 'components/common';
import { REPAYMENT_OPTIONS, PAYMENT_STATUS, PAYMENT_METHOD_OPTIONS } from 'constants/constants';
import { selectDate, paymentPlanOptions } from 'state/actions/paymentPlanActions';
import {
  paymentForm,
  resetPaymentForm,
  selectPaymentMethod,
  savePaymentToken,
  savePaymentCardType,
  savePaymentSource,
  savePaymentLast4,
  savePaymentRoutingNumber,
  savePaymentNameOnAccount,
  savePaymentDetails
} from 'state/actions/paymentMethodActions';
import {
  useSelectSelectedPlan,
  useSelectAccountId,
  useDispatch,
  useSelectPaymentForm,
  useSelectPaymentMethod,
  useSelectPaymentInfo,
  useAnalytics,
  useStatus,
  useLiveChat
} from 'hooks';
import { MIXPANEL_EVENTS } from 'constants/mixpanelEvents';
import { FULFILLED } from 'constants/actionStatusConstants';
import { Box, Spinner } from '@chakra-ui/react';
import TransactionalHeader from 'components/common/TransactionalHeader';
import useSectionRouter from 'hooks/sectionRouter';
import { ADD_PAYMENT_METHOD_TO_PROMISE } from 'constants/sections';

const { CONFIRM_PAYMENT_METHOD } = ADD_PAYMENT_METHOD_TO_PROMISE;

const repayUrls = process.env.REPAY_URLS.split(',');

const AddPaymentMethodPage = () => {
  const { push, pop } = useSectionRouter();
  const intl = useIntl();
  const { trackEvent } = useAnalytics();
  const { accountId } = useSelectAccountId();
  const setDate = useDispatch(selectDate);
  const getPlanOptions = useDispatch(paymentPlanOptions);
  const { optionStatus } = useStatus(paymentPlanOptions);
  const paymentFormRequest = useDispatch(paymentForm);
  const selectPaymentMethodRequest = useDispatch(selectPaymentMethod);
  const resetPaymentFormRequest = useDispatch(resetPaymentForm);
  const savePaymentTokenRequest = useDispatch(savePaymentToken);
  const savePaymentCardTypeRequest = useDispatch(savePaymentCardType);
  const savePaymentSourceRequest = useDispatch(savePaymentSource);
  const savePaymentLast4Request = useDispatch(savePaymentLast4);
  const savePaymentDetailsRequest = useDispatch(savePaymentDetails);
  const savePaymentRoutingNumberRequest = useDispatch(savePaymentRoutingNumber);
  const savePaymentNameOnAccountRequest = useDispatch(savePaymentNameOnAccount);

  const paymentLoaderRef = useRef();
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [loaderVisible, setLoaderVisible] = useState(false);
  const selectedPaymentMethodRef = useRef();

  const { selectedRepaymentOption } = useSelectSelectedPlan();

  const { paymentFormUrl, hasPaymentForm } = useSelectPaymentForm();
  const { selectedPaymentMethod } = useSelectPaymentMethod();
  const { paymentToken } = useSelectPaymentInfo();
  const isCreatePlan = selectedRepaymentOption === REPAYMENT_OPTIONS.createPlan;
  const isPaymentMethodACH = selectedPaymentMethod === PAYMENT_METHOD_OPTIONS.bank;
  const isPaymentMethodCC = selectedPaymentMethod === PAYMENT_METHOD_OPTIONS.card;

  useLiveChat();

  const paymentHandler = e => {
    if (!repayUrls.includes(e.origin)) return;
    if (e.data === PAYMENT_STATUS.success) setPaymentSuccess(true);
    if (e.data?.paymentResponseData) {
      const {
        saved_payment_method: { token },
        payment_type_id: savedCardType,
        customer_id: customerId,
        account_type: accountType,
        pn_ref: pnRef,
        result_details: { card_info: { type = '' } = {} } = {},
        result,
        last4,
        date,
        transit_number: routingNumber,
        name_on_check: nameOnAccount
      } = e.data.paymentResponseData;
      const paymentSource =
        PAYMENT_METHOD_OPTIONS.card === selectedPaymentMethodRef.current
          ? savedCardType
          : accountType;
      savePaymentCardTypeRequest(savedCardType);
      savePaymentSourceRequest(paymentSource);
      savePaymentLast4Request(last4);
      savePaymentTokenRequest(token);
      savePaymentRoutingNumberRequest(routingNumber);
      savePaymentNameOnAccountRequest(nameOnAccount);
      savePaymentDetailsRequest({
        date,
        customer_id: customerId,
        pn_ref: pnRef,
        card_type: type,
        result,
        payment_last4: last4,
        payment_source: paymentSource
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener('message', paymentHandler);

    trackEvent(MIXPANEL_EVENTS.initiateAddDetailsToPromise);

    resetPaymentFormRequest();
    optionStatus !== FULFILLED && getPlanOptions(accountId);
    setDate(new Date());

    return () => window.removeEventListener('message', paymentHandler);
  }, []);

  const onPaymentMethodSelect = paymentMethod => {
    setLoaderVisible(!loaderVisible);
    resetPaymentFormRequest();
    selectPaymentMethodRequest(paymentMethod);
    selectedPaymentMethodRef.current = paymentMethod;
    paymentFormRequest({ accountId, methodType: paymentMethod });
    return paymentLoaderRef.current?.scrollIntoView();
  };

  useEffect(() => {
    if (paymentSuccess && paymentToken) {
      trackEvent(MIXPANEL_EVENTS.authorizeAddDetailsToPromise);
      push(CONFIRM_PAYMENT_METHOD);
    }
  }, [paymentSuccess, paymentToken]);

  return (
    <div className="setup-payment-page-container">
      <TransactionalHeader
        ariaLabel={intl.formatMessage({ id: 'createPlan.goBackAria' })}
        title={intl.formatMessage({ id: 'createPlan.goBack' })}
        backToCallback={pop}
      />
      <div className="payment-container">
        <div className="container">
          <h1 className="page-title">
            {intl.formatMessage(
              { id: 'addPaymentMethod.setupPaymentMethod' },
              { payments: isCreatePlan ? 2 : 1 }
            )}
          </h1>
          <div className="tile-button-container mb-0 justify-content-center" ref={paymentLoaderRef}>
            <TileButton
              label={intl.formatMessage({ id: 'paymentMethod.card' })}
              ariaLabel={intl.formatMessage({ id: 'paymentMethod.card' })}
              active={isPaymentMethodCC}
              onClick={() => onPaymentMethodSelect(PAYMENT_METHOD_OPTIONS.card)}
              icon={<CardIcon />}
            />
            <TileButton
              label={intl.formatMessage({ id: 'paymentMethod.bank' })}
              ariaLabel={intl.formatMessage({ id: 'paymentMethod.bank' })}
              active={isPaymentMethodACH}
              onClick={() => onPaymentMethodSelect(PAYMENT_METHOD_OPTIONS.bank)}
              icon={<BankIcon />}
            />
          </div>
        </div>
      </div>
      <Box textAlign="center">{loaderVisible && <Spinner size="lg" color="beta.400" />}</Box>
      <div
        className={cn(
          'payment-method-container',
          { 'payment-method-card': isPaymentMethodCC },
          { 'payment-method-bank': isPaymentMethodACH }
        )}
      >
        {hasPaymentForm && (
          <iframe
            title="Repay Payment Method"
            key={paymentFormUrl}
            src={paymentFormUrl}
            onLoad={() => setLoaderVisible(false)}
            scrolling="no"
            className="h-100 w-100 pt-3"
          />
        )}
      </div>
    </div>
  );
};

export default AddPaymentMethodPage;
