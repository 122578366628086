import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { getMonthAbrev } from 'utils/helpers';
import { bool, number, func } from 'prop-types';

const MonthButton = ({ month, selected, onClick }) => {
  return (
    <Flex
      py={2}
      background={selected ? 'beta.400' : 'gamma.50'}
      borderRadius="27px"
      width="80px"
      height="34px"
      cursor="pointer"
      alignItems="center"
      justifyContent="center"
      onClick={onClick}
      margin={1}
    >
      <Text as="span" fontSize="xs" color={selected ? 'white' : 'alpha.300'} fontWeight={700}>
        {getMonthAbrev(month)}
      </Text>
    </Flex>
  );
};

MonthButton.propTypes = {
  selected: bool.isRequired,
  month: number.isRequired,
  onClick: func.isRequired
};

export default MonthButton;
