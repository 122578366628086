import { useSelector, shallowEqual } from 'react-redux';

const useSelectSideMenuInfo = () =>
  useSelector(
    ({ accounts: { accountSelected } }) => ({
      firstName: accountSelected?.accountholderFirstName,
      lastName: accountSelected?.accountholderLastName,
      referenceNumber: accountSelected?.accountId
    }),
    shallowEqual
  );

export default useSelectSideMenuInfo;
