import React from 'react';
import { arrayOf, shape, number, string, bool } from 'prop-types';
import { useIntl, defineMessages } from 'react-intl';

import { CurrencyText, Pane, Label, Button } from 'components/common';
import { PLAN_PAYMENT_STATUS } from 'constants/constants';
import { SHORT_DATE_FORMAT, formatDate } from 'utils/dateHelpers';
import routes from 'constants/routesPaths';

const PaymentsOverview = ({ payments, maxItems = -1, showMoreCTA = false }) => {
  const intl = useIntl();

  const messages = defineMessages({
    date: { id: 'paymentHistory.date' },
    amount: { id: 'paymentHistory.amount' },
    status: { id: 'paymentHistory.status' },
    paymentMethod: { id: 'paymentHistory.paymentMethod' }
  });

  const getColor = status => {
    switch (status) {
      case PLAN_PAYMENT_STATUS.pending:
        return 'blue';
      case PLAN_PAYMENT_STATUS.paid:
        return 'green';
      case PLAN_PAYMENT_STATUS.failure:
        return 'red';
      default:
        return 'gray';
    }
  };

  return (
    <>
      <section className="d-flex justify-content-between">
        <span className="page-subtitle">
          {intl.formatMessage({ id: 'paymentHistory.subtitle' })}
        </span>
        {showMoreCTA && (
          <Button
            linkTo={routes.paymentHistory}
            buttonStyle="secondary"
            size="x-small"
            title={intl.formatMessage({ id: 'paymentHistory.viewAll' })}
          />
        )}
      </section>

      <div className="payments-header">
        <span>{intl.formatMessage(messages.date)}</span>
        <span className="hide-sm">{intl.formatMessage(messages.paymentMethod)}</span>
        <span>{intl.formatMessage(messages.amount)}</span>
        <span>{intl.formatMessage(messages.status)}</span>
      </div>

      <Pane className="payments-table mb-3">
        {payments.map(({ amount, statusCode, date, paymentMethod }, index) => {
          if (maxItems > 0 && index + 1 > maxItems) return;

          return (
            <div key={`payment-${index}`} className="payments-row">
              <span>{formatDate(date, SHORT_DATE_FORMAT)}</span>
              <span className="hide-sm">{paymentMethod}</span>
              <span className="font-weight-bold">
                <CurrencyText value={amount} />
              </span>
              <span>
                <Label label={statusCode.toLowerCase()} color={getColor(statusCode)} />
              </span>
            </div>
          );
        })}
      </Pane>
    </>
  );
};

PaymentsOverview.propTypes = {
  payments: arrayOf(
    shape({
      paymentAmount: number,
      paymentStatus: string,
      paymentDate: string
    })
  ).isRequired,
  maxItems: number,
  showMoreCTA: bool
};

export default PaymentsOverview;
