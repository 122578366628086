import React from 'react';
import { string, oneOf } from 'prop-types';

import { black, md } from 'styles/_variables.scss';
import ArrowIcon from 'components/icons/ArrowIcon';
import ArrowMobileIcon from 'components/icons/ArrowMobileIcon';
import { useBreakpoint } from 'hooks';

const UP = 'up';
const DOWN = 'down';
const LEFT = 'left';
const RIGHT = 'right';

const Arrow = ({ type = DOWN, className = '', color = black }) => {
  const isDesktop = useBreakpoint(md);
  const getArrowRotation = type => {
    switch (type) {
      case LEFT:
        return 0;
      case UP:
        return 90;
      case RIGHT:
        return 180;
      default:
        return 270;
    }
  };

  return (
    <div
      className={className}
      style={{
        display: 'inline-block',
        transform: `rotate(${getArrowRotation(type)}deg)`,
        transformOrigin: '50% 50%'
      }}
    >
      {isDesktop ? <ArrowIcon color={color} /> : <ArrowMobileIcon color={color} />}
    </div>
  );
};

Arrow.propTypes = {
  className: string,
  color: string,
  type: oneOf([DOWN, UP, LEFT, RIGHT])
};

export default Arrow;
