import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  unverifiedLoginTitle: { id: 'settlementLogin.unverifiedLoginTitle' },
  unverifiedLoginBody: { id: 'settlementLogin.unverifiedLoginBody' },
  unverifiedLoginDOB: { id: 'settlementLogin.unverifiedLoginDOB' },
  unverifiedLoginDOBPlaceholder: { id: 'settlementLogin.unverifiedLoginDOBPlaceholder' },
  unverifiedLoginSSN: { id: 'settlementLogin.unverifiedLoginSSN' },
  unverifiedLoginSSNPlaceholder: { id: 'settlementLogin.unverifiedLoginSSNPlaceholder' },
  numSubmitFields: { id: 'settlementLogin.numSubmitFields' },
  verify: { id: 'settlementLogin.verify' },
  or: { id: 'settlementLogin.or' }
});
