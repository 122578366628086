import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { oneOfType, bool, func, number, string } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

const Input = ({
  label,
  name,
  value,
  placeholder,
  onChange,
  onBlur = () => {},
  active,
  error,
  touched,
  min = 0,
  ...props
}) => (
  <div className="input-component">
    {label && (
      <label
        className={cn('input-component__label', {
          'input-component__label--error': error,
          'input-component__label--empty': isEmpty(value)
        })}
        htmlFor={name}
      >
        {label}
      </label>
    )}
    <div className="input-component-container">
      <input
        className={cn('input-component-container-content', {
          'input-component-container-content--error': error,
          'input-component-container-content--empty': isEmpty(value)
        })}
        aria-label={label}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        min={min}
        {...props}
      />
      {error && (
        <span className="span--error">
          <FormattedMessage id={error} defaultMessage={error} />
        </span>
      )}
    </div>
  </div>
);

Input.propTypes = {
  name: string.isRequired,
  label: string,
  value: string,
  min: number,
  placeholder: string,
  onChange: func.isRequired,
  onBlur: func,
  error: oneOfType([string, bool]),
  active: bool.isRequired,
  touched: bool.isRequired
};

export default Input;
