import React, { useState } from 'react';
import { Flex, Box, Text } from '@chakra-ui/react';

import { useIntl } from 'react-intl';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { Redirect } from 'react-router-dom';

import { Button, ContentContainer, Pane } from 'components/common';
import { accounts, setAccountSelectedId } from 'state/actions/userActions';
import routes from 'constants/routesPaths';
import {
  useSelectAccountOptions,
  useSelectAccountSelected,
  useSelectAccountIds,
  useSession,
  useDispatch
} from 'hooks';

const MultipleAccountsPage = () => {
  const intl = useIntl();
  const [accountIdSelected, setAccountIdSelected] = useState();
  const { accountIds } = useSelectAccountIds();
  const { authenticated } = useSession();
  const { accountOptions } = useSelectAccountOptions();
  const { accountSelected } = useSelectAccountSelected();
  const setAccountId = useDispatch(setAccountSelectedId);
  const accountsRequest = useDispatch(accounts);

  if (!isEmpty(accountSelected) || !authenticated) {
    return <Redirect to={routes.index} />;
  }

  if (isEmpty(accountOptions)) {
    accountsRequest(accountIds);
  }

  return (
    <ContentContainer noMargin>
      <Flex
        className="container multiple-accounts-page"
        direction="column"
        h="100%"
        py={12}
        maxW="420px"
      >
        <Box textAlign={{ base: 'left', bigSm: 'center' }}>
          <Text as="h1" fontSize={{ base: '32px', bigSm: '40px' }} mb={2} className="welcome">
            {intl.formatMessage({ id: 'accounts.welcome' })}
          </Text>
          <Text as="p" mb={2} fontSize="20px">
            {intl.formatMessage({ id: 'accounts.selectAccount' })}
          </Text>
          <Text as="p" mb={4} fontSize="16px">
            {intl.formatMessage({ id: 'accounts.switchAccount' })}
          </Text>
        </Box>

        {accountOptions.map(({ accountId, accountName }) => (
          <button
            className="account-option"
            key={accountId}
            onClick={() => setAccountIdSelected(accountId)}
            type="button"
            aria-label={intl.formatMessage({ id: 'accounts.selectAccount' })}
          >
            <Pane
              className={cn('account-option-content', {
                active: accountId === accountIdSelected
              })}
            >
              <div className="font-weight-bold">{accountName}</div>
              {accountId}
            </Pane>
          </button>
        ))}
        <Button
          buttonStyle="primary"
          className="mt-3"
          size="medium"
          color="green"
          onClick={() => setAccountId(accountIdSelected)}
          title={intl.formatMessage({ id: 'createPlan.continue' })}
          ariaLabel={intl.formatMessage({ id: 'createPlan.continueAria' })}
          isDisabled={!accountIdSelected}
          linkTo={{ pathname: routes.disclosures, state: { hideMenu: true } }}
        />
      </Flex>
    </ContentContainer>
  );
};

export default MultipleAccountsPage;
