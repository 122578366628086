import React from 'react';
import cn from 'classnames';
import { bool, string } from 'prop-types';

const TooltipInfo = ({ title, description, isDesktop = false }) => (
  <>
    <h2
      className={cn(
        'tooltip-title',
        { 'tooltip-title-mobile': !isDesktop },
        { 'tooltip-title-desktop': isDesktop }
      )}
    >
      {title}
    </h2>
    <p
      className={cn(
        'tooltip-description',
        { 'tooltip-description-mobile': !isDesktop },
        { 'tooltip-description-desktop': isDesktop }
      )}
    >
      {description}
    </p>
  </>
);

TooltipInfo.propTypes = {
  title: string.isRequired,
  description: string.isRequired,
  isDesktop: bool
};

export default TooltipInfo;
