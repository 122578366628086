import { Stack, Text } from '@chakra-ui/react';
import { CurrencyText } from 'components/common';
import React from 'react';
import dayjs from 'dayjs';

const PaymentPlanList = ({ paymentSchedule, withPrices, textListProps }) => {
  return (
    <Stack w="full" alignItems="end">
      {paymentSchedule.map(({ paymentDate, paymentAmount }) => (
        <Text {...textListProps}>
          {`${dayjs(paymentDate).format('MMM D, YYYY')}${withPrices ? ' - ' : ''}`}
          {withPrices && <CurrencyText value={paymentAmount} />}
        </Text>
      ))}
    </Stack>
  );
};

export default PaymentPlanList;
