import { useSelector, shallowEqual } from 'react-redux';

const useSelectAccountDetails = () =>
  useSelector(
    ({ accounts: { accountSelected } }) => ({
      originalCreditor: accountSelected?.clientName,
      originalBalance: accountSelected?.originalBalance,
      remainingBalance: accountSelected?.remainingBalance,
      referenceNumber: accountSelected?.accountId,
      accountNumber: accountSelected?.originalAccountNumber,
      openedDate: accountSelected?.accountOpenedDate,
      currentCreditorName: accountSelected?.currentCreditorName,
      solDisclosureId: accountSelected?.solDisclosureID,
      vehicleMake: accountSelected?.vehicleMake,
      vehicleModel: accountSelected?.vehicleModel,
      vehicleYear: accountSelected?.vehicleYear,
      vin: accountSelected?.vin,
      cardStoreBrand: accountSelected?.cardStoreBrand
    }),
    shallowEqual
  );

export default useSelectAccountDetails;
