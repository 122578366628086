import React from 'react';
import dayjs from 'dayjs';
import { Flex, Text, Grid } from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import { number, func, string, shape } from 'prop-types';
import { generateMonths } from 'utils/helpers';
import { messages } from 'pages/SettlementOfferPage/translations';
import MonthButton from '../MonthButton';
import DayButton from '../DayButton';

const MonthDaySelection = ({
  selectedMonth,
  selectedDay,
  handleMonthClick,
  handleDayClick,
  expiryDate,
  amountOfMonths,
  title
}) => {
  const intl = useIntl();

  if (title === undefined) {
    title = intl.formatMessage(messages.selectMonthAndDay);
  }

  const isDisabledDay = day =>
    (selectedMonth?.month === dayjs().month() &&
      selectedMonth?.year === dayjs().year() &&
      day < dayjs().date()) ||
    (selectedMonth?.month === dayjs(expiryDate).month() &&
      selectedMonth?.year === dayjs(expiryDate).year() &&
      day > dayjs(expiryDate).date());

  return (
    <Flex
      alignItems="center"
      direction="column"
      backgroundColor="white"
      borderRadius="12px"
      w="full"
      py={6}
      px={4}
      mt={2}
    >
      <Text
        textAlign="center"
        fontWeight={700}
        fontSize={{ base: 'lg', md: 'xl' }}
        color="gray.600"
        mb={4}
      >
        {title}
      </Text>
      <Text fontSize="sm" color="alpha.500" mb={2}>
        {intl.formatMessage(messages.selectMonth)}
      </Text>
      <Flex
        flexWrap="wrap"
        width="90%"
        justifyContent="center"
        alignItems="center"
        paddingX={{ base: 4, md: 10 }}
        placeContent="center"
      >
        {generateMonths(amountOfMonths)?.map(month => {
          return (
            <MonthButton
              key={month?.month}
              month={month?.month}
              selected={
                month?.month === selectedMonth?.month && month?.year === selectedMonth?.year
              }
              onClick={() => handleMonthClick(month)}
            />
          );
        })}
      </Flex>
      <Text fontSize="sm" color="alpha.500" mb={2} mt={6}>
        {intl.formatMessage(messages.selectDay)}
      </Text>
      <Grid gridTemplateColumns="repeat(7, 1fr)" gridTemplateRows="repeat(4, 1fr)" gridGap={2}>
        {[...Array(28).keys()].map(option => (
          <DayButton
            key={option + 1}
            day={option + 1}
            selected={option + 1 === selectedDay}
            onClick={isDisabledDay(option + 1) ? () => {} : () => handleDayClick(option + 1)}
            disabled={isDisabledDay(option + 1)}
          />
        ))}
      </Grid>
    </Flex>
  );
};

MonthDaySelection.propTypes = {
  selectedMonth: shape({ month: number, year: number }),
  selectedDay: number,
  handleMonthClick: func,
  handleDayClick: func,
  expiryDate: string,
  amountOfMonths: number,
  title: string
};

export default MonthDaySelection;
