import React, { memo } from 'react';
import { string, func, object, instanceOf, number, bool, oneOfType } from 'prop-types';
import cn from 'classnames';
import DatePicker from 'react-datepicker';
import MaskedTextInput from 'react-text-mask';
import { FormattedMessage } from 'react-intl';

import CalendarIcon from 'assets/icons/calendar.svg';

const DatePickerInput = ({
  label,
  value,
  placeholder,
  onSelectDate,
  error,
  format,
  onCalendarClose = () => {},
  minDate,
  maxDate,
  monthsShown = 1,
  className = '',
  showCalendarIcon = false
}) => (
  <div className="date-picker-component" id="date-picker">
    {label && (
      <label className="date-picker-component__label" htmlFor={label}>
        {label}
      </label>
    )}
    <div className="date-picker-component-container">
      <DatePicker
        ariaLabelledBy="date-picker"
        dateFormat={format}
        className={cn(
          'date-picker-component-container-content',
          {
            'date-picker-component-container-content--error': error
          },
          className
        )}
        selected={value}
        onChange={onSelectDate}
        onCalendarClose={onCalendarClose}
        placeholderText={placeholder}
        customInput={
          <MaskedTextInput
            type="text"
            guide={false}
            mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
          />
        }
        minDate={minDate}
        maxDate={maxDate}
        monthsShown={monthsShown}
      />
      {showCalendarIcon && <CalendarIcon className="date-picker-icon" />}
      {error && (
        <span className="span--error">
          <FormattedMessage id={error} defaultMessage={error} />
        </span>
      )}
    </div>
  </div>
);

DatePickerInput.propTypes = {
  value: object,
  error: oneOfType([string, bool]),
  label: string,
  className: string,
  onSelectDate: func.isRequired,
  onCalendarClose: func,
  placeholder: string,
  format: string,
  minDate: instanceOf(Date),
  maxDate: instanceOf(Date),
  monthsShown: number,
  showCalendarIcon: bool
};

export default memo(DatePickerInput);
