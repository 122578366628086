import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Text,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalCloseButton,
  ModalHeader,
  ModalContent,
  Button,
  Flex
} from '@chakra-ui/react';
import { messages } from './translations';

const ModalDisclosure = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const intl = useIntl();

  return (
    <>
      <Text
        onClick={() => setIsModalOpen(true)}
        textDecoration="underline"
        textAlign="center"
        mt={4}
        fontSize="md"
        fontWeight={700}
        color="beta.500"
        cursor="pointer"
        w="fit-content"
      >
        {intl.formatMessage(messages.disclosureTitle)}
      </Text>

      <Modal size="xl" isCentered isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalOverlay />
        <ModalContent padding={8}>
          <ModalHeader py={2}>{intl.formatMessage(messages.disclosureTitle)}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex direction="column">
              <Text mt={1} fontSize="md" color="alpha.500">
                {intl.formatMessage(messages.disclosureBody)}
              </Text>
              <Button
                variant="successBase"
                size="lg"
                onClick={() => setIsModalOpen(false)}
                alignSelf="flex-end"
                mt={6}
              >
                <Text fontSize="md" color="white">
                  {intl.formatMessage(messages.gotIt)}
                </Text>
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalDisclosure;
