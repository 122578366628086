import { useSelector, shallowEqual } from 'react-redux';

const useSelectSettlementUrl = () =>
  useSelector(
    ({ settlementOffer: { settlementUrl } }) => ({
      settlementUrl
    }),
    shallowEqual
  );

export default useSelectSettlementUrl;
