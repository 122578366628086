const global = {
  svg: {
    display: 'inline'
  },
  '*, *::before, &::after': {
    wordWrap: 'inherit'
  },
  body: {
    minHeight: 'initial'
  }
};

export default global;
