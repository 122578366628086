import { useEffect, useCallback } from 'react';
import mixpanel from 'mixpanel-browser';

export default (event, options = {}) => {
  const trackEvent = useCallback((event, options) => mixpanel.track(event, options), []);

  useEffect(() => {
    event && trackEvent(event, options);
  }, [event, options, trackEvent]);

  return {
    trackEvent
  };
};
