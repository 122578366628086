export const activeExperiments = {
  paymentMethodDisclosures: {
    name: 'paymentMethodDisclosures',
    variants: [
      {
        name: 'No disclosure ',
        ratio: 0.33
      },
      {
        name: 'Text disclosure',
        ratio: 0.33
      },
      {
        name: 'Modal disclosure',
        ratio: 0.33
      }
    ]
  }
};

// Discarded experiments or experiments that are not being used right now

export const inactiveExperiments = {};
