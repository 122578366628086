import React, { useEffect, useMemo, useRef } from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { useHistory } from 'react-router-dom';
import ReactToPrint from 'react-to-print';

import { ContentContainer } from 'components/common';
import SuccessIcon from 'assets/icons/successIcon.svg';
import LogoIcon from 'assets/icons/logoIcon.svg';
import PrintIcon from '@mui/icons-material/PrintOutlined';
import { PAYMENT_METHOD_OPTIONS } from 'constants/constants';
import { PAYMENT_AUTHORIZATION } from 'constants/disclosures';
import { resetRepaymentOption } from 'state/actions/paymentPlanActions';
import routes from 'constants/routesPaths';
import {
  useSelectAccountDetails,
  useSelectSelectedPlan,
  useSelectPaymentMethod,
  useSelectPaymentInfo,
  useDispatch,
  useLiveChat
} from 'hooks';
import PaymentPlanList from 'components/paymentPlans/PaymentPlanList';
import { Button, Flex, Heading, Stack, StackDivider, Text } from '@chakra-ui/react';
import { successScreenPaymentMethodDecorator } from 'utils/decorators/addPaymentMethodToPromise';
import { useSelector } from 'react-redux';
import { getNewPlan } from 'state/reducers/paymentPlanReducer';

const AddPaymentMethodSuccess = () => {
  const intl = useIntl();
  const { referenceNumber } = useSelectAccountDetails();
  const { selectedPaymentMethod } = useSelectPaymentMethod();
  const { last4, cardType, routingNumber } = useSelectPaymentInfo();
  const history = useHistory();
  const componentRef = useRef();
  const resetSelectedRepaymentOption = useDispatch(resetRepaymentOption);
  const newPlan = useSelector(getNewPlan);

  const { selectedPlan } = useSelectSelectedPlan();

  useLiveChat();

  const messages = defineMessages({
    bankAccount: { id: 'paymentMethod.bankMinified' },
    endingIn: { id: 'confirmPage.endingIn' },
    paymentMethod: { id: 'confirmPage.paymentMethod' },
    transactionId: { id: 'viewPaymentConfirmation.transactionId' },
    originalCreditor: { id: 'viewPaymentConfirmation.originalCreditor' },
    referenceNumber: { id: 'viewPaymentConfirmation.referenceNumber' },
    routingNumber: { id: 'viewPaymentConfirmation.routingNumber' },
    frequency: { id: 'viewPaymentConfirmation.frequency' },
    every: { id: 'viewPaymentConfirmation.every' },
    ofTheMonth: { id: 'paymentFrequency.ofTheMonth' },
    backToHome: { id: 'viewPaymentConfirmation.backToHome' }
  });

  const paymentMethodDecoration = successScreenPaymentMethodDecorator({
    selectedPaymentMethod,
    last4,
    bankAccount: intl.formatMessage(messages.bankAccount),
    cardType
  });

  const messageSuccess = useMemo(
    () =>
      intl.formatMessage({
        id: 'viewAddPaymentMethodConfirmation.addPaymentMethod.messageSuccess'
      }),
    [intl]
  );

  const paymentSuccess = useMemo(
    () =>
      intl.formatMessage({
        id: 'viewAddPaymentMethodConfirmation.addPaymentMethod.addPaymentMethodSuccess'
      }),
    [intl]
  );

  const paymentLabel = useMemo(
    () =>
      intl.formatMessage({
        id: `viewAddPaymentMethodConfirmation.addPaymentMethod.planLabel`
      }),
    [intl]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      resetSelectedRepaymentOption();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!selectedPlan) history.push(routes.paymentPlans);
  }, [selectedPlan]);

  return (
    <ContentContainer>
      <div className="success-page-container">
        <div ref={componentRef}>
          <LogoIcon className="print-soc-logo" />
          <span className="success-container">
            <div className="success-icon">
              <SuccessIcon />
            </div>
            <Heading fontSize="2rem" textAlign="center" mb={1}>
              {messageSuccess}
            </Heading>
            <Text mb={4} textAlign="center">
              {paymentSuccess}
            </Text>
          </span>
          <Flex
            w={{ base: 'full', md: '30rem' }}
            boxShadow="md"
            borderRadius="md"
            p={6}
            alignItems="center"
            flexDir="column"
          >
            <Stack
              w="full"
              divider={
                <StackDivider
                  borderBottomStyle="solid"
                  borderBottomWidth="0.5px"
                  borderBottomColor="gray.200"
                />
              }
              spacing={2}
            >
              <Flex w="full" justifyContent="space-between">
                <Text pr="2">{intl.formatMessage(messages.transactionId)}</Text>
                <Text fontWeight={800}>{newPlan?.planDetails?.planId}</Text>
              </Flex>
              <Flex w="full" justifyContent="space-between">
                <Text pr="2">{intl.formatMessage(messages.referenceNumber)}</Text>
                <Text fontWeight={800}>{referenceNumber}</Text>
              </Flex>
              <Flex w="full" justifyContent="space-between">
                <Text pr="2">{paymentLabel}</Text>
                <PaymentPlanList
                  paymentSchedule={selectedPlan.paymentSchedule}
                  textListProps={{ fontWeight: 800 }}
                  withPrices
                />
              </Flex>
              {selectedPaymentMethod === PAYMENT_METHOD_OPTIONS.bank && (
                <Flex w="full" justifyContent="space-between">
                  <Text pr="2">{intl.formatMessage(messages.routingNumber)}</Text>
                  <Text fontWeight={800}>{routingNumber}</Text>
                </Flex>
              )}
              <Flex w="full" justifyContent="space-between">
                <Text pr="2">{paymentMethodDecoration.paymentMethod}</Text>
                <Text fontWeight={800}>{paymentMethodDecoration.paymentMethodDescription}</Text>
              </Flex>
            </Stack>
          </Flex>
          <section className="payment-authorization-container">
            <span className="payment-authorization">
              {PAYMENT_METHOD_OPTIONS.card === selectedPaymentMethod
                ? PAYMENT_AUTHORIZATION.card
                : PAYMENT_AUTHORIZATION.ACH}
              {PAYMENT_AUTHORIZATION.print}
            </span>
          </section>
        </div>
        <Stack w="15rem" mt="2rem" spacing="1rem">
          <ReactToPrint
            trigger={() => (
              <Button w="full" leftIcon={<PrintIcon />} variant="primaryLink" isFullWidth>
                {intl.formatMessage({ id: 'viewPaymentConfirmation.printReceipt' })}
              </Button>
            )}
            content={() => componentRef.current}
          />
          <Button
            isFullWidth
            variant="primaryOutline"
            onClick={() => history.push(routes.index)}
            ariaLabel={intl.formatMessage(messages.backToHome)}
          >
            {intl.formatMessage(messages.backToHome)}
          </Button>
        </Stack>
      </div>
    </ContentContainer>
  );
};

export default AddPaymentMethodSuccess;
