import { useSelector, shallowEqual } from 'react-redux';

const useSelectSelectedPlan = () =>
  useSelector(
    ({
      paymentPlan: {
        selectedPlan,
        selectedDate,
        selectedFrequency,
        selectedRepaymentOption,
        selectedAmount,
        planSummary
      }
    }) => ({
      selectedRepaymentOption,
      selectedPlan,
      selectedDate,
      selectedFrequency,
      selectedAmount,
      planSummary
    }),
    shallowEqual
  );

export default useSelectSelectedPlan;
