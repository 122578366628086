const typography = {
  fonts: {
    heading: 'Inter',
    body: 'Inter'
  },
  fontWeights: {
    normal: 400,
    bold: 700
  }
};

export default typography;
