import { useSelectAccountId } from 'hooks';
import {
  addPaymentMethodToPromise,
  cleanNewPlan,
  cleanPlan,
  getPaymentPlans
} from 'state/actions/paymentPlanActions';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getCurrentSection } from 'state/reducers/sectionReducer';
import { ADD_PAYMENT_METHOD_TO_PROMISE } from 'constants/sections';
import { resetPaymentForm } from 'state/actions/paymentMethodActions';
import ErrorSection from 'components/paymentPlans/ErrorSection';
import { defineMessages, useIntl } from 'react-intl';
import useSectionRouter from 'hooks/sectionRouter';
import PaymentPlanPreview from '../../components/paymentPlans/PaymentPlanPreview';
import AddPaymentMethod from '../../components/paymentPlans/AddPaymentMethod';
import AddPaymentMethodSuccess from '../../components/paymentPlans/AddPaymentMethodSuccess';
import AddPaymentMethodConfirm from '../../components/paymentPlans/AddPaymentMethodConfirm';

const {
  PREVIEW,
  ADD_PAYMENT_METHOD,
  CONFIRM_PAYMENT_METHOD,
  PAYMENT_METHOD_SUCCESS,
  ADD_PAYMENT_METHOD_TO_PROMISE_ERROR
} = ADD_PAYMENT_METHOD_TO_PROMISE;

const PaymentPlansContainer = () => {
  const { clear } = useSectionRouter();
  const intl = useIntl();
  const { accountId } = useSelectAccountId();
  const dispatch = useDispatch();
  const section = useSelector(getCurrentSection);

  const messages = defineMessages({
    addPaymentMethodToPromiseErrorDescription: {
      id: 'addPaymentMethodToPromiseErrorDescription.description'
    }
  });

  useEffect(() => {
    accountId && dispatch(getPaymentPlans(accountId));
  }, [accountId]);

  const resetStatus = () => {
    clear();
    dispatch(cleanNewPlan());
    dispatch(cleanPlan());
    dispatch(addPaymentMethodToPromise.reset());
    dispatch(resetPaymentForm());
  };

  useEffect(() => {
    return resetStatus;
  }, []);

  switch (section) {
    case PREVIEW:
      return <PaymentPlanPreview />;
    case ADD_PAYMENT_METHOD:
      return <AddPaymentMethod />;
    case CONFIRM_PAYMENT_METHOD:
      return <AddPaymentMethodConfirm />;
    case PAYMENT_METHOD_SUCCESS:
      return <AddPaymentMethodSuccess />;
    case ADD_PAYMENT_METHOD_TO_PROMISE_ERROR:
      return (
        <ErrorSection
          description={intl.formatMessage(messages.addPaymentMethodToPromiseErrorDescription)}
          ctaCallback={resetStatus}
        />
      );
    default:
      return null;
  }
};

export default PaymentPlansContainer;
