import React from 'react';
import { string } from 'prop-types';

import { black } from 'styles/_variables.scss';

const ArrowIcon = ({ color = black }) => (
  <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.44838e-06 9.99999L0.823987 10.8478L9.73696 20L12 18.3044L3.91103 9.99999L12 1.69561L9.73696 -9.421e-08L0.823988 9.1522L1.44838e-06 9.99999Z"
      fill={color}
    />
  </svg>
);

ArrowIcon.propTypes = {
  color: string
};

export default ArrowIcon;
