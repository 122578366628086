// App common constants
import { getOrdinal } from 'utils/helpers';

export const SUPPORTED_LANGUAGES = ['en'];
export const DEFAULT_LANGUAGE = 'en';
export const SOC_WEB_URL = 'https://springoakscapital.com/';
export const IFRAME_LOADER_FORMAT = 'Oval';
export const IFRAME_LOADER_SIZE = 40;
export const TELEPHONE_CONTACT = '866-539-7525';
export const NULL_CREDITOR = 'Not available at this time.';

export const PAYMENT_FREQUENCY = {
  weekly: 'paymentFrequency.weekly',
  biweekly: 'paymentFrequency.biweekly',
  monthly: 'paymentFrequency.monthly'
};

export const DAYS_WEEK = {
  monday: 'days.monday',
  tuesday: 'days.tuesday',
  wednesday: 'days.wednesday',
  thursday: 'days.thursday',
  friday: 'days.friday',
  saturday: 'days.saturday',
  sunday: 'days.sunday'
};

export const PLAN_FREQUENCY = {
  weekly: 4,
  biweekly: 2,
  monthly: 1
};

export const getSelectDays = () => {
  const daysArray = [...Array(28).keys()];
  const daysOptions = [];
  daysArray.map(day => daysOptions.push(getOrdinal(day + 1)));
  daysOptions.push('Last day');

  return daysOptions;
};

export const REPAYMENT_OPTIONS = {
  createPlan: 'schedule',
  partialPayment: 'partial',
  payInFull: 'PIF'
};

export const SETTLEMENT_OPTIONS = {
  createPlan: 'settlementSchedule',
  settleInFull: 'SIF'
};

export const SETTLEMENT_OPTIONS_LABEL = {
  [SETTLEMENT_OPTIONS.createPlan]: 'planType.paymentPlan',
  [SETTLEMENT_OPTIONS.settleInFull]: 'planType.payInFull'
};

export const REPAYMENT_OPTIONS_LABEL = {
  [REPAYMENT_OPTIONS.createPlan]: 'planType.paymentPlan',
  [REPAYMENT_OPTIONS.partialPayment]: 'planType.partialPayment',
  [REPAYMENT_OPTIONS.payInFull]: 'planType.payInFull'
};

export const CONFIRMATION_OPTIONS_ID = {
  [REPAYMENT_OPTIONS.createPlan]: 'viewPaymentConfirmation.createPlan',
  [REPAYMENT_OPTIONS.partialPayment]: 'viewPaymentConfirmation.partialPayment',
  [REPAYMENT_OPTIONS.payInFull]: 'viewPaymentConfirmation.payInFull'
};

export const SETTLEMENT_CONFIRMATION_OPTIONS_ID = {
  [SETTLEMENT_OPTIONS.createPlan]: 'viewPaymentConfirmation.createPlan',
  [SETTLEMENT_OPTIONS.settleInFull]: 'viewPaymentConfirmation.payInFull'
};

export const PARTIAL_PAYMENTS_OPTIONS = [10, 25, 50, 75, 100];

export const PAYMENT_STATUS = {
  success: 'PAYMENT_SUCCESS',
  error: 'PAYMENT_ERROR'
};

export const PAYMENT_METHOD_OPTIONS = {
  card: 'card',
  bank: 'ach'
};

export const DEFAULT_PLAN = {
  planTerm: 1,
  planScheduleFrequency: 1
};

export const PAYMENT_DATES_MEDIA_QUERIES = {
  xlDesktop: {
    breakpoint: { max: 9999, min: 2000 },
    items: 7
  },
  lgDesktop: {
    breakpoint: { max: 2000, min: 1200 },
    items: 6
  },
  desktop: {
    breakpoint: { max: 1200, min: 950 },
    items: 5
  },
  lgTablet: {
    breakpoint: { max: 950, min: 850 },
    items: 4
  },
  tablet: {
    breakpoint: { max: 850, min: 767 },
    items: 3
  },
  lgMobile: {
    breakpoint: { max: 767, min: 500 },
    items: 4,
    partialVisibilityGutter: 10
  },
  mdMobile: {
    breakpoint: { max: 500, min: 400 },
    items: 3,
    partialVisibilityGutter: 10
  },
  smMobile: {
    breakpoint: { max: 400, min: 0 },
    items: 2,
    partialVisibilityGutter: 20
  }
};

export const PLAN_PAYMENT_STATUS = {
  pending: 'PENDING',
  paid: 'PAID',
  failure: 'FAILURE'
};

export const PAYMENT_PLAN_ERROR_CODE = {
  RepayContactException: '530',
  RepayDeclinedPaymentException: '540'
};
