import React from 'react';
import { useIntl } from 'react-intl';
import { bool, func, oneOf } from 'prop-types';

import { TileButton } from 'components/common';
import { PAYMENT_FREQUENCY } from 'constants/constants';
import WeeklyIcon from 'assets/icons/weekly.svg';
import BiweeklyIcon from 'assets/icons/biweekly.svg';
import MonthlyIcon from 'assets/icons/monthly.svg';

const PaymentFrequency = ({
  showWeeklyOptions,
  showBiweeklyOptions,
  showMonthlyOptions,
  onFrequencySelected,
  selectedFrequency = PAYMENT_FREQUENCY.weekly
}) => {
  const intl = useIntl();

  const getOptionsSettings = value => {
    switch (value) {
      case PAYMENT_FREQUENCY.biweekly:
        return {
          show: showBiweeklyOptions,
          icon: <BiweeklyIcon />,
          aria: 'paymentFrequency.ariaBiweekly'
        };
      case PAYMENT_FREQUENCY.monthly:
        return {
          show: showMonthlyOptions,
          icon: <MonthlyIcon />,
          aria: 'paymentFrequency.ariaMonthly'
        };
      case PAYMENT_FREQUENCY.weekly:
      default:
        return {
          show: showWeeklyOptions,
          icon: <WeeklyIcon />,
          aria: 'paymentFrequency.ariaWeekly'
        };
    }
  };

  return (
    <div className="tile-button-container">
      {Object.keys(PAYMENT_FREQUENCY).map(key => {
        const frequency = PAYMENT_FREQUENCY[key];
        const { show, icon, aria } = getOptionsSettings(frequency);

        return (
          show && (
            <TileButton
              label={intl.formatMessage({ id: frequency })}
              ariaLabel={intl.formatMessage({ id: aria })}
              onClick={() => onFrequencySelected(frequency)}
              active={frequency === selectedFrequency}
              icon={icon}
              key={key}
            />
          )
        );
      })}
    </div>
  );
};

PaymentFrequency.propTypes = {
  showWeeklyOptions: bool.isRequired,
  showBiweeklyOptions: bool.isRequired,
  showMonthlyOptions: bool.isRequired,
  onFrequencySelected: func.isRequired,
  selectedFrequency: oneOf([
    PAYMENT_FREQUENCY.weekly,
    PAYMENT_FREQUENCY.biweekly,
    PAYMENT_FREQUENCY.monthly
  ])
};

export default PaymentFrequency;
