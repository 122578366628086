import { useSelector, shallowEqual } from 'react-redux';

const useSelectSettlementOptionSelected = () =>
  useSelector(
    ({ settlementOffer: { selectedSettlementOption } }) => ({
      selectedSettlementOption
    }),
    shallowEqual
  );

export default useSelectSettlementOptionSelected;
