import { useSelector, shallowEqual } from 'react-redux';

const useSelectNewPlanId = () =>
  useSelector(
    ({ settlementOffer: { newPlan } }) => ({
      planId: newPlan?.planId
    }),
    shallowEqual
  );

export default useSelectNewPlanId;
