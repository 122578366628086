import routes from 'constants/routesPaths';
import { MIXPANEL_LOCATIONS } from 'constants/mixpanelEvents';

export const mixPanelLocation = location => {
  const { index, disclosures, homeDisclosures, login, accountError } = routes;
  switch (location) {
    case index:
      return { Location: MIXPANEL_LOCATIONS.home };
    case disclosures:
    case homeDisclosures:
      return { Location: MIXPANEL_LOCATIONS.disclosures };
    case login:
      return { Location: MIXPANEL_LOCATIONS.login };
    case accountError:
      return { Location: MIXPANEL_LOCATIONS.accountError };
    default:
      return { Location: MIXPANEL_LOCATIONS.home };
  }
};
