const URL = process.env.LV_CHAT_URL;
const UUID = process.env.LV_CHAT_UUID;
const WIDGET_ID = process.env.LV_CHAT_WIDGET_ID;
const WIDGET_ICON = process.env.LV_CHAT_WIDGET_ICON;

export const lvWebWidget = `
    LVWebWidget.render({            
        url: '${URL}',            
        uuid: '${UUID}',    
        launcherIcon: '${WIDGET_ICON}',        
        widgetId: ${WIDGET_ID}
    });`;
