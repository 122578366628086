import React from 'react';
import dayjs from 'dayjs';
import { useIntl } from 'react-intl';
import { string, func } from 'prop-types';
import { Flex, Text } from '@chakra-ui/react';
import { daysBetween } from 'utils/helpers';
import { messages } from 'pages/SettlementOfferPage/translations';
import { DatePickerInput } from 'components/common';
import { DATEPICKER_DATE_FORMAT } from 'utils/dateHelpers';

const PayInFullPicker = ({ expiryDate, selectedDate, setDate }) => {
  const intl = useIntl();

  return (
    <>
      <Text fontWeight={700} fontSize={{ base: 'lg', md: 'xl' }} color="gray.600" mb={0.5}>
        {intl.formatMessage(messages.setUpPayment)}
      </Text>
      <Text textAlign="center" fontSize="md" color="gray.600" mb={10}>
        {intl.formatMessage(messages.paymentDateQuestion)}
      </Text>
      <Flex direction="column" position="relative" maxW="400px" w="100%">
        <DatePickerInput
          format={DATEPICKER_DATE_FORMAT}
          label={intl.formatMessage({ id: 'setupPayment.selectDateLabel' })}
          placeholder=""
          minDate={dayjs().toDate()}
          maxDate={dayjs()
            .add(daysBetween(expiryDate) + 1, 'day')
            .toDate()}
          value={selectedDate}
          onSelectDate={date => setDate(date)}
          showCalendarIcon
          error={!selectedDate && 'setupPayment.selectDateError'}
        />
        <Text fontSize="sm" color="delta.600" mt={-2}>
          {intl.formatMessage(messages.daysDisclaimerBegin)} {daysBetween(expiryDate)}{' '}
          {intl.formatMessage(messages.daysDisclaimerEnd)}
        </Text>
      </Flex>
    </>
  );
};

PayInFullPicker.propTypes = {
  expiryDate: string,
  selectedDate: string,
  setDate: func
};

export default PayInFullPicker;
