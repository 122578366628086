import React, { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash/isEmpty';

import { Pane, ContentContainer, Loading } from 'components/common';
import AccountDetails from 'components/accounts/AccountDetails';
import PaymentCTA from 'components/paymentPlans/PaymentCTA';
import CancelPlan from 'components/paymentPlans/CancelPlan';
import PaymentsOverview from 'components/paymentsOverview';

import {
  useSelectHolderName,
  useSelectPaymentPlans,
  useSelectAccountId,
  useSelectAccountDetails,
  useDispatch,
  useStatus,
  useSelectPaymentHistory,
  useLiveChat
} from 'hooks';
import { getPaymentPlans, getPaymentHistory } from 'state/actions/paymentPlanActions';
import { account } from 'state/actions/userActions';
import { FULFILLED, PENDING } from 'constants/actionStatusConstants';

const HomePage = () => {
  const intl = useIntl();
  const { firstName } = useSelectHolderName();
  const { accountId } = useSelectAccountId();
  const { hasPaymentPlans } = useSelectPaymentPlans();
  const { paymentHistory, hasPaymentHistory } = useSelectPaymentHistory();
  const { remainingBalance } = useSelectAccountDetails();
  const accountRequest = useDispatch(account);
  const paymentPlansRequest = useDispatch(getPaymentPlans);
  const paymentHistoryRequest = useDispatch(getPaymentHistory);
  const { statusHistory } = useStatus(getPaymentHistory);
  const { status: statusPaymentPlans } = useStatus(getPaymentPlans);
  const { status: statusAccount } = useStatus(account);

  const showCancelPlan = useMemo(
    () => statusPaymentPlans === FULFILLED && (hasPaymentPlans || hasPaymentHistory),
    [statusPaymentPlans, hasPaymentPlans, hasPaymentHistory]
  );

  useEffect(() => {
    accountId && accountRequest([accountId]) && paymentPlansRequest(accountId);
  }, [accountId, hasPaymentPlans, paymentPlansRequest, accountRequest]);

  useEffect(() => {
    ![FULFILLED, PENDING].includes(statusHistory) && paymentHistoryRequest(accountId);
  }, [accountId, statusHistory]); // eslint-disable-line react-hooks/exhaustive-deps

  useLiveChat();

  return (
    <ContentContainer>
      {statusPaymentPlans === PENDING || statusAccount === PENDING ? (
        <div className="d-flex h-100">
          <Loading />
        </div>
      ) : (
        <div className="homepage-container">
          <h1 className="home-welcome">{`Welcome ${firstName}!`}</h1>
          {statusAccount === FULFILLED && (
            <Pane className="mb-4" wide>
              <AccountDetails />
            </Pane>
          )}
          {statusPaymentPlans === FULFILLED && !isEmpty(paymentHistory) && (
            <PaymentsOverview maxItems={2} payments={paymentHistory} showMoreCTA />
          )}
          {remainingBalance > 0 && (
            <PaymentCTA buttonTitle={intl.formatMessage({ id: 'paymentOptionsBlock.cta' })} />
          )}
          {showCancelPlan && <CancelPlan />}
        </div>
      )}
    </ContentContainer>
  );
};

export default HomePage;
