import React, { useEffect } from 'react';
import { string } from 'prop-types';
import cn from 'classnames';
import useDropdownMenu from 'react-accessible-dropdown-menu-hook';
import { useHistory } from 'react-router-dom';

import { Arrow } from 'components/common';
import { account, toggleAccounts, setAccountSelectedId } from 'state/actions/userActions';
import { resetRepaymentOption } from 'state/actions/paymentPlanActions';
import {
  useDispatch,
  useSelectAccountOptions,
  useBreakpoint,
  useSelectAccountSelected,
  useSelectAccountId
} from 'hooks';
import routes from 'constants/routesPaths';
import { sm } from 'styles/_variables.scss';

const SelectAccount = ({ className = '', ...props }) => {
  const { accountOptions } = useSelectAccountOptions();
  const isDesktop = useBreakpoint(sm);
  const history = useHistory();
  const { accountSelected } = useSelectAccountSelected();
  const { accountId: accountIdSelected } = useSelectAccountId();
  const accountRequest = useDispatch(account);
  const resetSelectedRepaymentOption = useDispatch(resetRepaymentOption);
  const toggleAccountsSelect = useDispatch(toggleAccounts);
  const setAccountId = useDispatch(setAccountSelectedId);

  const {
    buttonProps,
    itemProps: [itemProps],
    isOpen,
    setIsOpen
  } = useDropdownMenu(accountOptions?.length);

  const selectOption = accountId => {
    setIsOpen(false);
    if (accountId !== accountIdSelected) {
      setAccountId(accountId);
      accountRequest([accountId]);
      resetSelectedRepaymentOption();
      history.push(routes.index);
    }
  };

  useEffect(() => {
    toggleAccountsSelect(isOpen);
  }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={cn(className, 'select-account')}>
      <button
        className="select-account-container"
        type="button"
        onClick={() => setIsOpen(true)}
        {...buttonProps}
        {...props}
      >
        {accountSelected?.clientName && (
          <>
            <div className="select-account-container-name">{accountSelected.clientName}</div>
            {isDesktop && (
              <>
                <div className="mx-1">-</div>
                {accountSelected?.accountId}
              </>
            )}
            <Arrow className="ml-3" />
          </>
        )}
      </button>

      <div className={cn('select-account-options', isOpen ? 'd-flex' : 'd-none')} role="menu">
        {accountOptions.map(({ accountId, accountName }) => {
          const onSelected = () => selectOption(accountId);

          return (
            <span
              key={accountId}
              {...itemProps}
              role="menuitem"
              onClick={onSelected}
              onKeyDown={onSelected}
              tabIndex={0}
              className="select-account-options-item"
            >
              <div className={cn({ active: accountSelected?.clientName === accountName })}>
                {accountName}
              </div>
              {accountId}
            </span>
          );
        })}
      </div>
    </div>
  );
};

SelectAccount.propTypes = {
  className: string
};

export default SelectAccount;
