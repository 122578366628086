import { useSelector, shallowEqual } from 'react-redux';

const useSelectAccountSelected = () =>
  useSelector(
    ({ accounts: { accountSelected } }) => ({
      accountSelected
    }),
    shallowEqual
  );

export default useSelectAccountSelected;
