import { useSelector, shallowEqual } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

const useSelectPaymentHistory = () =>
  useSelector(
    ({ paymentPlan: { paymentHistory } }) => ({
      paymentHistory,
      hasPaymentHistory: !isEmpty(paymentHistory)
    }),
    shallowEqual
  );

export default useSelectPaymentHistory;
