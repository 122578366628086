import { useSelector, shallowEqual } from 'react-redux';

const useSelectSettlementDate = () =>
  useSelector(
    ({ settlementOffer: { selectedDate } }) => ({
      selectedDate
    }),
    shallowEqual
  );

export default useSelectSettlementDate;
