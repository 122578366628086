import axios from 'axios';
import httpClient from 'httpClient';

class PaymentPlanService {
  static repaymentOptions(accountId) {
    return httpClient.post('/repayment-options', { accountId });
  }

  static getPaymentPlans(accountId) {
    return httpClient.post('/payment-plans', { accountId });
  }

  static getPaymentHistory(accountId) {
    return httpClient.post('/payment-history', { accountId });
  }

  static createPlan(plan) {
    return httpClient.post('/payment-plan', plan);
  }

  static getPlanPaymentDates(planScheduleDetails) {
    return httpClient.post('/payment-schedule', planScheduleDetails);
  }

  static sendProofOfAuth(data) {
    return httpClient.post('/ach-proof-of-auth', data);
  }

  static getIPv4() {
    return axios.get(process.env.IPV4_ENDPOINT);
  }

  static addPaymentMethodToPromise(payload) {
    return httpClient.post('/payment-plan-schedule', payload);
  }

  static getSpecialPaymentPlan(payload) {
    return httpClient.post('/special-payment-plan', payload);
  }
}

export default PaymentPlanService;
