import React from 'react';
import { useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import cn from 'classnames';
import { string } from 'prop-types';

const SideMenuOption = ({ titleId, ariaLabel, route }) => {
  const intl = useIntl();
  const { pathname } = useLocation();

  return (
    <Link
      aria-label={intl.formatMessage({ id: ariaLabel })}
      className={cn('menu-option', { active: pathname === route })}
      to={route}
    >
      {intl.formatMessage({ id: titleId })}
    </Link>
  );
};

SideMenuOption.propTypes = {
  ariaLabel: string.isRequired,
  titleId: string.isRequired,
  route: string.isRequired
};

export default SideMenuOption;
