import React, { useEffect, memo } from 'react';
import { string, func } from 'prop-types';

import { Arrow, Button } from 'components/common';
import { white } from 'styles/_variables.scss';
import { accounts } from 'state/actions/userActions';
import { useDispatch, useSelectBannerInfo, useSelectAccountId } from 'hooks';

const TransactionalHeader = ({ ariaLabel, title, backToCallback }) => {
  const getAccountDetails = useDispatch(accounts);
  const { accountId } = useSelectAccountId();
  const { referenceNumber } = useSelectBannerInfo();

  useEffect(() => {
    !referenceNumber && getAccountDetails([accountId]);
  }, [referenceNumber, accountId, getAccountDetails]);

  return (
    <section className="back-banner">
      <div className="container">
        <Button
          className="back-button"
          ariaLabel={ariaLabel}
          title={title}
          onClick={backToCallback}
        >
          <Arrow type="left" className="mr-3" color={white} />
          {title}
        </Button>
      </div>
    </section>
  );
};

TransactionalHeader.propTypes = {
  ariaLabel: string.isRequired,
  title: string.isRequired,
  backToCallback: func.isRequired
};

export default memo(TransactionalHeader);
