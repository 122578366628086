import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { Link, useLocation } from 'react-router-dom';
import { func, bool } from 'prop-types';
import cn from 'classnames';

import { Button } from 'components/common';
import SelectAccount from 'components/accounts/SelectAccount';
import HamburgerIcon from 'assets/icons/hamburgerIcon.svg';
import LogoIcon from 'assets/icons/logoIcon.svg';
import LogoMinIcon from 'assets/icons/logoMinIcon.svg';
import {
  useSelectAccountOptions,
  useSelectAccountSelected,
  useBreakpoint,
  useAnalytics
} from 'hooks';
import routes from 'constants/routesPaths';
import { MIXPANEL_EVENTS } from 'constants/mixpanelEvents';
import { mixPanelLocation } from 'utils/mixPanelLocation';
import { md } from 'styles/_variables.scss';

const Header = ({ onMenuClick = () => {}, showMenu = false, privateRoute = false }) => {
  const location = useLocation();
  const { trackEvent } = useAnalytics();
  const { accountOptions } = useSelectAccountOptions();
  const { accountSelected } = useSelectAccountSelected();

  const showSelectAccount =
    (privateRoute || location.pathname === routes.accountError) &&
    accountOptions?.length > 1 &&
    !isEmpty(accountSelected);
  const isDesktop = useBreakpoint(md);
  const showLogoMinIcon = !isDesktop && !isEmpty(accountSelected) && accountOptions?.length > 1;

  return (
    <header className="header">
      <div className="px-0">
        <div className="row no-gutters justify-content-start align-items-center">
          {showMenu && (
            <Button
              className="button--icon mr-1 mr-sm-3 d-block d-sm-block d-md-none"
              ariaLabel="Open Menu"
              onClick={onMenuClick}
            >
              <HamburgerIcon />
            </Button>
          )}
          <Link
            className={cn('soc-logo', { 'soc-logo-min': showLogoMinIcon })}
            aria-label="Go to Home Page"
            to={routes.index}
            onClick={() =>
              trackEvent(MIXPANEL_EVENTS.clickHeaderLogo, mixPanelLocation(location.pathname))
            }
          >
            {showLogoMinIcon ? <LogoMinIcon /> : <LogoIcon />}
          </Link>
          {showSelectAccount && <SelectAccount />}
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  onMenuClick: func,
  showMenu: bool,
  privateRoute: bool
};

export default Header;
