import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { shape, number } from 'prop-types';

import { Pane, CurrencyText, ProgressBar } from 'components/common';
import { useSelectAccountDetails, useAnalytics, useDispatch } from 'hooks';
import { MIXPANEL_EVENTS } from 'constants/mixpanelEvents';
import { Button, Divider, Flex, Text } from '@chakra-ui/react';
import { selectPlan } from 'state/actions/paymentPlanActions';
import PaymentPlanCarousel from './PaymentPlanCarousel';

const PaymentPlan = ({ paymentPlan, addPaymentMethodOnClick }) => {
  const intl = useIntl();
  const { originalCreditor } = useSelectAccountDetails();
  const setSelectedPlan = useDispatch(selectPlan);

  const { trackEvent } = useAnalytics();

  useEffect(() => {
    trackEvent(MIXPANEL_EVENTS.viewPaymentPlansPage);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleAddPaymentMethodClick = () => {
    setSelectedPlan(paymentPlan);
    addPaymentMethodOnClick();
  };

  return (
    <Pane className="mb-4 mb-md-5 p-4 p-lg-5" wide>
      <div className="payment-plan-component">
        <Flex justifyContent={{ base: 'center', lg: 'left' }}>
          <Text mb={{ lg: 0, base: 3 }} fontWeight="700">
            {originalCreditor}
          </Text>
        </Flex>
        {paymentPlan.isPromise && (
          <Flex justifyContent="center" float={{ base: 'none', lg: 'right' }}>
            <Button variant="successBase" onClick={handleAddPaymentMethodClick}>
              {intl.formatMessage({ id: 'paymentPlans.addPaymentMethod' })}
            </Button>
          </Flex>
        )}
        <Divider display={{ base: 'flex', lg: 'none' }} color="gray.400" my="1rem" />
        <div>
          <div className="payment-plan-detail">
            {intl.formatMessage({ id: 'paymentPlans.remainingBalance' })}
            <div className="ml-2 font-weight-bold">
              <CurrencyText value={paymentPlan.remainingBalance} />
            </div>
          </div>

          <div className="payment-plan-detail">
            {intl.formatMessage({ id: 'paymentPlans.installments' })}
            <div className="ml-2 mr-1">{paymentPlan.planLength}</div>
            {paymentPlan.planLength === 1
              ? intl.formatMessage({ id: 'paymentPlans.payment' })
              : intl.formatMessage({ id: 'paymentPlans.payments' })}
          </div>
        </div>

        <ProgressBar
          originalBalance={paymentPlan.originalBalance}
          remainingBalance={paymentPlan.remainingBalance}
        />

        {paymentPlan.paymentSchedule && (
          <PaymentPlanCarousel schedules={paymentPlan.paymentSchedule} />
        )}
      </div>
    </Pane>
  );
};

PaymentPlan.propTypes = {
  paymentPlan: shape({
    originalBalance: number.isRequired,
    remainingBalance: number.isRequired,
    planLength: number.isRequired
  }).isRequired
};

export default PaymentPlan;
