import React from 'react';
import { node, string, bool } from 'prop-types';
import cn from 'classnames';

const Pane = ({ children, className = '', wide = false }) => (
  <section className={cn('pane', className, { wide })}>{children}</section>
);

Pane.propTypes = {
  children: node.isRequired,
  className: string,
  wide: bool
};

export default Pane;
