const breakpoints = {
  sm: '30em',
  bigSm: '40em',
  md: '48em',
  lg: '62em',
  xl: '80em',
  '2xl': '96em'
};

export default breakpoints;
