import { createSlice } from '@reduxjs/toolkit';
import {
  goToSection,
  goToPreviousSection,
  cleanNavigationStack
} from 'state/actions/sectionActions';
import { PREVIEW } from 'constants/sections';
import { stackSection, unstackSection } from 'utils/navigationUtils';

const initialState = {
  sections: [PREVIEW]
};

const sectionsSlice = createSlice({
  name: 'sections',
  initialState,
  extraReducers: {
    [goToSection]: (state, { payload }) => {
      state.sections = stackSection(state.sections, payload);
    },
    [goToPreviousSection]: (state, { payload }) => {
      state.sections = unstackSection(state.sections, payload);
    },
    [cleanNavigationStack]: state => {
      state.sections = initialState.sections;
    }
  }
});

export const getSections = state => state.sections;
export const getSectionStack = state => getSections(state).sections;
export const getCurrentSection = state => {
  const sectionStack = getSectionStack(state);
  return sectionStack[sectionStack.length - 1];
};

export default sectionsSlice.reducer;
