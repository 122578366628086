import { useSelector, shallowEqual } from 'react-redux';

const useSelectedSettlementPaymentMethod = () =>
  useSelector(
    ({ settlementOffer: { selectedSettlementPaymentMethod } }) => ({
      selectedSettlementPaymentMethod
    }),
    shallowEqual
  );

export default useSelectedSettlementPaymentMethod;
