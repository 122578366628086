import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import paymentMethodService from 'services/paymentMethodService';
import parseError from 'utils/parseError';

export const paymentForm = createAsyncThunk('paymentForm', async paymentFormInfo => {
  try {
    const {
      data: { data: paymentFormDetails }
    } = await paymentMethodService.paymentForm({ ...paymentFormInfo });
    return paymentFormDetails[0]?.formUrl;
  } catch ({ response }) {
    throw parseError(response);
  }
});

export const selectPaymentMethod = createAction('paymentForm/selectPaymentMethod');
export const savePaymentToken = createAction('paymentForm/savePaymentToken');
export const savePaymentLast4 = createAction('paymentForm/savePaymentLast4');
export const savePaymentRoutingNumber = createAction('paymentForm/savePaymentRoutingNumber');
export const savePaymentNameOnAccount = createAction('paymentForm/savePaymentNameOnAccount');
export const savePaymentCardType = createAction('paymentForm/savePaymentCardType');
export const savePaymentSource = createAction('paymentForm/savePaymentSource');
export const savePaymentDetails = createAction('paymentForm/savePaymentDetails');
export const resetPaymentForm = createAction('paymentForm/resetPaymentForm');

export const { fulfilled: paymentFormFulfilled } = paymentForm;
