import React, { useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import routes from 'constants/routesPaths';
import { green } from 'styles/_variables.scss';
import { MIXPANEL_EVENTS } from 'constants/mixpanelEvents';
import { FULFILLED, REJECTED } from 'constants/actionStatusConstants';
import { IFRAME_LOADER_FORMAT, IFRAME_LOADER_SIZE } from 'constants/constants';
import {
  settlementInitialUrl,
  settlementShortUrl,
  settlementReferrer
} from 'state/actions/settlementOfferActions';
import { useDispatch, useStatus, useAnalytics, useSelectSettlementUrl } from 'hooks';

const SettlementOfferInitialPage = () => {
  const history = useHistory();
  const { trackEvent } = useAnalytics();
  const getSettlementUrl = useDispatch(settlementInitialUrl);
  const setShortUrl = useDispatch(settlementShortUrl);
  const setReferrer = useDispatch(settlementReferrer);
  const { settlementUrl } = useSelectSettlementUrl();
  const { status } = useStatus(settlementInitialUrl);
  const url = history.location.pathname.split('/');
  const shortUrlPath = url.at(-1);
  const ref = url.at(-2);

  useEffect(() => {
    trackEvent(MIXPANEL_EVENTS.viewSettlementOfferPage, { hashCode: shortUrlPath });
    setReferrer({ ref });
    setShortUrl({ shortUrlPath });
    getSettlementUrl({ ref, shortUrlPath });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return status === FULFILLED ? (
    ref === 'e' ? (
      <Redirect push to={{ pathname: `/${settlementUrl}` }} />
    ) : (
      <Redirect
        push
        to={{ pathname: routes.disclosures, state: { hideMenu: true, settlementUrl } }}
      />
    )
  ) : status === REJECTED ? (
    <Redirect to={routes.settlementError} />
  ) : (
    <div className="settlement-container">
      <Loader
        visible
        type={IFRAME_LOADER_FORMAT}
        color={green}
        height={IFRAME_LOADER_SIZE}
        width={IFRAME_LOADER_SIZE}
      />
    </div>
  );
};

export default SettlementOfferInitialPage;
