import React, { useEffect, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import LogoIcon from 'assets/icons/logoIcon.svg';
import ReactToPrint from 'react-to-print';
import { Flex, Text, Button, useTheme } from '@chakra-ui/react';
import { Redirect } from 'react-router-dom';
import PaymentDates from 'components/paymentPlans/PaymentDates';
import SuccessIcon from '@mui/icons-material/CheckCircle';
import PrintIcon from '@mui/icons-material/PrintOutlined';
import SettlementSuccessGrid from 'components/settlementOffer/SettlementSuccessGrid';
import {
  SETTLEMENT_CONFIRMATION_OPTIONS_ID,
  PAYMENT_METHOD_OPTIONS,
  SETTLEMENT_OPTIONS,
  REPAYMENT_OPTIONS
} from 'constants/constants';
import { ACCOUNT_DATE_FORMAT, formatDate } from 'utils/dateHelpers';
import { PAYMENT_AUTHORIZATION } from 'constants/disclosures';
import { MIXPANEL_EVENTS } from 'constants/mixpanelEvents';
import routes from 'constants/routesPaths';
import {
  useSelectSettlementReferenceNumber,
  useSelectedSettlementPaymentMethod,
  useSelectSettlementPlan,
  useSelectSettlementOption,
  useSelectSettlementOfferInfo,
  useSelectPaymentInfo,
  useSelectSettlementDate,
  useSelectNewSettlementPlanId,
  useAnalytics
} from 'hooks';
import { getOrdinal } from 'utils/helpers';
import { messages } from './translations';

const SettlementOfferSuccess = () => {
  const intl = useIntl();
  const theme = useTheme();
  const { last4, cardType, routingNumber, nameOnAccount, source } = useSelectPaymentInfo();
  const { trackEvent } = useAnalytics();
  const { referenceNumber } = useSelectSettlementReferenceNumber();
  // Pay in full or create a plan
  const { selectedSettlementOption } = useSelectSettlementOption();
  // Plan selected ( # of months )
  const { selectedSettlementPlan } = useSelectSettlementPlan();
  // ACH or credit card
  const { selectedSettlementPaymentMethod } = useSelectedSettlementPaymentMethod();
  // Settlement offer info
  const { offerAmount, originalCreditor } = useSelectSettlementOfferInfo();
  const { selectedDate } = useSelectSettlementDate();
  const { planId } = useSelectNewSettlementPlanId();
  const componentRef = useRef();

  const { SOC_WEB_URL } = process.env;

  const isCreatePlan =
    selectedSettlementOption === SETTLEMENT_OPTIONS.createPlan ||
    selectedSettlementOption === REPAYMENT_OPTIONS.createPlan;

  const isPayInFull =
    selectedSettlementOption === SETTLEMENT_OPTIONS.settleInFull ||
    selectedSettlementOption === REPAYMENT_OPTIONS.payInFull;

  const mappedSettlementOption = isCreatePlan
    ? SETTLEMENT_OPTIONS.createPlan
    : SETTLEMENT_OPTIONS.settleInFull;

  const isCard = PAYMENT_METHOD_OPTIONS.card === selectedSettlementPaymentMethod;
  const isBank = PAYMENT_METHOD_OPTIONS.bank === selectedSettlementPaymentMethod;

  const messageSuccess = useMemo(
    () => intl.formatMessage({ id: 'viewPaymentConfirmation.payment.messageSuccess' }),
    [intl]
  );

  const paymentSuccess = useMemo(
    () => intl.formatMessage({ id: 'settlementConfirmation.paymentSuccess' }),
    [intl]
  );

  const dateLabel = useMemo(
    () =>
      intl.formatMessage({
        id: `${SETTLEMENT_CONFIRMATION_OPTIONS_ID[mappedSettlementOption]}.date`
      }),
    [selectedSettlementOption, intl]
  );

  const paymentLabel = useMemo(
    () =>
      intl.formatMessage({
        id: `${SETTLEMENT_CONFIRMATION_OPTIONS_ID[mappedSettlementOption]}.payment`
      }),
    [selectedSettlementOption, intl]
  );

  const consumerNameLabel = useMemo(
    () => intl.formatMessage({ id: 'viewPaymentConfirmation.consumerName' }),
    [intl]
  );

  const accountTypeLabel = useMemo(
    () => intl.formatMessage({ id: 'viewPaymentConfirmation.accountType' }),
    [intl]
  );

  useEffect(() => {
    if (isCreatePlan) {
      trackEvent(MIXPANEL_EVENTS.viewSettlementOfferSuccessPagePlan, { referenceNumber });
    } else if (isPayInFull) {
      trackEvent(MIXPANEL_EVENTS.viewSettlementOfferSuccessPagePIF, { referenceNumber });
    }
  }, [trackEvent, isCreatePlan, isPayInFull, referenceNumber]);

  if (!planId) {
    return <Redirect to={routes.settlementError} />;
  }

  const generatePaymentPlan = () => {
    const paymentPlan = [];
    const initialDate = new Date(selectedDate);
    const amountOfMonths = selectedSettlementPlan?.numberOfMonths;

    [...Array(amountOfMonths).keys()].map((_, index) => {
      const date = new Date(initialDate);
      date.setMonth(initialDate.getMonth() + index);
      paymentPlan.push({
        date: formatDate(date, ACCOUNT_DATE_FORMAT),
        amount: selectedSettlementPlan?.amount?.[index]
      });
    });

    return paymentPlan;
  };

  const data = [
    {
      label: intl.formatMessage(messages.referenceNumber),
      value: referenceNumber
    },
    {
      label: consumerNameLabel,
      value: nameOnAccount
    },
    {
      label: intl.formatMessage(messages.transactionId),
      value: planId
    },
    {
      ...(isPayInFull && {
        label: dateLabel,
        value: formatDate(selectedDate, ACCOUNT_DATE_FORMAT)
      })
    },
    {
      ...(originalCreditor && {
        label: intl.formatMessage(messages.originalCreditor),
        value: originalCreditor
      })
    },
    {
      ...(routingNumber &&
        isBank && {
          label: 'Routing Number',
          value: routingNumber
        })
    },
    {
      label: accountTypeLabel,
      value: source
    },
    {
      label: intl.formatMessage(messages.paymentMethod),
      value: isCard
        ? `${cardType} ${intl.formatMessage(messages.endingIn)} ${last4}`
        : `${intl.formatMessage(messages.bankAccount)} ${intl.formatMessage(
            messages.endingIn
          )} ${last4}`
    },
    {
      ...(isPayInFull && {
        label: paymentLabel,
        value: `$${offerAmount.toLocaleString(undefined, { minimumFractionDigits: 2 })}`
      })
    },
    {
      ...(isCreatePlan && {
        label: paymentLabel,
        isPaymentDates: true,
        value: <PaymentDates small paymentSchedule={generatePaymentPlan()} />
      })
    },
    {
      ...(isCreatePlan && {
        label: intl.formatMessage(messages.frequency),
        value: `${intl.formatMessage(messages.every)} ${getOrdinal(
          dayjs(selectedDate).date()
        )} ${intl.formatMessage({
          id: 'paymentFrequency.ofTheMonth'
        })}`
      })
    }
  ];

  return (
    <Flex
      alignItems="center"
      w="full"
      h="full"
      direction="column"
      backgroundColor="delta.50"
      pb={12}
    >
      <Flex
        mt={{ base: 6, md: 12 }}
        alignItems="center"
        direction="column"
        ref={componentRef}
        w="full"
        className="settlement-pdf-container"
      >
        <LogoIcon className="print-soc-logo" />
        <Flex className="success-container" alignItems="center" direction="column">
          <Flex className="success-icon" justifyContent="center">
            <SuccessIcon sx={{ color: theme.colors.beta[500], width: '68px', height: '68px' }} />
          </Flex>
          <Text
            fontSize={{ base: 'xl', md: '3xl' }}
            color="alpha.500"
            fontWeight={700}
            textAlign="center"
            className="success-title"
          >
            {messageSuccess}
          </Text>
          <Text
            className="success-message"
            fontSize={{ base: 'md', md: 'xl' }}
            color="gray.500"
            textAlign="center"
            mb={6}
            mx={2}
          >
            {paymentSuccess}
          </Text>
        </Flex>
        <SettlementSuccessGrid data={data} />
        <Flex
          maxW="914px"
          paddingX={{ base: 0, md: 4 }}
          width={{ base: '90%', md: '100%' }}
          className="payment-authorization-container"
        >
          <Text pt={6} fontSize="xs" color="alpha.500" className="payment-authorization">
            {PAYMENT_METHOD_OPTIONS.card === selectedSettlementPaymentMethod
              ? PAYMENT_AUTHORIZATION.card
              : PAYMENT_AUTHORIZATION.ACH}
            {PAYMENT_AUTHORIZATION.print}
          </Text>
        </Flex>
      </Flex>
      <Flex alignItems="center" my={8}>
        <ReactToPrint
          trigger={() => (
            <Button
              _hover={{ backgroundColor: 'transparent' }}
              _active={{ backgroundColor: 'transparent' }}
              backgroundColor="transparent"
              padding={2}
              color="alpha.500"
              className="success-settlement-not-display-pdf"
            >
              <PrintIcon className="mr-2" />
              {intl.formatMessage(messages.printReceipt)}
            </Button>
          )}
          onAfterPrint={() => trackEvent(MIXPANEL_EVENTS.clickPrintReceipt)}
          content={() => componentRef.current}
        />
      </Flex>

      <a href={SOC_WEB_URL} onClick={() => trackEvent(MIXPANEL_EVENTS.clickBackToWebsite)}>
        <Button
          _hover={{ backgroundColor: 'transparent' }}
          _active={{ backgroundColor: 'transparent' }}
          paddingX={20}
          paddingY={2.5}
          height="auto"
          colorScheme="alpha"
          variant="outline"
          className="success-settlement-back-button"
        >
          {intl.formatMessage(messages.backToHome)}
        </Button>
      </a>
    </Flex>
  );
};

export default SettlementOfferSuccess;
