import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { Pane, ContentContainer, Loading } from 'components/common';
import Documents from 'components/documents/Documents';
import EmptyPage from 'pages/EmptyPage';

import { useSelectAccountId, useStatus, useSelectDocuments, useDispatch } from 'hooks';
import { documents, resetDocuments } from 'state/actions/userActions';
import { FULFILLED, PENDING } from 'constants/actionStatusConstants';

const DocumentsPage = () => {
  const intl = useIntl();
  const { accountId } = useSelectAccountId();
  const documentsRequest = useDispatch(documents);
  const resetDocumentsRequest = useDispatch(resetDocuments);
  const { hasDocuments } = useSelectDocuments();
  const { status } = useStatus(documents);

  useEffect(() => {
    return () => {
      resetDocumentsRequest();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    accountId && documentsRequest({ accountId });
  }, [accountId, documentsRequest]);

  return (
    <ContentContainer>
      <div className="homepage-container">
        {status === FULFILLED &&
          (hasDocuments ? (
            <>
              <h1 className="page-title">{intl.formatMessage({ id: 'documents.title' })}</h1>
              <Pane className="mb-4 p-4 p-lg-5" wide>
                <Documents />
              </Pane>
            </>
          ) : (
            <EmptyPage title={intl.formatMessage({ id: 'documents.empty' })} />
          ))}
        {status === PENDING && <Loading />}
      </div>
    </ContentContainer>
  );
};

export default DocumentsPage;
