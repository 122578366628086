import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { bool, number, func } from 'prop-types';

const DayButton = ({ day, selected, onClick, disabled = false }) => {
  const backgroundColor = selected ? 'beta.400' : 'gamma.50';
  const textColor = selected ? 'white' : 'alpha.300';

  return (
    <Flex
      py={2}
      background={disabled ? 'white' : backgroundColor}
      borderRadius="27px"
      width="34px"
      height="34px"
      cursor="pointer"
      alignItems="center"
      justifyContent="center"
      onClick={onClick}
      borderColor={disabled ? 'gray.400' : 'transparent'}
      borderStyle="solid"
      borderWidth="1px"
    >
      <Text as="span" fontSize="xs" color={disabled ? 'gray.400' : textColor} fontWeight={700}>
        {day}
      </Text>
    </Flex>
  );
};

DayButton.propTypes = {
  selected: bool.isRequired,
  day: number.isRequired,
  onClick: func.isRequired,
  disabled: bool
};

export default DayButton;
