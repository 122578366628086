import httpClient from 'httpClient';

class UserService {
  static login(user) {
    return httpClient.post('/login', user);
  }

  static accounts(accountIds) {
    return httpClient.post('/accounts', { accountIds });
  }

  static documents(accountId) {
    return httpClient.post('/documents', accountId);
  }

  static document(documentInfo) {
    return httpClient.post('/document', documentInfo);
  }

  static disclosures(debtID) {
    return httpClient.post('/disclosures', { debtID });
  }
}

export default UserService;
