import { useSelector, shallowEqual } from 'react-redux';

const useSelectSettlementOfferInfo = () =>
  useSelector(
    ({
      settlementOffer: {
        currentBalance,
        offerAmount,
        settlementTermsOffered,
        expiryDate,
        originalCreditor
      }
    }) => ({
      currentBalance,
      offerAmount,
      settlementTermsOffered,
      expiryDate,
      originalCreditor
    }),
    shallowEqual
  );

export default useSelectSettlementOfferInfo;
