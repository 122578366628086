import { PAYMENT_METHOD_OPTIONS } from 'constants/constants';
import capitalize from 'lodash/capitalize';

export const successScreenPaymentMethodDecorator = ({
  selectedPaymentMethod,
  last4,
  cardType,
  bankAccount
}) => {
  const paymentMethodDecoration = { paymentMethodDescription: `****${last4}` };
  if (selectedPaymentMethod === PAYMENT_METHOD_OPTIONS.card)
    paymentMethodDecoration.paymentMethod = capitalize(cardType?.toLowerCase());
  if (selectedPaymentMethod === PAYMENT_METHOD_OPTIONS.bank)
    paymentMethodDecoration.paymentMethod = bankAccount;
  return paymentMethodDecoration;
};

export const confirmationScreenPaymentMethodDecorator = ({
  intl,
  messages,
  selectedPaymentMethod,
  last4,
  cardType
}) => {
  let subject;
  if (selectedPaymentMethod === PAYMENT_METHOD_OPTIONS.card)
    subject = capitalize(cardType?.toLowerCase());
  if (selectedPaymentMethod === PAYMENT_METHOD_OPTIONS.bank)
    subject = intl.formatMessage(messages.bankAccount);
  return {
    paymentMethod: intl.formatMessage(messages.paymentMethod),
    paymentMethodDescription: `${subject} ${intl.formatMessage(messages.endingIn)} ****${last4}`
  };
};

const planHasSameQuotes = paymentSchedule =>
  paymentSchedule.every(payment => payment.paymentAmount === paymentSchedule[0].paymentAmount);

export const getPlanOneLineResume = (selectedPlan, intl) => {
  const { paymentSchedule, planLength } = selectedPlan;
  const uniformPayments = planHasSameQuotes(paymentSchedule);
  const baseAmount = `$${paymentSchedule[0].paymentAmount}`;
  const lastPaymentAmount = `$${paymentSchedule[paymentSchedule.length - 1].paymentAmount}`;
  return intl.formatMessage(
    { id: 'viewAddPaymentMethodConfirmation.addPaymentMethod.paymentResume' },
    {
      payments: planLength - !uniformPayments,
      baseAmount,
      surplus: +!uniformPayments,
      lastPaymentAmount
    }
  );
};
