import React, { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, Redirect } from 'react-router-dom';
import { Flex, Text, Button, useTheme } from '@chakra-ui/react';
import routes from 'constants/routesPaths';
import { Arrow, Loading, CurrencyText } from 'components/common';
import { createPaymentPlan } from 'state/actions/settlementOfferActions';
import { PENDING, REJECTED, FULFILLED } from 'constants/actionStatusConstants';
import { PAYMENT_AUTHORIZATION } from 'constants/disclosures';
import {
  useDispatch,
  useStatus,
  useSelectPaymentInfo,
  useSelectSettlementReferenceNumber,
  useSelectSettlementOption,
  useSelectSettlementOfferInfo,
  useSelectedSettlementPaymentMethod,
  useSelectSettlementPlan,
  useSelectSettlementDate
} from 'hooks';
import {
  SETTLEMENT_OPTIONS_LABEL,
  SETTLEMENT_OPTIONS,
  SETTLEMENT_CONFIRMATION_OPTIONS_ID,
  PAYMENT_METHOD_OPTIONS,
  REPAYMENT_OPTIONS
} from 'constants/constants';
import PaymentDates from 'components/paymentPlans/PaymentDates';
import { white } from 'styles/_variables.scss';
import CalendarIcon from '@mui/icons-material/Today';
import PayInFullIcon from '@mui/icons-material/LocalAtm';
import { formatDate, ACCOUNT_DATE_FORMAT, PAYLOAD_DATE_FORMAT } from 'utils/dateHelpers';
import { messages } from './translations';

const ConfirmSettlementPage = () => {
  const intl = useIntl();
  const theme = useTheme();
  const history = useHistory();

  const createPlanRequest = useDispatch(createPaymentPlan);

  const { status } = useStatus(createPaymentPlan);
  const { referenceNumber } = useSelectSettlementReferenceNumber();
  const { offerAmount, originalCreditor } = useSelectSettlementOfferInfo();

  const {
    paymentToken,
    last4,
    cardType,
    paymentDetails,
    routingNumber,
    source,
    nameOnAccount
  } = useSelectPaymentInfo();

  const { selectedSettlementOption } = useSelectSettlementOption();
  const { selectedSettlementPaymentMethod } = useSelectedSettlementPaymentMethod();

  const selectedCreatePlan =
    selectedSettlementOption === SETTLEMENT_OPTIONS.createPlan ||
    selectedSettlementOption === REPAYMENT_OPTIONS.createPlan;

  const mappedPaymentOption = selectedCreatePlan
    ? SETTLEMENT_OPTIONS.createPlan
    : SETTLEMENT_OPTIONS.settleInFull;

  // Plan selected ( # of months )
  const { selectedSettlementPlan } = useSelectSettlementPlan();
  // ACH or credit card
  const { selectedDate } = useSelectSettlementDate();

  const generatePaymentPlan = (isForPayload = false) => {
    const paymentPlan = [];
    const initialDate = new Date(selectedDate);
    const amountOfMonths = selectedSettlementPlan?.numberOfMonths;
    [...Array(amountOfMonths).keys()].map((_, index) => {
      const date = new Date(initialDate);
      date.setMonth(initialDate.getMonth() + index);
      paymentPlan.push({
        date: isForPayload
          ? formatDate(date, PAYLOAD_DATE_FORMAT)
          : formatDate(date, ACCOUNT_DATE_FORMAT),
        amount: selectedSettlementPlan?.amount?.[index]
      });
    });

    return paymentPlan;
  };

  const generatePaymentString = () => {
    const paymentPlan = generatePaymentPlan();
    const amountOfMonths = selectedSettlementPlan?.numberOfMonths;
    let stringResult = '';

    if (paymentPlan.every(payment => payment.amount === paymentPlan[0].amount)) {
      stringResult += `${amountOfMonths} month${amountOfMonths > 1 ? 'ly' : ''} payment${
        amountOfMonths > 1 ? 's' : ''
      } of $${paymentPlan[0].amount.toLocaleString(undefined, { minimumFractionDigits: 2 })}`;
    } else {
      stringResult += `${amountOfMonths - 1} month${amountOfMonths > 2 ? 'ly' : ''} payment${
        amountOfMonths > 2 ? 's' : ''
      } of $${paymentPlan[0].amount.toLocaleString(undefined, { minimumFractionDigits: 2 })}`;
      stringResult += ` and 1 month payment of $${paymentPlan[
        amountOfMonths - 1
      ].amount.toLocaleString(undefined, { minimumFractionDigits: 2 })}`;
    }

    return stringResult;
  };

  const dateLabel = useMemo(
    () =>
      selectedCreatePlan
        ? intl.formatMessage({ id: 'confirmPage.startingDate' })
        : intl.formatMessage({ id: 'confirmPage.paymentDate' }),
    [selectedCreatePlan, intl]
  );

  const buttonLabel = intl.formatMessage({ id: 'confirmPage.authorize' });

  const titleMessage = useMemo(
    () =>
      selectedCreatePlan
        ? intl.formatMessage(messages.titleCreatePlan)
        : intl.formatMessage(messages.titlePayment),
    [selectedCreatePlan, intl]
  );

  const planIcon = useMemo(() => {
    return selectedCreatePlan ? (
      <CalendarIcon sx={{ color: theme.colors.gray[500] }} />
    ) : (
      <PayInFullIcon sx={{ color: theme.colors.gray[500] }} />
    );
  }, [selectedCreatePlan, theme]);

  const paymentLabel = useMemo(
    () =>
      intl.formatMessage({
        id: `${SETTLEMENT_CONFIRMATION_OPTIONS_ID[mappedPaymentOption]}.payment`
      }),
    [selectedSettlementOption, intl]
  );

  const consumerNameLabel = useMemo(
    () => intl.formatMessage({ id: 'viewPaymentConfirmation.consumerName' }),
    [intl]
  );

  const accountTypeLabel = useMemo(
    () => intl.formatMessage({ id: 'viewPaymentConfirmation.accountType' }),
    [intl]
  );

  const routingNumberLabel = useMemo(
    () => intl.formatMessage({ id: 'viewPaymentConfirmation.routingNumber' }),
    [intl]
  );

  useEffect(() => {
    status === REJECTED && history.replace(routes.settlementError);
    status === FULFILLED && history.replace(routes.settlementSuccess);
  }, [status, history]);

  const onPlanCreateClick = () => {
    const paymentSchedule = generatePaymentPlan(true);

    createPlanRequest({
      accountId: referenceNumber,
      paymentToken,
      paymentDetails,
      selectedPaymentMethod: selectedSettlementPaymentMethod,
      selectedRepaymentOption: selectedSettlementOption,
      selectedDate,
      amountOfMonths: selectedSettlementPlan?.numberOfMonths,
      last4,
      routingNumber,
      source,
      nameOnAccount,
      paymentSchedule,
      offerAmount
    });
  };

  if (!referenceNumber) {
    return <Redirect push to={routes.settlementError} />;
  }
  return (
    <Flex direction="column" align="center" backgroundColor="delta.50" flex={1} pb={8}>
      <Flex
        w="full"
        backgroundColor="alpha.400"
        paddingY={{ base: 2, md: 3 }}
        paddingX={{ base: 2, md: 7 }}
        mb={{ base: 7, md: 4 }}
      >
        <Button
          aria-label={intl.formatMessage(messages.backAria)}
          onClick={() => history.goBack()}
          backgroundColor="transparent"
          color="white"
          _hover={{ backgroundColor: 'transparent' }}
          _active={{ backgroundColor: 'transparent' }}
          _focus={{ backgroundColor: 'transparent' }}
        >
          <Arrow type="left" className="mr-3" color={white} />
          {intl.formatMessage(messages.back)}
        </Button>
      </Flex>
      <Flex alignItems="center" direction="column" maxW="1100px" margin="0 auto" width="100%">
        <Text
          width="90%"
          fontSize={{ base: 'xl', md: '3xl' }}
          color="alpha.500"
          fontWeight={700}
          mb={5}
        >
          {titleMessage}
        </Text>

        <Flex
          direction="column"
          borderRadius="6px"
          boxShadow="0px 20px 21px -5px rgba(237, 242, 247, 0.44)"
          width="90%"
          backgroundColor="white"
          paddingX={{ base: 4, md: 6 }}
          paddingY={{ base: 5, md: 9 }}
        >
          <Text
            display={{ base: 'block', md: 'flex' }}
            fontSize={{ base: 'lg', md: 'xl' }}
            color="alpha.500"
            alignItems="center"
            fontWeight={700}
          >
            {`${intl.formatMessage(messages.reference)}: `}
            <Text fontSize="md" fontWeight={400} ml={{ base: 0, md: 1 }}>
              {referenceNumber}
            </Text>
          </Text>

          {nameOnAccount && (
            <Text
              display={{ base: 'block', md: 'flex' }}
              fontSize={{ base: 'lg', md: 'xl' }}
              mt={{ base: 2, md: 4 }}
              color="alpha.500"
              alignItems="center"
              fontWeight={700}
            >
              {`${consumerNameLabel}: `}
              <Text fontSize="md" fontWeight={400} ml={{ base: 0, md: 1 }}>
                {nameOnAccount}
              </Text>
            </Text>
          )}

          {!selectedCreatePlan && (
            <Text
              display={{ base: 'block', md: 'flex' }}
              alignItems="center"
              fontSize={{ base: 'lg', md: 'xl' }}
              color="alpha.500"
              fontWeight={700}
              mt={{ base: 2, md: 4 }}
            >
              <Flex alignItems="center" gridGap={1}>
                <CalendarIcon />
                {`${dateLabel}: `}
              </Flex>
              <Text fontSize="md" fontWeight={400} ml={{ base: 0, md: 1 }}>
                {formatDate(selectedDate, ACCOUNT_DATE_FORMAT)}
              </Text>
            </Text>
          )}

          {originalCreditor && (
            <Text
              display={{ base: 'block', md: 'flex' }}
              alignItems="center"
              fontSize={{ base: 'lg', md: 'xl' }}
              color="alpha.500"
              fontWeight={700}
              mt={{ base: 2, md: 4 }}
            >
              {`${intl.formatMessage(messages.creditor)}: `}
              <Text fontSize="md" fontWeight={400} ml={{ base: 0, md: 1 }}>
                {originalCreditor}
              </Text>
            </Text>
          )}

          {routingNumber && (
            <Text
              display={{ base: 'block', md: 'flex' }}
              alignItems="center"
              fontSize={{ base: 'lg', md: 'xl' }}
              color="alpha.500"
              fontWeight={700}
              mt={{ base: 2, md: 4 }}
            >
              {`${routingNumberLabel}: `}
              <Text fontSize="md" fontWeight={400} ml={{ base: 0, md: 1 }}>
                {routingNumber}
              </Text>
            </Text>
          )}

          {selectedSettlementPaymentMethod === PAYMENT_METHOD_OPTIONS.bank && (
            <Text
              display={{ base: 'block', md: 'flex' }}
              alignItems="center"
              fontSize={{ base: 'lg', md: 'xl' }}
              color="alpha.500"
              fontWeight={700}
              mt={{ base: 2, md: 4 }}
            >
              {`${accountTypeLabel}: `}
              <Text fontSize="md" fontWeight={400} ml={{ base: 0, md: 1 }}>
                {source}
              </Text>
            </Text>
          )}

          <Text
            display={{ base: 'block', md: 'flex' }}
            alignItems="center"
            fontSize={{ base: 'lg', md: 'xl' }}
            color="alpha.500"
            fontWeight={700}
            mt={{ base: 2, md: 4 }}
          >
            {`${intl.formatMessage(messages.paymentMethod)}: `}
            <Flex>
              {PAYMENT_METHOD_OPTIONS.card === selectedSettlementPaymentMethod ? (
                <Text fontSize="md" fontWeight={400} ml={{ base: 0, md: 1 }}>
                  {cardType}
                </Text>
              ) : (
                <Text fontSize="md" fontWeight={400} ml={{ base: 0, md: 1 }}>
                  {intl.formatMessage(messages.bankAccount)}
                </Text>
              )}
              <Text textAlign="center" fontSize="md" fontWeight={700} ml={1}>
                {intl.formatMessage(messages.endingIn)}
              </Text>
              <Text textAlign="center" fontSize="md" fontWeight={400} ml={1}>
                {last4}
              </Text>
            </Flex>
          </Text>

          <Text
            display={{ base: 'block', md: 'flex' }}
            alignItems="center"
            fontSize={{ base: 'lg', md: 'xl' }}
            color="alpha.500"
            fontWeight={700}
            mt={{ base: 2, md: 4 }}
          >
            {`${intl.formatMessage(messages.totalBalance)}: `}
            <Flex>
              <Text fontSize="md" fontWeight={400} ml={{ base: 0, md: 1 }}>
                <CurrencyText value={parseFloat(offerAmount)} />
              </Text>
            </Flex>
          </Text>

          {selectedCreatePlan && (
            <Text
              display={{ base: 'block', md: 'flex' }}
              alignItems="center"
              fontSize={{ base: 'lg', md: 'xl' }}
              color="alpha.500"
              fontWeight={700}
              mt={{ base: 2, md: 4 }}
            >
              {`${paymentLabel}: `}
              <Flex>
                <Text fontSize="md" fontWeight={400} ml={{ base: 0, md: 1 }}>
                  <PaymentDates small paymentSchedule={generatePaymentPlan()} />
                </Text>
              </Flex>
            </Text>
          )}

          <Text
            fontSize={{ base: 'lg', md: 'xl' }}
            color="alpha.500"
            fontWeight={700}
            display={{ base: 'block', md: 'flex' }}
            alignItems="center"
            mt={{ base: 2, md: 4 }}
          >
            <Flex gridGap={1} alignItems="center">
              {planIcon}
              {intl.formatMessage({ id: SETTLEMENT_OPTIONS_LABEL[mappedPaymentOption] })}
              {selectedCreatePlan && `: `}
            </Flex>
            <Text fontSize="md" fontWeight={400} ml={{ base: 0, md: 1 }} display="flex">
              {selectedCreatePlan && generatePaymentString()}
            </Text>
          </Text>
        </Flex>
        {status === PENDING ? (
          <div className="pt-5">
            <Loading />
          </div>
        ) : (
          <Flex width="90%" direction="column" mt={8} alignItems="center">
            <Text mb={8} fontSize="xs">
              {PAYMENT_METHOD_OPTIONS.card === selectedSettlementPaymentMethod
                ? PAYMENT_AUTHORIZATION.card
                : PAYMENT_AUTHORIZATION.ACH}
              {PAYMENT_AUTHORIZATION.cta.replace('{buttonText}', buttonLabel)}
              {PAYMENT_AUTHORIZATION.print}
            </Text>
            <Button
              backgroundColor="beta.500"
              title={buttonLabel}
              aria-label={buttonLabel}
              onClick={onPlanCreateClick}
              height="auto"
              color="white"
              paddingX={8}
              paddingY={2.5}
              _hover={{ backgroundColor: 'beta.500' }}
              _active={{ backgroundColor: 'beta.500' }}
              _focus={{ backgroundColor: 'beta.500' }}
            >
              <Text fontWeight={700}>{intl.formatMessage(messages.confirmSettlement)}</Text>
            </Button>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default ConfirmSettlementPage;
