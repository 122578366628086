import React, { useCallback, useMemo } from 'react';
import { arrayOf, object, bool } from 'prop-types';
import cn from 'classnames';
import { useIntl } from 'react-intl';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton
} from 'react-accessible-accordion';

import { Arrow, CurrencyText } from 'components/common';
import { linkColor, md } from 'styles/_variables.scss';
import { getOrdinal } from 'utils/helpers';
import { ACCOUNT_DATE_FORMAT, formatDate } from 'utils/dateHelpers';
import { useToggle, useBreakpoint } from 'hooks';

const PaymentDates = ({ paymentSchedule, small = false }) => {
  const intl = useIntl();
  const isDesktop = useBreakpoint(md);
  const [isExpanded, toggleExpanded] = useToggle();

  const accordion = useCallback(
    position => (
      <Accordion allowZeroExpanded onChange={toggleExpanded}>
        <AccordionItem>
          <AccordionItemHeading
            className={cn('view-more-payments', position, { expanded: isExpanded })}
          >
            <AccordionItemButton>
              {intl.formatMessage({
                id: isExpanded ? 'confirmPage.viewLess' : 'confirmPage.viewAllDates'
              })}
              <Arrow color={linkColor} type={isExpanded ? 'up' : 'down'} className="ml-2" />
            </AccordionItemButton>
          </AccordionItemHeading>
        </AccordionItem>
      </Accordion>
    ),
    [intl, isExpanded, toggleExpanded]
  );

  const visibleDates = useMemo(() => {
    return isDesktop ? paymentSchedule?.length > 20 : paymentSchedule?.length > 5;
  }, [paymentSchedule, isDesktop]);

  return (
    <div
      className={cn('payments-container', { expanded: isExpanded, collapsed: !isExpanded, small })}
    >
      {visibleDates && isDesktop && accordion('top')}
      <div className={cn('payments-list', { expanded: isExpanded, collapsed: !isExpanded, small })}>
        {paymentSchedule.map(({ date, amount }, index) => (
          <div className="payment-item" key={`payment-${index}`}>
            <span className="font-weight-bold">{getOrdinal(index + 1)}</span>
            <span>
              {` ${formatDate(date, ACCOUNT_DATE_FORMAT)} - `}
              <CurrencyText value={amount} />
            </span>
          </div>
        ))}
      </div>
      {visibleDates && !isDesktop && accordion('bottom')}
    </div>
  );
};

PaymentDates.propTypes = {
  paymentSchedule: arrayOf(object).isRequired,
  small: bool
};

export default PaymentDates;
