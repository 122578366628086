import { combineReducers } from 'redux';
import localForage from 'localforage';
import { persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import statusReducer from './statusReducer';
import session from './sessionReducer';
import sections from './sectionReducer';
import accounts from './accountReducer';
import paymentPlan from './paymentPlanReducer';
import paymentMethod from './paymentMethodReducer';
import documents from './documentReducer';
import settlementOffer from './settlementOfferReducer';

const sessionPersistConfig = {
  key: 'session',
  storage: localForage,
  whitelist: ['authenticated', 'info', 'user', 'disclosuresAccepted'],
  stateReconciler: autoMergeLevel2
};

const accountsPersistConfig = {
  key: 'accounts',
  storage: localForage,
  whitelist: ['accountOptions', 'accountSelected'],
  stateReconciler: autoMergeLevel2
};

const rootReducer = combineReducers({
  session: persistReducer(sessionPersistConfig, session),
  accounts: persistReducer(accountsPersistConfig, accounts),
  statusReducer,
  documents,
  paymentPlan,
  paymentMethod,
  settlementOffer,
  sections
});

export default rootReducer;
