export const DISCLOSURES = [
  {
    paragraph:
      'This is a communication from a debt collector. This is an attempt to collect a debt' +
      ' and any information obtained will be used for that purpose.'
  },
  {
    paragraph:
      'The following jurisdictions require us to furnish you with the following information.' +
      ' These disclosures are requirements of these jurisdictions and do not contain a complete' +
      ' list of the rights all consumers have under city, state, and federal laws.'
  },
  {
    title: 'Colorado Residents',
    paragraph:
      'The address and telephone number of our local office is as follows: 8690 Wolff Court,' +
      ' Suite 110, Westminster, CO 80031; Colorado telephone for Colorado residents only: 303-920-4763.' +
      ' For residents of all other states, please use our toll free number 866-539-7525.'
  },
  {
    title: 'Connecticut Residents',
    paragraph:
      'This collection agency is licensed in Connecticut. License number CCA-1916692. NMLS #1916692.'
  },
  {
    title: 'Massachusetts Residents',
    subtitle: 'NOTICE OF IMPORTANT RIGHTS',
    paragraph:
      'You have the right to make a written or oral request that telephone calls regarding your' +
      ' debt not be made to you at your place of employment. Any such oral request will be valid' +
      ' for only ten days unless you provide written confirmation of the request postmarked or' +
      ' delivered within seven days of such request. You may terminate this request by writing' +
      ' to the debt collector.'
  },
  {
    title: 'North Carolina Residents',
    paragraph: 'Spring Oaks Capital, LLC’s North Carolina company number is as follows: 119507661'
  },
  {
    title: 'New York Residents',
    paragraph:
      'If a creditor or debt collector receives a money judgment against you in court, state and' +
      ' federal laws may prevent the following types of income from being taken to pay the debt: ' +
      '1. Supplemental security income, (SSI); 2. Social security; 3. Public assistance (welfare); ' +
      '4. Spousal support, maintenance (alimony) or child support; 5. Unemployment benefits; ' +
      '6. Disability benefits; 7. Workers’ compensation benefits; 8. Public or private pensions; ' +
      '9. Veterans’ benefits; 10. Federal student loans, federal student grants, and federal work ' +
      'study funds; and 11. Ninety percent of your wages or salary earned in the last sixty days.'
  },
  {
    title: 'New York City Residents',
    paragraph:
      'You may also reach us by calling Tim Rees at 866-539-7554. New York City Department' +
      ' of Consumer Affairs License Number(s): 2097110-DCA'
  },
  {
    title: 'Tennessee Residents',
    paragraph:
      'Spring Oaks Capital, LLC is licensed by the collection service board of the department of' +
      ' commerce and insurance.'
  }
];

export const PAYMENT_AUTHORIZATION = {
  ACH: `I authorize Spring Oaks Capital, LLC hereinafter to initiate a single or recurring ACH/electronic debit to my account set forth
  above in the amount(s) and date(s) set forth above. I agree that ACH transactions I authorize comply with all applicable law. Furthermore,
  I assert that I am the owner or an authorized signer of the bank account provided. If you wish to rescind this authorization or cancel payment arrangements,
  you may write Spring Oaks Capital, LLC at P.O. Box 1216, Chesapeake, VA 23327-1216 or call (866) 281-3065 during our normal business hours of
  Monday through Thursday 8am-9pm EST, Friday 8am-6pm EST. `,
  card: `I authorize Spring Oaks Capital, LLC hereinafter to initiate a single or recurring credit/debit card charge to my account set forth
  above in the amount(s) and date(s) set forth above. I agree that credit/debit card transactions I authorize comply with all applicable law.
  For recurring payment arrangements, I understand that this authorization will remain in full force and effect until I notify Spring Oaks Capital,
  LLC in writing or by phone that I wish to revoke this authorization. `,
  cta: 'To complete the payment process, click the “{buttonText}“ button. ',
  print:
    'It is recommended that you print a copy of this authorization and maintain it for your records.'
};
