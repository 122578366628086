const colors = {
  black: '#000000',
  white: '#FFFFFF',
  gray: {
    50: '#F8F9FA',
    100: '#EDF2F7',
    200: '#E2E8F0',
    300: '#CBD5E0',
    400: '#A0AEC0',
    500: '#718096',
    600: '#4A5568'
  },
  alpha: {
    50: '#3474C0',
    100: '#306BB0',
    200: '#2B61A0',
    300: '#275790',
    400: '#1E4470',
    500: '#0D1D30',
    600: '#03070C'
  },
  beta: {
    50: '#C2F36F',
    100: '#BAF15C',
    200: '#B2F049',
    300: '#9AE813',
    400: '#81C310',
    500: '#689D0D',
    600: '#4C7309'
  },
  gamma: {
    50: '#F0F7FF',
    100: '#C2DFFF',
    200: '#94C6FF',
    300: '#7DBAFF',
    400: '#66AEFF',
    500: '#4FA1FF',
    600: '#3895FF'
  },
  delta: {
    50: '#F9FAFF',
    100: '#D9E9BE',
    200: '#D0E4AF',
    300: '#C7DEA0',
    400: '#B6D482',
    500: '#A4C963',
    600: '#4161FF'
  },
  epsilon: {
    50: '#FFF5F5',
    100: '#FED7D7',
    200: '#FEB2B2',
    300: '#FC8181',
    400: '#F56565',
    500: '#E53E3E',
    600: '#C53030'
  },
  zeta: {
    50: '#FFFFF0',
    100: '#FEFCBF',
    200: '#FAF089',
    300: '#F6E05E',
    400: '#ECC94B',
    500: '#D69E2E',
    600: '#B7791F'
  },
  link: '#135eb7',
  secondaryColor: '#25466d',
  softRed: '#e1000019',
  red: '#e10000'
};

export default colors;
