import React from 'react';
import { string, func, node } from 'prop-types';

import { Button } from 'components/common';
import { sm } from 'styles/_variables.scss';
import { useBreakpoint } from 'hooks';

const FixedFooter = ({ title, route, children, ariaLabel = title, onClick = () => {} }) => {
  const isDesktop = useBreakpoint(sm);

  return (
    <section className="fixed-footer">
      {children ?? null}
      <Button
        linkTo={route}
        buttonStyle="primary"
        size={isDesktop ? 'large' : 'medium'}
        color="green"
        title={title}
        ariaLabel={ariaLabel}
        onClick={onClick}
        wide
      />
    </section>
  );
};

FixedFooter.propTypes = {
  title: string.isRequired,
  route: string.isRequired,
  ariaLabel: string,
  onClick: func,
  children: node
};

export default FixedFooter;
