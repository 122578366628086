import React, { useEffect } from 'react';
import { useSelectAccountId } from 'hooks';
import { string, object } from 'prop-types';
import mixpanel from 'mixpanel-browser';
import { MIXPANEL_AB_TESTING } from 'constants/mixpanelEvents';
import { Experiment, Variant, emitter, experimentDebugger } from '@marvelapp/react-ab-test';
import { activeExperiments } from 'constants/experiments';

const ExperimentWrapper = ({ experimentName, variantContent }) => {
  const { accountId } = useSelectAccountId();
  const variants = activeExperiments[experimentName]?.variants;

  useEffect(() => {
    if (accountId) {
      emitter.calculateActiveVariant(experimentName, accountId);
    }
  }, [accountId, experimentName]);

  // If there are no active variants for the experiment passed, return null
  if (!variants) {
    return null;
  }

  emitter.defineVariants(
    experimentName,
    variants.map(({ name }) => name),
    variants.map(({ ratio }) => ratio)
  );

  // Debugger to change between variants of an experiment
  if (process.env.ENV !== 'prod') {
    experimentDebugger.enable();
  }

  let VariantComponent;

  return (
    <Experiment name={experimentName}>
      {Object.keys(variantContent).map(variantName => {
        VariantComponent = variantContent[variantName];

        return (
          <Variant key={experimentName + variantName} name={variantName}>
            <VariantComponent experimentName={experimentName} />
          </Variant>
        );
      })}
    </Experiment>
  );
};

// Called when the experiment is displayed to the user.
emitter.addPlayListener((experimentName, variantName) => {
  mixpanel.track(MIXPANEL_AB_TESTING.startExperiment, {
    'Experiment name': experimentName,
    'Variant Name': variantName
  });
});

// Called when a 'win' is emitted
emitter.addWinListener((experimentName, variantName) => {
  mixpanel.track(MIXPANEL_AB_TESTING.emitWin, {
    name: experimentName,
    variant: variantName
  });
});

ExperimentWrapper.propTypes = {
  experimentName: string.isRequired,
  variantContent: object.isRequired
};

export default ExperimentWrapper;
