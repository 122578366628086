import { useSelector, shallowEqual } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

const useSelectPaymentPlans = () =>
  useSelector(
    ({ paymentPlan: { paymentPlans } }) => ({
      paymentPlans,
      hasPaymentPlans: !isEmpty(paymentPlans)
    }),
    shallowEqual
  );

export default useSelectPaymentPlans;
