import React from 'react';
import { string } from 'prop-types';
import { useIntl } from 'react-intl';
import { Flex, Text } from '@chakra-ui/react';
import { messages } from 'pages/SettlementOfferPage/translations';

const OfferHeader = ({ referenceNumber, currentBalance, offerAmount }) => {
  const intl = useIntl();

  const balance = currentBalance || 0;

  return (
    <Flex
      w="full"
      justifyContent="space-between"
      backgroundColor="gray.600"
      borderTopRadius="12px"
      paddingY={2}
      paddingX={6}
    >
      <Flex direction="column">
        {offerAmount !== undefined ? (
          // payment plan landing page flow will not have offerAmount defined
          <>
            <Text fontWeight={700} fontSize={{ base: 'sm', md: 'md' }} color="white" mt={0}>
              {intl.formatMessage(messages.amountTitle)} $
              {parseFloat(balance).toLocaleString(undefined, { minimumFractionDigits: 2 })}
            </Text>
            <Text fontWeight={700} fontSize={{ base: 'sm', md: 'md' }} color="white" mt={0}>
              {intl.formatMessage(messages.offerTitle)} $
              {parseInt(offerAmount, 10).toLocaleString(undefined, { minimumFractionDigits: 2 })}
            </Text>
          </>
        ) : (
          // settlement flow, offerAmount should always be defined
          <>
            <Text fontWeight={700} fontSize={{ base: 'xs', md: 'sm' }} color="gray.400">
              {intl.formatMessage(messages.amountTitle)}
            </Text>
            <Text
              as="span"
              fontWeight={700}
              fontSize={{ base: 'sm', md: 'md' }}
              color="white"
              mt={0}
            >
              ${parseFloat(balance).toLocaleString(undefined, { minimumFractionDigits: 2 })}
            </Text>
          </>
        )}
      </Flex>
      <Flex direction="column">
        <Text fontWeight={700} fontSize={{ base: 'xs', md: 'sm' }} color="gray.400">
          {intl.formatMessage(messages.referenceIdTitle)}
        </Text>
        <Text as="span" fontWeight={700} fontSize={{ base: 'sm', md: 'md' }} color="white" mt={0}>
          {referenceNumber}
        </Text>
      </Flex>
    </Flex>
  );
};

OfferHeader.propTypes = {
  referenceNumber: string.isRequired,
  currentBalance: string.isRequired,
  offerAmount: string
};

export default OfferHeader;
