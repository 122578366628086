import React, { useEffect, useMemo, memo } from 'react';
import { string } from 'prop-types';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import NumberFormat from 'react-number-format';

import { Arrow, Button, CurrencyText } from 'components/common';
import routes from 'constants/routesPaths';
import { white, md } from 'styles/_variables.scss';
import { accounts, toggleBackBannerSummary } from 'state/actions/userActions';
import { REPAYMENT_OPTIONS_LABEL, REPAYMENT_OPTIONS } from 'constants/constants';
import {
  useDispatch,
  useSelectBannerInfo,
  useSelectAccountId,
  useSelectSelectedPlan,
  useSelectBannerSummary,
  useBreakpoint
} from 'hooks';

const BackBanner = ({ ariaLabel, title, backTo }) => {
  const intl = useIntl();
  const history = useHistory();
  const isDesktop = useBreakpoint(md);
  const getAccountDetails = useDispatch(accounts);
  const toggleBannerSummary = useDispatch(toggleBackBannerSummary);
  const { accountId } = useSelectAccountId();
  const { isBannerSummaryExpanded } = useSelectBannerSummary();
  const { originalCreditor, remainingBalance, referenceNumber } = useSelectBannerInfo();
  const { selectedRepaymentOption, selectedAmount, planSummary } = useSelectSelectedPlan();

  const bannerButtonTitle = useMemo(
    () =>
      isBannerSummaryExpanded
        ? intl.formatMessage({ id: 'backBanner.viewLess' })
        : intl.formatMessage({ id: 'backBanner.summary' }),
    [isBannerSummaryExpanded, intl]
  );

  useEffect(() => {
    !referenceNumber && getAccountDetails([accountId]);
  }, [referenceNumber, accountId, getAccountDetails]);

  const onBackClicked = () => {
    backTo ? history.replace(backTo) : history.goBack();
  };

  return (
    <section className="back-banner">
      <div className="container">
        <Button className="back-button" ariaLabel={ariaLabel} title={title} onClick={onBackClicked}>
          <Arrow type="left" className="mr-3" color={white} />
          {title}
        </Button>
        <Button
          className="banner-button float-right"
          ariaLabel={intl.formatMessage({ id: 'backBanner.summaryAria' })}
          ariaExpanded={isBannerSummaryExpanded}
          title={bannerButtonTitle}
          onClick={toggleBannerSummary}
        >
          <Arrow
            type={isBannerSummaryExpanded ? 'up' : 'down'}
            className="mr-2 mr-sm-3"
            color={white}
          />
          {bannerButtonTitle}
        </Button>
        {selectedRepaymentOption === REPAYMENT_OPTIONS.payInFull && (
          <Button
            className="banner-button float-right mr-1 mr-sm-2 mr-xl-3"
            linkTo={routes.repaymentOptions}
          >
            {intl.formatMessage({
              id: isDesktop ? 'paymentOptionsBlock.cta' : 'paymentOptionsBlock.minifiedCTA'
            })}
          </Button>
        )}
        {referenceNumber && isBannerSummaryExpanded && (
          <div className="banner-info">
            <div className="banner-account">
              <span className="banner-account-item">
                <span className="font-weight-bold">{`${intl.formatMessage({
                  id: 'backBanner.originalCreditor'
                })}:  `}</span>
                {originalCreditor}
              </span>
              <span className="banner-account-item">
                <span className="font-weight-bold">{`${intl.formatMessage({
                  id: 'backBanner.remainingBalance'
                })}:  `}</span>
                <CurrencyText value={remainingBalance} />
              </span>
              <span className="banner-account-item">
                <span className="font-weight-bold">{`${intl.formatMessage({
                  id: 'backBanner.refNumber'
                })}:  `}</span>
                {referenceNumber}
              </span>
              {(selectedRepaymentOption || selectedAmount) && (
                <span className="banner-account-item">
                  {selectedRepaymentOption && (
                    <span className="font-weight-bold">
                      {intl.formatMessage({ id: REPAYMENT_OPTIONS_LABEL[selectedRepaymentOption] })}
                    </span>
                  )}
                  {selectedAmount && (
                    <NumberFormat
                      value={selectedAmount}
                      displayType="text"
                      thousandSeparator
                      prefix="$"
                      decimalScale={2}
                      renderText={(value, props) => (
                        <span className="font-weight-bold d-flex" {...props}>
                          {value}
                        </span>
                      )}
                    />
                  )}
                  {selectedRepaymentOption === REPAYMENT_OPTIONS.createPlan && (
                    <span className="d-flex">{planSummary}</span>
                  )}
                </span>
              )}
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

BackBanner.propTypes = {
  ariaLabel: string.isRequired,
  title: string.isRequired,
  backTo: string
};

export default memo(BackBanner);
