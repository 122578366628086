import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { bool, object, string, node } from 'prop-types';
import { Route, Redirect, useLocation } from 'react-router-dom';

import routes from 'constants/routesPaths';

const PrivateRoute = ({
  children,
  exact = false,
  path,
  accountSelected,
  authenticated,
  suppressed
}) => {
  const location = useLocation();

  if (!authenticated) {
    return <Redirect to={{ pathname: routes.login, state: { from: location } }} />;
  }

  if (isEmpty(accountSelected)) {
    return (
      <Redirect
        to={{ pathname: routes.selectAccount, state: { from: location, hideMenu: true } }}
      />
    );
  }

  if (suppressed && location.pathname !== routes.homeDisclosures) {
    return (
      <Redirect to={{ pathname: routes.accountError, state: { from: location, hideMenu: true } }} />
    );
  }

  return (
    <Route exact={exact} path={path}>
      {children}
    </Route>
  );
};

PrivateRoute.propTypes = {
  children: node.isRequired,
  path: string.isRequired,
  accountSelected: object.isRequired,
  authenticated: bool.isRequired,
  suppressed: bool,
  exact: bool
};

export default PrivateRoute;
