import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import settlementOfferService from 'services/settlementOfferService';

import paymentPlanService from 'services/paymentPlanService';
import parseError from 'utils/parseError';
import { SETTLEMENT_OPTIONS, PAYMENT_METHOD_OPTIONS } from 'constants/constants';

import { getSettlementBody, formatDate } from 'utils/helpers';

export const settlementInitialUrl = createAsyncThunk('settlementInitial', async url => {
  try {
    const {
      data: { data: settlementUrl }
    } = await settlementOfferService.getSettlementUrl(url);
    return settlementUrl;
  } catch ({ response }) {
    throw parseError(response);
  }
});

export const settlementOffer = createAsyncThunk('settlementOffer', async referenceNumber => {
  try {
    const {
      data: { data: settlementOffer }
    } = await settlementOfferService.getSettlementOffer(referenceNumber);
    return settlementOffer;
  } catch ({ response }) {
    throw parseError(response);
  }
});

export const verifyPhoneNumber = createAsyncThunk(
  'verifyPhoneNumber',
  async ({ debtId, shortUrl, settlementUrl, ref, dateOfBirth, ssnLastFour }) => {
    try {
      const credentials = {
        debtId,
        shortUrl,
        fullUrl: settlementUrl,
        ref,
        dateOfBirth,
        ssnLastFour
      };
      const {
        data: { data: verified }
      } = await settlementOfferService.verifyPhoneNumber(credentials);
      return verified;
    } catch ({ response }) {
      throw parseError(response);
    }
  }
);

export const createPaymentPlan = createAsyncThunk(
  'settlementOffer/createPlan',
  async ({
    accountId,
    paymentToken,
    paymentDetails,
    selectedPaymentMethod,
    selectedRepaymentOption,
    selectedDate,
    amountOfMonths,
    last4,
    routingNumber,
    source,
    nameOnAccount,
    paymentSchedule,
    offerAmount
  }) => {
    try {
      // send proof of authorization in case the payment method is ACH
      if (selectedPaymentMethod === PAYMENT_METHOD_OPTIONS.bank) {
        const {
          data: { IPv4 }
        } = await paymentPlanService.getIPv4();

        let paymentSchedules = [];
        paymentSchedules =
          selectedRepaymentOption === SETTLEMENT_OPTIONS.createPlan
            ? paymentSchedule.map(p => {
                return {
                  paymentDate: p.date,
                  currencyValue: p.amount
                };
              })
            : [
                {
                  paymentDate: formatDate(selectedDate),
                  currencyValue: offerAmount
                }
              ];

        const data = {
          accountId,
          paymentSchedules,
          routeNumber: routingNumber || '',
          bankAcctLast4: last4 || '',
          accountType: source || '',
          consumerIpAddress: IPv4 || '',
          consumerName: nameOnAccount || ''
        };

        await paymentPlanService.sendProofOfAuth(data);
      }

      const body = {
        accountId,
        planDetails: getSettlementBody(
          paymentToken,
          paymentDetails,
          selectedPaymentMethod,
          selectedRepaymentOption,
          selectedDate,
          amountOfMonths
        )
      };

      const {
        data: {
          data: { planDetails }
        }
      } = await paymentPlanService.createPlan(body);

      return planDetails;
    } catch ({ response }) {
      throw parseError(response);
    }
  }
);

export const settlementShortUrl = createAction('settlementOffer/shortUrl');
export const settlementReferrer = createAction('settlementOffer/ref');
export const selectSettlementOption = createAction('settlementOffer/selectRepaymentOption');
export const selectSettlementPlan = createAction('settlementOffer/selectPlan');
export const selectSettlementPaymentMethod = createAction('settlementOffer/selectPaymentMethod');
export const selectDate = createAction('settlementOffer/selectDate');
export const hydrateFromSpecialPlan = createAction('settlementOffer/hydrateFromSpecialPlan');

export const { fulfilled: verifyPhoneNumberFulfilled } = verifyPhoneNumber;
export const { fulfilled: settlementInitialUrlFulfilled } = settlementInitialUrl;
export const { fulfilled: settlementOfferFulfilled } = settlementOffer;
export const { fulfilled: createPaymentPlanFulfilled } = createPaymentPlan;
