import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  bankAccount: { id: 'paymentMethod.bankMinified' },
  endingIn: { id: 'confirmPage.endingIn' },
  paymentMethod: { id: 'confirmPage.paymentMethod' },
  transactionId: { id: 'viewPaymentConfirmation.transactionId' },
  originalCreditor: { id: 'viewPaymentConfirmation.originalCreditor' },
  referenceNumber: { id: 'viewPaymentConfirmation.referenceNumber' },
  printReceipt: { id: 'viewPaymentConfirmation.printReceipt' },
  frequency: { id: 'viewPaymentConfirmation.frequency' },
  every: { id: 'viewPaymentConfirmation.every' },
  ofTheMonth: { id: 'paymentFrequency.ofTheMonth' },
  backToHome: { id: 'settlement.backToHome' }
});
