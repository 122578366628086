import React, { useEffect, useMemo, memo } from 'react';
import { useIntl } from 'react-intl';
import sortBy from 'lodash/sortBy';

import { Loading } from 'components/common';
import { document } from 'state/actions/userActions';
import { useAnalytics, useSelectAccountDetails, useSelectDocuments, useStatus } from 'hooks';
import { MIXPANEL_EVENTS } from 'constants/mixpanelEvents';
import { PENDING } from 'constants/actionStatusConstants';
import Document from './Document';

const Documents = () => {
  const intl = useIntl();
  const {
    referenceNumber,
    originalCreditor,
    openedDate,
    accountNumber
  } = useSelectAccountDetails();
  const { status } = useStatus(document);
  const { documents } = useSelectDocuments();
  const { trackEvent } = useAnalytics();

  const memoizedDocuments = useMemo(() => {
    return sortBy(documents, 'updatedAt').reverse();
  }, [documents]);

  useEffect(() => {
    trackEvent(MIXPANEL_EVENTS.viewDocumentsPage);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="d-flex flex-column w-100">
      <div className="documents-reference-number">
        <div className="font-weight-bold pr-2">
          {intl.formatMessage({ id: 'documents.referenceNumber' })}
        </div>
        {referenceNumber}
      </div>
      <div className="documents-account-details pt-3 px-md-3 px-xl-4">
        <div className="documents-item-content documents-original-creditor">
          <span className="document-item-title">
            {intl.formatMessage({ id: 'documents.originalCreditor' })}
          </span>
          <div className="document-item-value">{originalCreditor}</div>
        </div>
        <div className="documents-item-content">
          <span className="document-item-title">
            {intl.formatMessage({ id: 'documents.openedDate' })}
          </span>
          <div className="document-item-value">{openedDate}</div>
        </div>
      </div>
      <div className="documents-item-content px-md-3 px-xl-4">
        <span className="document-item-title">
          {intl.formatMessage({ id: 'documents.accountNumber' })}
        </span>
        <div className="document-item-value">{accountNumber}</div>
      </div>
      <span className="document-subtitle">
        {intl.formatMessage({ id: 'documents.title' })}
        <div>{status === PENDING && <Loading size="small" />}</div>
      </span>
      <div className="documents-container row">
        {memoizedDocuments.map(({ ...props }, index) => (
          <Document key={`document-item-${index}`} {...props} />
        ))}
      </div>
    </div>
  );
};

export default memo(Documents);
