import React from 'react';

import AccountErrorPage from 'pages/AccountErrorPage';
import ConfirmPaymentPage from 'pages/ConfirmPaymentPage';
import CreatePlanPage from 'pages/CreatePlanPage';
import DisclosuresPage from 'pages/DisclosuresPage';
import DocumentsPage from 'pages/DocumentsPage';
import HomePage from 'pages/HomePage';
import LoginPage from 'pages/LoginPage';
import MultipleAccountsPage from 'pages/MultipleAccountsPage';
import NotFoundPage from 'pages/NotFoundPage';
import PartialPaymentPage from 'pages/PartialPaymentPage';
import PaymentErrorPage from 'pages/PaymentErrorPage';
import PaymentPlansPage from 'pages/PaymentPlansPage';
import PaymentHistoryPage from 'pages/PaymentHistoryPage';
import PaymentPlanSuccess from 'pages/PaymentPlanSuccess';
import RepaymentOptionPage from 'pages/RepaymentOptionPage';
import routesPaths from 'constants/routesPaths';
import SetupPaymentPage from 'pages/SetupPaymentPage';
import VersionPage from 'pages/VersionPage';
import SettlementOfferInitialPage from 'pages/SettlementOfferInitialPage';
import SettlementOfferPage from 'pages/SettlementOfferPage';
import ConfirmSettlementPage from 'pages/ConfirmSettlementPage';
import SettlementOfferSuccess from 'pages/SettlementOfferSuccess';
import SettlementErrorPage from 'pages/SettlementErrorPage';
import PaymentPlanLandingPage from 'pages/PaymentPlanLandingPage';

const routes = [
  {
    path: routesPaths.index,
    component: <HomePage />,
    exact: true,
    private: true
  },
  {
    path: routesPaths.createPaymentPlan,
    component: <CreatePlanPage />,
    exact: true,
    private: true
  },
  {
    path: routesPaths.repaymentOptions,
    component: <RepaymentOptionPage />,
    exact: true,
    private: true
  },
  {
    path: routesPaths.partialPayment,
    component: <PartialPaymentPage />,
    exact: true,
    private: true
  },
  {
    path: routesPaths.setupPlanPayment,
    component: <SetupPaymentPage />,
    exact: true,
    private: true
  },
  {
    path: routesPaths.confirmPaymentPage,
    component: <ConfirmPaymentPage />,
    exact: true,
    private: true
  },
  {
    path: routesPaths.paymentPlanSuccess,
    component: <PaymentPlanSuccess />,
    exact: true,
    private: true
  },
  {
    path: routesPaths.paymentPlans,
    component: <PaymentPlansPage />,
    exact: true,
    private: true
  },
  {
    path: routesPaths.paymentErrorPage,
    component: <PaymentErrorPage />,
    exact: true,
    private: true
  },
  {
    path: routesPaths.login,
    component: <LoginPage />
  },
  {
    path: routesPaths.disclosures,
    component: <DisclosuresPage />,
    exact: true
  },
  {
    path: routesPaths.uniqueDisclosures,
    component: <DisclosuresPage />,
    exact: true
  },
  {
    path: routesPaths.homeDisclosures,
    component: <DisclosuresPage />,
    private: true,
    exact: true
  },
  {
    path: routesPaths.documents,
    component: <DocumentsPage />,
    private: true,
    exact: true
  },
  {
    path: routesPaths.accountError,
    component: <AccountErrorPage />,
    exact: true
  },
  {
    path: routesPaths.version,
    component: <VersionPage />,
    private: true,
    exact: true
  },
  {
    path: routesPaths.paymentHistory,
    component: <PaymentHistoryPage />,
    private: true,
    exact: true
  },
  {
    path: routesPaths.selectAccount,
    component: <MultipleAccountsPage />,
    exact: true
  },
  {
    path: routesPaths.settlementOffer,
    component: <SettlementOfferPage />,
    exact: true
  },
  {
    path: routesPaths.settlementConfirm,
    component: <ConfirmSettlementPage />,
    exact: true
  },
  {
    path: routesPaths.settlementSuccess,
    component: <SettlementOfferSuccess />,
    exact: true
  },
  {
    path: routesPaths.settlementError,
    component: <SettlementErrorPage />,
    exact: true
  },
  // This route takes the form "/static-fragment/:param" so it goes after the static routes
  {
    path: routesPaths.paymentPlanLandingPage,
    component: <PaymentPlanLandingPage />,
    exact: true,
    private: false
  },
  // This route takes the form "/:param/:another_param" so it goes at the end
  {
    path: routesPaths.settlementOfferInitial,
    component: <SettlementOfferInitialPage />,
    exact: true
  },
  // This is a catch-all
  {
    component: <NotFoundPage />
  }
];

export default routes;
