import React, { useEffect, useState } from 'react';
import { useIntl, defineMessages } from 'react-intl';

import { ContentContainer } from 'components/common';

import { MIXPANEL_EVENTS } from 'constants/mixpanelEvents';
import { PAYMENT_AUTHORIZATION } from 'constants/disclosures';
import {
  useAnalytics,
  useSelectSelectedPlan,
  useSelectBannerInfo,
  useSelectPaymentInfo,
  useSelectPaymentMethod,
  useLiveChat,
  useSelectAccountId,
  useStatus,
  useDispatch
} from 'hooks';
import { PAYMENT_METHOD_OPTIONS } from 'constants/constants';

import CalendarIcon from '@mui/icons-material/Today';
import { addPaymentMethodToPromise } from 'state/actions/paymentPlanActions';
import { FULFILLED, REJECTED, PENDING } from 'constants/actionStatusConstants';
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Stack,
  StackDivider,
  Text,
  useTheme
} from '@chakra-ui/react';
import {
  confirmationScreenPaymentMethodDecorator,
  getPlanOneLineResume
} from 'utils/decorators/addPaymentMethodToPromise';
import useSectionRouter from 'hooks/sectionRouter';
import { ADD_PAYMENT_METHOD_TO_PROMISE } from 'constants/sections';
import TransactionalHeader from 'components/common/TransactionalHeader';
import { useSelector } from 'react-redux';
import { getPaymentMethod } from 'state/reducers/paymentMethodReducer';

const {
  PAYMENT_METHOD_SUCCESS,
  ADD_PAYMENT_METHOD_TO_PROMISE_ERROR
} = ADD_PAYMENT_METHOD_TO_PROMISE;

const messages = defineMessages({
  back: { id: 'createPlan.goBack' },
  backAria: { id: 'createPlan.goBackAria' },
  bankAccount: { id: 'paymentMethod.bank' },
  creditor: { id: 'accounts.originalCreditor' },
  endingIn: { id: 'confirmPage.endingIn' },
  every: { id: 'confirmPage.every' },
  paymentDates: { id: 'confirmPage.paymentDates' },
  routingNumber: { id: 'viewPaymentConfirmation.routingNumber' },
  ofTheMonth: { id: 'paymentFrequency.ofTheMonth' },
  frequency: { id: 'confirmPage.frequency' },
  paymentMethod: { id: 'confirmPage.paymentMethod' },
  reference: { id: 'accounts.referenceNumber' },
  titleAddPaymentMethod: { id: 'confirmPage.titleAddPaymentMethod' },
  titlePayment: { id: 'confirmPage.titlePayment' },
  totalBalance: { id: 'confirmPage.totalBalance' }
});

const AddPaymentMethodSuccess = () => {
  const theme = useTheme();
  const { push, pop } = useSectionRouter();
  const intl = useIntl();
  const { trackEvent } = useAnalytics();
  const { status } = useStatus(addPaymentMethodToPromise);

  const addPaymentMethodToPromiseRequest = useDispatch(addPaymentMethodToPromise);
  const { originalCreditor, referenceNumber } = useSelectBannerInfo();
  const { selectedPaymentMethod } = useSelectPaymentMethod();
  const { last4, cardType, routingNumber } = useSelectPaymentInfo();
  const { selectedPlan } = useSelectSelectedPlan();
  const { accountId } = useSelectAccountId();
  const paymentMethod = useSelector(getPaymentMethod);
  const [paymentsResume, setPaymentsResume] = useState('');
  const addPaymentMethodToPromiseLoading = status === PENDING;

  useLiveChat();

  const buttonLabel = intl.formatMessage({ id: 'confirmPage.confirmAddPaymentMethod' });

  const paymentMethodDecoration = confirmationScreenPaymentMethodDecorator({
    intl,
    messages,
    selectedPaymentMethod,
    last4,
    cardType
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onClickHandler = () => {
    addPaymentMethodToPromiseRequest({
      accountId,
      selectedPlan,
      paymentMethod
    });
  };

  useEffect(() => {
    if (selectedPlan) setPaymentsResume(getPlanOneLineResume(selectedPlan, intl));
  }, [selectedPlan]);

  useEffect(() => {
    if (status === FULFILLED) {
      trackEvent(MIXPANEL_EVENTS.conversionAddDetailsToPromise);
      push(PAYMENT_METHOD_SUCCESS);
    }
    if (status === REJECTED) push(ADD_PAYMENT_METHOD_TO_PROMISE_ERROR);
  }, [status]);

  return (
    <ContentContainer>
      <TransactionalHeader
        ariaLabel={intl.formatMessage({ id: 'createPlan.goBackAria' })}
        title={intl.formatMessage({ id: 'createPlan.goBack' })}
        backToCallback={pop}
      />
      <Container maxW="5xl">
        <Heading fontWeight={800} textAlign={{ base: 'center', lg: 'left' }} fontSize="3xl" mb="8">
          {intl.formatMessage(messages.titleAddPaymentMethod)}
        </Heading>
        <Box boxShadow="md" borderRadius="md">
          <Stack
            p={30}
            spacing={4}
            divider={
              <StackDivider
                borderBottomStyle="solid"
                borderBottomWidth="0.5px"
                borderBottomColor="gray.200"
              />
            }
          >
            <Flex w="full" justifyContent="space-between">
              <Text fontWeight={800} pr="1">{`${intl.formatMessage(messages.creditor)}: `}</Text>
              <Text textAlign="right">{originalCreditor}</Text>
            </Flex>
            <Flex w="full" justifyContent="space-between">
              <Text fontWeight={800} pr="1">{`${intl.formatMessage(messages.reference)}: `}</Text>
              <Text textAlign="right">{referenceNumber}</Text>
            </Flex>
            <Flex w="full" justifyContent="space-between">
              <Flex alignItems="start">
                <Flex alignItems="center">
                  <CalendarIcon sx={{ color: theme.colors.gray[500] }} />
                  <Text ml="2" fontWeight={800} pr="1">
                    {`${intl.formatMessage(messages.paymentDates)}:`}
                  </Text>
                </Flex>
              </Flex>
              <Flex textAlign="right">
                <Text>{paymentsResume}</Text>
              </Flex>
            </Flex>
            {selectedPaymentMethod === PAYMENT_METHOD_OPTIONS.bank && (
              <Flex w="full" justifyContent="space-between">
                <Text fontWeight={800} pr="1">
                  {`${intl.formatMessage(messages.routingNumber)}: `}
                </Text>
                <Text textAlign="right">{routingNumber}</Text>
              </Flex>
            )}
            <Flex w="full" justifyContent="space-between">
              <Text fontWeight={800} pr="1">
                {`${paymentMethodDecoration.paymentMethod}: `}
              </Text>
              <Text textAlign="right">{paymentMethodDecoration.paymentMethodDescription}</Text>
            </Flex>
            <Flex w="full" justifyContent="space-between">
              <Text fontWeight={800} pr="1">
                {`${intl.formatMessage(messages.totalBalance)}: `}
              </Text>
              <Text textAlign="right">{`$${selectedPlan.originalBalance}`}</Text>
            </Flex>
          </Stack>
        </Box>
        <Box mt="1rem" mb="4rem">
          <Stack>
            <Text fontSize="0.9rem" my="1rem" order={{ base: 1, lg: 0 }}>
              {PAYMENT_METHOD_OPTIONS.card === selectedPaymentMethod
                ? PAYMENT_AUTHORIZATION.card
                : PAYMENT_AUTHORIZATION.ACH}
              {PAYMENT_AUTHORIZATION.cta.replace('{buttonText}', buttonLabel)}
              {PAYMENT_AUTHORIZATION.print}
            </Text>
            <Flex my="1rem" justifyContent="center">
              <Button
                variant="successBase"
                w={{ base: '14rem', md: '24rem' }}
                size="lg"
                aria-label={buttonLabel}
                onClick={onClickHandler}
                isLoading={addPaymentMethodToPromiseLoading}
              >
                {buttonLabel}
              </Button>
            </Flex>
          </Stack>
        </Box>
      </Container>
    </ContentContainer>
  );
};

export default AddPaymentMethodSuccess;
