import { useSelector, shallowEqual } from 'react-redux';

const useSelectHolderName = () =>
  useSelector(
    ({ accounts: { accountSelected } }) => ({
      firstName: accountSelected?.accountholderFirstName,
      lastName: accountSelected?.accountholderLastName
    }),
    shallowEqual
  );

export default useSelectHolderName;
