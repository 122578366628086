import { useSelector, shallowEqual } from 'react-redux';

const useSelectBannerInfo = () =>
  useSelector(
    ({ accounts: { accountSelected } }) => ({
      originalCreditor: accountSelected?.clientName,
      remainingBalance: accountSelected?.remainingBalance,
      referenceNumber: accountSelected?.accountId
    }),
    shallowEqual
  );

export default useSelectBannerInfo;
